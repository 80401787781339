<template>
  <!-- INFO: This Vue component is used for both large and compact breakpoints, and corresponds with 2 tag files: navUtilityBarB2cLargeContainer.tag and navUtilityBarB2cCompactContainer.tag  -->
  <div
    v-show="isShown"
    class="nav-utility-bar-b2c-container flex flex-col px-2 md:px-3 pb-3 lg:flex-row lg:justify-between lg:py-25"
    qaid="nav-utility-b2c"
  >
    <p v-if="isCompactView" class="nav-utility-bar-b2c-container__shop-text">{{ $t('nav.mobile.shopOurBrands') }}</p>
    <div class="flex mt-3 flex-col lg:mt-0 lg:flex-row lg:items-center">
      <a
        v-for="logo in logos"
        :id="generateLogoId(logo.identifier)"
        :key="logo.identifier"
        :href="logo.href"
        :aria-label="logo.aria"
        class="flex mb-3 lg:mb-0 lg:mr-2"
        :class="{'nav-diamant-logo': logo.identifier === 'diamant-logo'}"
        :target="logo.target"
      >
        <span
          :class="`nav-utility-bar-b2c-container__logo nav-utility-bar-b2c-container__logo--${logo.identifier} hover:opacity-100 lg:opacity-30`"
        />
        <span class="logo__heading" v-html="logo.heading" />
      </a>
    </div>

    <div class="nav-utility-bar-b2c-container__b2c-utilities">
      <!-- Profile & login info: -->
      <div class="mb-2 lg:mb-0 lg:mr-2 lg:order-2">
        <slot name="profile-login" />
      </div>

      <!-- Language & country info: -->
      <div>
        <slot name="language-country-info" />
      </div>

      <!-- Shopping cart: -->
      <div v-show="!isCompactView" class="order-last">
        <slot name="minicart" />
      </div>

      <pdl-button
        class="nav-utility-bar-b2c-container__close-mobile-menu-button"
        tertiary
        inverted
        icon="close"
        @click="toggleMobileMenu"
      >
        {{ $t('popup.close') }}
      </pdl-button>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {PdlButton} from '@pedal/pdl-button';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavUtilityBarB2cContainer',
  /* eslint-disable-next-line vue/no-unused-components */
  components: {PdlButton, PdlIcon},
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    brandUrls: {
      type: Object,
      required: true,
    },
    isElectraSupportedSite: {
      type: Boolean,
      default: false,
    },
    isTrekTravelLocale: {
      type: Boolean,
      default: false,
    },
    isDiamantLocale: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('global', ['isCompactView']),
    isShown() {
      return this.isLarge ? !this.isCompactView : this.isCompactView;
    },
    logos() {
      const logos = [
        {
          identifier: 'trek-logo',
          href: this.brandUrls.trekBrandNavURLClear,
          title: this.$t('general.logo.text'),
          aria: this.$t('nav.logo.altText'),
          heading: this.$t('general.logo.text'),
          logoName: 'trekLogo',
        },
      ];
      if (this.isElectraSupportedSite) {
        logos.push({
          identifier: 'electra-logo',
          href: this.brandUrls.electraBrandNavURLClear,
          title: this.$t('general.logo.text.electra'),
          aria: this.$t('nav.logo.altText.electra'),
          heading: this.$t('page_ElectraBrand'),
          logoName: 'electraLogo',
        });
      }

      logos.push({
        identifier: 'bontrager-logo',
        href: this.brandUrls.bontragerBrandNavURLClear,
        title: this.$t('general.logo.text.bontrager'),
        aria: this.$t('nav.logo.altText.bontrager'),
        heading: this.$t('page_BontragerBrand'),
        logoName: 'bontragerLogo',
      });

      if (this.isDiamantLocale) {
        logos.push({
          identifier: 'diamant-logo',
          href: this.brandUrls.diamantBrandURL,
          title: this.$t('general.logo.text.diamant'),
          aria: this.$t('general.logo.text.diamant'),
          heading: this.$t('general.logo.text.diamant'),
          logoName: 'diamantLogo',
          target: '_blank',
        });
      }

      if (this.isTrekTravelLocale) {
        logos.push({
          identifier: 'trek-travel-logo',
          href: this.brandUrls.trekTravelBrandNavURL,
          title: this.$t('nav.logo.altText.trekTravel'),
          aria: this.$t('nav.logo.altText.trekTravel'),
          heading: this.$t('page_TrekTravel'),
          logoName: 'trekTravelLogo',
        });
      }
      return logos;
    },
  },
  methods: {
    ...mapMutations('navigation', ['setMobileMenuOpen']),
    toggleMobileMenu() {
      this.setMobileMenuOpen(false);
    },
    generateLogoId(logoId) {
      return this.isLarge ? `${logoId}'-large'` : `${logoId}'-compact'`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/settings/_settings-logos.scss';

.nav-utility-bar-b2c-container {
  // This insane z-index is to ensure that all contents of the utility bar are not covered by full-screen overlays when main navigation menu opens,
  // And are not overwritten by the other insane z-indexes coming from _navigation.scss
  // TODO: streamline the z-index madness in Phase 2!
  position: relative;
  z-index: 16000016; //needs to be less than reveal z-index: 16000017 in _custom.scss in order for the cart overlay in mobile to work

  &__logo {
    opacity: 0.5;
  }

  &__shop-text {
    @apply text-white;
    @apply text-lg;
    @apply font-medium;
    @apply mt-5;

    &::before {
      content: '';
      border-left: 2px solid var(--nav-highlight-color);
      margin-right: 0.5rem;
    }
  }

  &__logo--trek-logo {
    background-image: url('data:image/svg+xml, #{$trek-logo-white}');

    @include brand-logo(trek, 11px);

    @include breakpoint(large) {
      @include brand-logo(trek, 10px);
    }
  }

  &__logo--electra-logo {
    background-image: url('data:image/svg+xml, #{$electra-logo-white}');

    @include brand-logo(electra, 16px);

    @include breakpoint(large) {
      @include brand-logo(electra, 14px);
    }
  }

  &__logo--bontrager-logo {
    background-image: url('data:image/svg+xml, #{$bontrager-logo-white}');

    @include brand-logo(bontrager, 12px);

    @include breakpoint(large) {
      @include brand-logo(bontrager, 10px);
    }
  }

  &__logo--trek-travel-logo {
    background-image: url('data:image/svg+xml, #{$trek-travel-logo-white}');

    @include brand-logo(trek_travel, 11px);

    @include breakpoint(large) {
      @include brand-logo(trek_travel, 10px);
    }
  }

  &__logo--diamant-logo {
    background-image: url('data:image/svg+xml, #{$diamant-logo-white}');

    @include brand-logo(diamant, 20px);

    @include breakpoint(large) {
      @include brand-logo(diamant, 18px);
    }
  }

  &__b2c-utilities {
    display: flex;

    @apply flex-col;
    @apply mt-3;

    padding-bottom: 5rem;

    @include breakpoint(large) {
      @apply flex-row;
      @apply mt-0;
      @apply pb-0;
      @apply items-center;
    }
  }

  .header-bottom__cart-button {
    color: white;

    &:hover {
      opacity: 1;
    }
  }

  .logout-text {
    color: var(--gray-30);
  }

  .nav-diamant-logo {
    @include breakpoint(large) {
      margin-bottom: 0.25rem;
    }
  }

  &__close-mobile-menu-button {
    @apply mt-2;

    @include breakpoint(large) {
      display: none;
    }
  }
}
</style>
