import vue from 'vue';
import VueI18n from 'vue-i18n';
import storefrontInstance from '@/api/instances/storefront';

vue.use(VueI18n);

export const I18n = new VueI18n({
  fallbackLocale: 'en_US',
  silentTranslationWarn: true,
});

window.VueI18n = I18n;
const loadedLanguages = [];

function setI18nLanguage(lang) {
  I18n.locale = lang;
  storefrontInstance.defaults.headers.common['Accept-Language'] = lang;

  return lang;
}

// function for Lazy loading or asynchronously loading the translation files
export async function loadLanguageAsync(lang) {
  // Error handling for if window.ACC.languageISO is null
  if (!lang) {
    return console.error('ERROR: Window.ACC.languageISO value not set. Translations for page unable to load.');
  }
  // If invoked for the same language as I18n locale
  if (I18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`).then((messages) => {
    I18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}

export default I18n;
