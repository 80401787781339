import StoredPaymentProvider from '@/utils/checkout/payment-providers/stored-payment-provider';

const StoredPaymentHandler = (() => {
  async function handlePayment() {
    const data = {
      paymentProvider: $('.form__retailer-option.selected #paymentProvider').val(),
      savedPaymentId: $('.form__retailer-option.selected #paymentInfoId').val(),
    };
    const provider = new StoredPaymentProvider(data);
    try {
      provider.initializePayment();
    } catch (error) {
      console.error(error);
    }
  }

  return {
    handlePayment,
  };
})();

export default StoredPaymentHandler;
