const Url = {
  /**
   * Create an URL based on base URL and list of parameters as an object.
   * @param baseUrl
   * @param parameters
   * @param rules
   */
  create(baseUrl, parameters = {}, rules = {}) {
    const defaults = {
      skipEmpty: false,
    };
    rules = Object.assign(defaults, rules);

    const queryString = this.objectToQueryStringArray(parameters, rules);
    const querySymbol = queryString.length > 0 ? (baseUrl.indexOf('?') < 0 ? '?' : '&') : '';

    return `${baseUrl}${querySymbol}${queryString.join('&')}`;
  },

  /**
   * Convert an object to an array with key=value.
   * @param parameters
   * @param rules
   * @return {Array}
   */
  objectToQueryStringArray(parameters, rules = {}) {
    let queryString = [];
    for (let param in parameters) {
      if (rules.skipEmpty && !parameters[param].toString().length) {
        continue;
      }

      queryString.push(`${param}=${parameters[param]}`);
    }

    return queryString;
  },

  /**
   * Update URL with a list of parameters.
   * @param url
   * @param parameters
   * @param options
   * @return {*|string}
   */
  update(url, parameters = {}, options = {}) {
    const defaults = {
      removeKeys: [],
    };
    options = Object.assign(defaults, options);

    let queryParams = this.parse(url);
    for (let param in parameters) {
      queryParams[param] = parameters[param];
    }

    if (options.removeKeys.length > 0) {
      options.removeKeys.forEach((key) => {
        if (key in queryParams && !(key in parameters)) {
          delete queryParams[key];
        }
      });
    }

    const baseUrl = url.split('?')[0];
    return this.create(baseUrl, queryParams);
  },

  /**
   * Parse URL string and return an object that contains list of parameters.
   * @param url
   */
  parse(url) {
    let result = {};
    if (url.indexOf('?') > -1) {
      // Extract GET parameters only without hash value.
      const queryString = url.split('?')[1].replace(/#(.*)$/g, '');
      queryString.split('&').forEach((param) => {
        const item = param.split('=');
        if (item.length > 1) {
          result[item[0]] = decodeURIComponent(item[1]);
        }
      });
    }

    return result;
  },

  /**
   * Get URL that contains context path.
   * @param url
   * @return {*}
   */
  withContextPath(url) {
    if (!url || !url.length) {
      return '';
    }

    const contextPath = ACC?.config?.encodedContextPath || '';
    return url.startsWith(contextPath) ? url : `${contextPath}${url}`;
  },

  /**
   * Append URL with a string.
   * @param url
   * @param appendix
   * @return {string|*}
   */
  appendUrl(url, appendix) {
    if (!appendix || !appendix.length) {
      return url;
    }

    return `${url}${url.indexOf('?') > -1 ? '&' : '?'}${appendix}`;
  },

  // takes an object and prepares it for url param appending by removing keys with empty values and encoding values
  cleanAndEncodeUrlObject(parameterObject) {
    let parameterObjectClone = {...parameterObject};

    Object.keys(parameterObjectClone).forEach((parameter) => {
      //Clean out empty values, but not booleans with false
      if (
        parameterObjectClone[parameter] === null ||
        parameterObjectClone[parameter] === undefined ||
        parameterObjectClone[parameter] === ''
      ) {
        delete parameterObjectClone[parameter];
      } else {
        parameterObjectClone[parameter] = encodeURIComponent(parameterObjectClone[parameter]);
      }
    });
    return parameterObjectClone;
  },

  // removes url params that match given object of parameters
  removeUrlParameterObjectFromUrl(parameterObject) {
    window.history.replaceState(
      null,
      document.title,
      Url.update(window.location.href, undefined, {
        removeKeys: Object.keys(parameterObject),
      })
    );
  },

  // Takes an object and appends its keys and values to the url parameters
  appendObjectToUrlParameters(objectParameters) {
    window.history.replaceState(
      null,
      document.title,
      Url.update(window.location.href, this.cleanAndEncodeUrlObject(objectParameters), undefined)
    );
  },
};

export {Url};
