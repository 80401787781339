import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';
import {ProductApi} from '@/api/product';
import Vue from 'vue';

const productTypes = Object.freeze({BUNDLE: 'bundleProducts', BASE: 'baseProducts'});

const MutationTypes = Object.freeze({
  SET_SKU_NOTIFY_ERROR_TOAST: 'setSkuNotifyErrorToast',
});

const skuGrid = {
  namespaced: true,

  state: {
    baseProducts: {},
    baseProductsSkus: [],
    baseProductsCallbacks: {},
    registerInputs: [],
    collapseState: {},
    minQtySku: [],
    allocatedCounts: {},
    bundleRegisterInputs: [],
    bundleProducts: {},
    isBundleInput: false,
    isBundleModal: false,
    skuEntriesWithBundle: [],
    skuNotifyErrorToast: '',
  },

  mutations: {
    updateVariantQuantity(state, payload) {
      payload.productType = state.isBundleInput ? productTypes.BUNDLE : productTypes.BASE;
      if (!state[payload.productType][payload.baseSku]) {
        state[payload.productType][payload.baseSku] = [];
      }
      if (payload.qty > 0) {
        let Idx = state[payload.productType][payload.baseSku].findIndex((item) => item.sku === payload.sku);
        if (Idx >= 0) {
          state[payload.productType][payload.baseSku][Idx].qty = payload.qty;
        } else {
          state[payload.productType][payload.baseSku].push({sku: payload.sku, qty: payload.qty});
        }

        if (payload.productType === productTypes.BASE) {
          if (!state.baseProductsSkus.includes(payload.sku)) {
            state.baseProductsSkus.push(payload.sku);
          }
        }

        //   Set this to true if any sku qty changes
        window.store.commit('productBundle/setIsVariantsChanged', true);

        // If same sku with qty >0 then remove it from 'minQtySku'
        if (state.minQtySku.includes(payload.sku)) {
          let index = state.minQtySku.findIndex((sku) => sku === payload.sku);
          state.minQtySku.splice(index, 1);
        }
      } else {
        // Some time payload.sku comes as blank string
        payload.sku && state.minQtySku.push(payload.sku);
        if (payload.qty === '') {
          Object.entries(state[payload.productType]).forEach(([baseSku, items]) => {
            let findElemIndex = items.findIndex((item) => item.sku === payload.sku);
            if (findElemIndex >= 0) {
              state[payload.productType][baseSku].splice(findElemIndex, 1);
            }
          });
          if (payload.productType === productTypes.BASE) {
            state.skuEntriesWithBundle = state.skuEntriesWithBundle.filter((entry) => entry.sku !== payload.sku);
          }
          window.store.commit('savedLists/updateEntries', payload.sku);
        }

        if (payload.productType === productTypes.BASE) {
          let index = state.baseProductsSkus.findIndex((sku) => sku === payload.sku);
          index > -1 && state.baseProductsSkus.splice(index, 1);
        }
      }

      if (payload.productType === productTypes.BUNDLE) {
        state.bundleRegisterInputs.push(payload.input);
      } else {
        state.registerInputs.push(payload.input);
        state.baseProductsCallbacks[payload.baseSku] = payload.callback;
      }
    },

    updateCollapseState(state, collapseStates) {
      Object.entries(collapseStates).forEach(([key, value]) => {
        state.collapseState[key] = value;
      });
    },

    clearBaseProduct(state, baseSku) {
      delete state.baseProducts[baseSku];
    },

    setAllocatedCounts(state, data) {
      if (!data || !Object.keys(data).length) {
        return;
      }

      Object.values(data).forEach((item) => {
        Vue.set(state.allocatedCounts, item.sku, item);
      });
    },

    isBundleInput(state, payload) {
      state.isBundleInput = payload;
    },

    setSkuEntriesWithBundle(state, payload) {
      state.skuEntriesWithBundle = state.skuEntriesWithBundle.filter((entry) => entry.sku !== payload.sku);
      state.skuEntriesWithBundle.push(payload);
    },

    emptyBaseSkus(state, sku) {
      if (sku) {
        let index = state.baseProductsSkus.findIndex((baseSku) => baseSku === sku);
        index > -1 && state.baseProductsSkus.splice(index, 1);
      } else {
        state.baseProductsSkus = [];
      }
    },

    [MutationTypes.SET_SKU_NOTIFY_ERROR_TOAST](state, errorMessage) {
      state.skuNotifyErrorToast = errorMessage;
    },
  },

  actions: {
    loadAllocatedCounts({commit}, ids) {
      if (!ids || !ids.length) {
        throw new Error('Wrong array of product IDs given.');
      }

      ProductApi.batchLoadAllocatedData(ids).then((data) => {
        commit('setAllocatedCounts', data);
      });
    },

    addBundleSkuToCart({dispatch}, failureMessage) {
      dispatch('addSkuToCart', [productTypes.BUNDLE, failureMessage]);
    },

    addBaseSkuToCart({dispatch}, failureMessage) {
      dispatch('addSkuToCart', [productTypes.BASE, failureMessage]);
    },

    addSkuToCart({state, commit}, [productType, failureMessage]) {
      // Reference the addSkusToCart method in BulkProductGridTabs.vue
      // Gather all of the variants associated with the baseSku as the payload
      let payload = {items: []};
      // Filtering items based on collapsed grids

      state.isBundleModal = productType === productTypes.BUNDLE;
      Object.entries(state[productType]).forEach((data) => {
        if (data.length < 2) {
          return;
        }

        const items = data[1];
        let len = items.length;
        while (len--) {
          payload.items.push(items[len]);
          items.splice(len, 1);
        }
      });

      let skus = window.store.state.productBundle.skusWithBundle;

      skus.forEach((sku) => {
        if (productType !== productTypes.BUNDLE) {
          let index = payload.items.findIndex((item) => item.sku === sku);
          if (index > -1) payload.items.splice(index, 1);
        }
      });

      storefrontInstance
        .put('/cart/entries/batch', payload)
        .then((response) => {
          Object.keys(state.baseProductsCallbacks).forEach((baseSku) => state.baseProductsCallbacks[baseSku](response));

          // Advanced Ordering failed items
          if (
            get(response, 'data.meta.feedback.message.basePropertyKey') ===
            'distributor.B2B.advancedOrder.error.cannotAdd'
          ) {
            const items = get(response, 'data.data.cartEntries');
            if (items && items.length) {
              let nonAOItems = [];

              items.forEach((item) => {
                nonAOItems.push({sku: item.sku, itemName: item.itemName});
              });

              window.store.commit('advancedOrdering/setDisplayAddErrorModal', true);
              window.store.commit('advancedOrdering/setNonAOItems', nonAOItems);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (failureMessage) {
            commit(MutationTypes.SET_SKU_NOTIFY_ERROR_TOAST, 'failureMessage');
          }
        })
        .finally(() => {
          if (productType !== productTypes.BUNDLE) {
            window.store.commit('savedLists/clearEntries');
          }
          window.store.dispatch('miniCart/fetchMiniCart');

          //clearInputs
          state.registerInputs.forEach((input) => {
            if (payload['items'].some((e) => e.sku === input.sku)) {
              input.$el.value = '';
            } else if (state.minQtySku.includes(input.sku)) {
              input.$el.value = '';
            } else if (productType === productTypes.BUNDLE) {
              input.$el.value = '';
            }
          });

          let skusWithBundle = window.store.state.productBundle.skusWithBundle;
          state.baseProductsSkus?.forEach((item) => {
            if (productType === productTypes.BASE && skusWithBundle.includes(item.sku)) {
              commit('emptyBaseSkus', item.sku);
            }

            if (productType === productTypes.BUNDLE) {
              commit('emptyBaseSkus', item.sku);
            }
          });

          if (productType === productTypes.BUNDLE) state.skuEntriesWithBundle = [];
        });
    },

    updateCartOrListColumn(context, {products, responseEntries, colName}) {
      products.forEach((obj) => {
        const itemsIndex = responseEntries?.findIndex((item) => item.sku === (obj.code || obj.sku));
        if (itemsIndex >= 0) {
          if (colName === 'skuQtyInSavedList') {
            obj[colName] = responseEntries[itemsIndex]?.skuQtyInSavedList || obj.skuQtyInSavedList;
          } else {
            obj[colName] = responseEntries[itemsIndex].qty;
          }
        }
        window.store.commit('savedLists/clearEntries');
        context.state.baseProducts = {};
      });

      if (colName === 'skuQtyInSavedList') {
        context.commit('emptyBaseSkus');
        context.state.skuEntriesWithBundle = [];
      }
      return products;
    },

    clearRegisterInputs({state}) {
      state.isBundleInput = false;
      state.isBundleModal = false;
      state.bundleProducts = {};
      state.minQtySku = [];
      state.bundleRegisterInputs.forEach((registerInput) => {
        registerInput.$el.value = '';
      });
      state.bundleRegisterInputs = [];
    },
  },
};

export default skuGrid;
