import {PaymentProvider} from '@/constants/payment-provider.js';
import BaseProvider from './base-provider';

const Gr4vyConfig = {
  gr4vyId: 'trek',
};

class Gr4vyFields {
  constructor({SecureFieldsModule, sessionId, environment}) {
    if (!SecureFieldsModule) throw new Error('Gr4vyFields: secureFields module is required');
    if (!sessionId) throw new Error('Gr4vyFields: sessionId is required');
    if (!environment) throw new Error('Gr4vyFields: environment is required');

    this.SecureFieldsModule = SecureFieldsModule;
    this.cardNumberField = undefined;
    this.expiryDateField = undefined;
    this.securityCodeField = undefined;
    this.secureFields = undefined;
    this.fieldList = [];
    this.sessionId = sessionId;
    this.environment = environment;
  }

  addCardNumberField(cardNumberField) {
    this.cardNumberField = cardNumberField;
    return this;
  }

  addExpiryDateField(expiryDateField) {
    this.expiryDateField = expiryDateField;
    return this;
  }

  addSecurityCodeField(securityCodeField) {
    this.securityCodeField = securityCodeField;
    return this;
  }

  getFields() {
    return this.fieldList;
  }

  build({handleCardNumberChange, handleExpiryDateChange, handleSecurityCodeChange}) {
    this.secureFields = new this.SecureFieldsModule({
      gr4vyId: Gr4vyConfig.gr4vyId,
      environment: this.environment,
      sessionId: this.sessionId,
    });

    this.secureFields.setDebug(false);

    if (this.cardNumberField) {
      const cardNumberSecureField = this.secureFields.addCardNumberField(this.cardNumberField);
      cardNumberSecureField.addEventListener('input', handleCardNumberChange);
      this.fieldList.push('number');
    }

    if (this.securityCodeField) {
      const securityCodeSecureField = this.secureFields.addSecurityCodeField(this.securityCodeField);
      securityCodeSecureField.addEventListener('input', handleSecurityCodeChange);
      this.fieldList.push('securityCode');
    }

    if (this.expiryDateField) {
      const expiryDateSecureField = this.secureFields.addExpiryDateField(this.expiryDateField);
      expiryDateSecureField.addEventListener('input', handleExpiryDateChange);
      this.fieldList.push('expiryDate');
    }

    return this.secureFields;
  }
}

class Gr4vyProvider extends BaseProvider {
  constructor(options) {
    super(options);
    const {billingAddress, secureFields, cardVaultSuccessEvent, cardVaultFailureEvent, validationCallFailure} = options;

    if (!secureFields || !billingAddress) {
      throw new Error('Gr4vyProvider: secureFields reference and billing address required');
    }

    this.providerCode = PaymentProvider.GR4VY;
    this.billingAddress = billingAddress;
    this.secureFields = secureFields;

    const handleCardVaultSuccess = async () => {
      try {
        return await this.submitPaymentInitForm({
          paymentOption: this.providerCode,
          paymentProviderCode: this.providerCode,
          sessionId: this.secureFields.config.sessionId,
          billingAddress: this.billingAddress,
        });
      } catch (error) {
        if (validationCallFailure instanceof Function) {
          validationCallFailure(error);
        } else {
          console.error(error);
        }
      }
    };

    const handleCardVaultFailure = ({status, message, code}) => {
      console.error('Card vault failed', {status, message, code});
    };

    secureFields.removeEventListener(cardVaultSuccessEvent, handleCardVaultSuccess);
    secureFields.addEventListener(cardVaultSuccessEvent, handleCardVaultSuccess);

    secureFields.removeEventListener(cardVaultFailureEvent, handleCardVaultFailure);
    secureFields.addEventListener(cardVaultFailureEvent, handleCardVaultFailure);
  }

  async initializePayment() {
    this.secureFields.submit();
  }
}

export {Gr4vyFields, Gr4vyProvider, Gr4vyConfig};
