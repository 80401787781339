import {ViewBreakpoints} from '@/constants/view-breakpoints';
import debounce from 'lodash/debounce';

const viewSizeStore = {
  namespaced: true,
  state: {
    viewBreakpoint: '',
  },

  getters: {
    /**
     * Check whether the given breakpoint is current or not. Accepts either a single value or an array.
     * viewBreakpointIs('small') or viewBreakpointIs(['small', 'medium']).
     * @param breakpoint
     * @return {*}
     */
    viewBreakpointIs: (state) => (breakpoint) => {
      return !Array.isArray(breakpoint)
        ? breakpoint === state.viewBreakpoint
        : breakpoint.includes(state.viewBreakpoint);
    },
    /**
     * Check whether the current breakpoint is less than given breakpoint.
     * @param breakpoint
     * @return {boolean}
     */
    viewBreakpointLessThan: (state) => (breakpoint) => {
      const breakpoints = Object.keys(ViewBreakpoints);
      return breakpoints.indexOf(breakpoint) > breakpoints.indexOf(state.viewBreakpoint);
    },
    /**
     * Check whether the current breakpoint is more than given breakpoint.
     * @param breakpoint
     * @return {boolean}
     */
    viewBreakpointMoreThan: (state) => (breakpoint) => {
      const breakpoints = Object.keys(ViewBreakpoints);
      return breakpoints.indexOf(breakpoint) < breakpoints.indexOf(state.viewBreakpoint);
    },
  },

  mutations: {
    setViewBreakpoint(state, value) {
      state.viewBreakpoint = value;
    },
  },

  actions: {
    handleResize: debounce(
      function ({dispatch}) {
        dispatch('getCurrentBreakpoint');
      },
      100,
      {leading: true}
    ),

    getCurrentBreakpoint({commit}) {
      let breakpoint = '';
      for (let b in ViewBreakpoints) {
        if (window.innerWidth > ViewBreakpoints[b] - 1 || !breakpoint) {
          breakpoint = b;
        } else {
          break;
        }
      }
      commit('setViewBreakpoint', breakpoint);
    },
  },
};
export default viewSizeStore;
