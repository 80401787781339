import occInstance from '@/api/instances/occ';
import {
  RouteBuilderClickModes,
  RouteBuilderMapDefaults,
  RouteBuilderMapStyle,
  RouteBuilderRoutingOptions,
  RouteBuilderSpeedTimeDefaults,
  RouteBuilderRoutePrivacyModes,
  RouteBuilderRouteSurfaceTypes,
} from '@/constants/rcp-builder';
import Vue from 'vue';
import {addUpWaypointCumulativeDistances, finalizeCueSheetList} from '@/rcpBuilder/utils/cue-helpers';
import {DEFAULT_CHART_OPTIONS, convertChartArrayToChartOptions} from '@/rcpBuilder/utils/chart-options';
import {
  createElevationDataObject,
  convertChartDataToPoints,
  calculateElevationChartRange,
} from '@/rcpBuilder/utils/elevation-helpers';
import {convertMetersToDistanceUnit, convertMetersToElevationUnit} from '@/utils/unit-converter';
import {UnitTypes} from '@/constants/unit-types';

const rcpBuilder = {
  namespaced: true,

  state: {
    currentRoute: 'builder',
    router: null,
    waypoints: [],
    clickMode: RouteBuilderClickModes.ADD_WAYPOINT,
    mapView: RouteBuilderMapStyle.STANDARD,
    isRightRailOpen: false,
    isElevationDrawerOpen: true,
    snapToRoad: true,
    isScrollZoomEnabled: true,
    zoomValue: RouteBuilderMapDefaults.DEFAULT_MAP_ZOOM,
    maxZoom: RouteBuilderMapDefaults.MAX_MAP_ZOOM,
    minZoom: RouteBuilderMapDefaults.MIN_MAP_ZOOM,
    featureTypes: {},
    utilityDrawerWidth: RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
    rightRailDrawerWidth: RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
    bottomDrawerHeight: RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
    elevationData: {},
    isClearMapDialogVisible: false,
    isAutoCenterEnabled: false,
    routingOption: RouteBuilderRoutingOptions.CYCLING,
    chartOptions: DEFAULT_CHART_OPTIONS,
    routeHistory: [],
    currentHistoryIndex: -1,
    reserveMapState: [],
    clearMapFeatures: false,
    isCuePopupOpen: false,
    isSaveMapDialogVisible: false,
    hasUnsavedChanges: false,
    userSpecifiedSpeed: null,
    rideTimeDefault: RouteBuilderSpeedTimeDefaults.RIDE_TIME,
    savedRouteId: '',
    routeSaveFormDefaults: {
      category: RouteBuilderRouteSurfaceTypes.PAVED,
      visibility: RouteBuilderRoutePrivacyModes.PRIVATE,
      name: '',
      publisher: '',
      locations: [],
      scope: null,
      header: {},
    },
    isLoadingRoute: false,
    isDeleteRouteDialogVisible: false,
    routeDeletedMsg: '',
  },
  getters: {
    individualWaypoint: (state) => (index) => {
      return state.waypoints[index];
    },
    waypointOnRoute: (state) => (routeId) => {
      return state.waypoints.findIndex((waypoint) => waypoint.geoJsonSourceId === routeId);
    },
    totalRouteDistance: ({waypoints}) => {
      let runningTotalDistance = 0;
      for (let waypoint of waypoints) {
        if (!waypoint.snapTo) {
          runningTotalDistance += waypoint.distance;
        } else {
          for (let cue of waypoint.cues) {
            runningTotalDistance += cue.distance;
          }
        }
      }
      return runningTotalDistance;
    },
    cueSheetList: ({waypoints}, {totalRouteDistance}) => {
      if (waypoints.length < 2) return [];

      const waypointsWithCumulativeDistances = addUpWaypointCumulativeDistances(waypoints);
      const cuesArray = waypointsWithCumulativeDistances.map((waypoint) => waypoint.cues).flat(1);
      const activeCuesList = cuesArray.filter((cue) => cue.maneuver !== 'arrive' && cue.maneuver !== 'depart');
      const finalCueSheetList = finalizeCueSheetList(activeCuesList, totalRouteDistance);

      return finalCueSheetList;
    },
    cuesManeuverImageMapping: ({featureTypes}) => {
      if (!featureTypes) return [];
      const cueTypes = featureTypes.cues,
        cueImageMapping = cueTypes.reduce((newObject, item) => ({...newObject, [item.featureId]: item.image}), {});

      Object.freeze(cueImageMapping);
      return cueImageMapping;
    },
    elevationRange: ({elevationData}, getters, {backend}) => {
      if (!elevationData.elevationChartData.length) return;
      const yAxisMax = convertMetersToElevationUnit(elevationData?.maxAltitude, backend.preferredUnit);

      const yAxisMin = convertMetersToElevationUnit(elevationData?.minAltitude, backend.preferredUnit);

      const yAxisTickLabel = calculateElevationChartRange(yAxisMax, yAxisMin, backend.preferredUnit);
      return {yAxisTickLabel, yAxisMax, yAxisMin};
    },
    speed: ({userSpecifiedSpeed}, getters, {backend}) => {
      if (userSpecifiedSpeed === null) {
        if (backend.preferredUnit === UnitTypes.IMPERIAL.milesString) return RouteBuilderSpeedTimeDefaults.MPH_DEFAULT;
        return RouteBuilderSpeedTimeDefaults.KPH_DEFAULT;
      } else {
        return userSpecifiedSpeed;
      }
    },
    estimatedTime: ({rideTimeDefault}, {totalRouteDistance, speed}, {backend}) => {
      if (totalRouteDistance === 0) return rideTimeDefault;

      const convertedDistance = convertMetersToDistanceUnit(totalRouteDistance, backend.preferredUnit);
      const calculatedTime = convertedDistance / speed;

      const splitDecimalValues = calculatedTime.toFixed(3).split('.');
      let hoursFormatted = splitDecimalValues[0].padStart(2, '0');

      const fractionalValue = splitDecimalValues[1] / 1000;
      let minutesFormatted = Math.round(fractionalValue * 60)
        .toString()
        .padStart(2, '0');

      const formattedTime = `${hoursFormatted}:${minutesFormatted}`;

      return formattedTime;
    },
    userRouteUnits: (state, getters, {backend}) => {
      let elevationUnit, distanceUnit;
      if (backend.preferredUnit === UnitTypes.IMPERIAL.milesString) {
        elevationUnit = window.vm.$t('rcp.routeBuilder.distance.unit.ft');
        distanceUnit = window.vm.$t('storeFinder.distance.unit.mi');
      } else if (backend.preferredUnit === UnitTypes.METRIC.kilometersAbbreviation) {
        elevationUnit = window.vm.$t('rcp.routeBuilder.distance.unit.m');
        distanceUnit = window.vm.$t('storeFinder.distance.unit.km');
      }
      return {elevationUnit, distanceUnit};
    },
  },

  mutations: {
    setCurrentRoute(state, payload) {
      state.currentRoute = payload;
    },
    setRouter(state, payload) {
      state.router = payload;
    },
    setClickMode(state, clickMode) {
      state.clickMode = RouteBuilderClickModes[clickMode];
    },
    setMapViewMode(state, viewMode) {
      state.mapView = viewMode;
    },
    setIsRightRailOpen(state, status) {
      state.isRightRailOpen = status;
    },
    setIsElevationDrawerOpen(state, status) {
      state.isElevationDrawerOpen = status;
    },
    addWaypointObject(state, waypointObj) {
      Vue.set(state.waypoints, waypointObj.id, waypointObj);
    },
    addControlpointObject(state, waypointObj) {
      state.waypoints.splice(waypointObj.id, 0, waypointObj);
    },
    updateWaypointsIndex(state) {
      state.waypoints.forEach((waypoint, index) => {
        if (waypoint.id === index) return;
        waypoint.id = index;
      });
    },
    setCurrentZoomLevel(state, payload) {
      state.zoomValue = payload;
    },
    setIncreasedZoomLevel(state) {
      state.zoomValue = Math.min(state.maxZoom, state.zoomValue + 1);
    },
    setDecreasedZoomLevel(state) {
      state.zoomValue = Math.max(state.minZoom, state.zoomValue - 1);
    },
    setScrollZoom(state, value) {
      state.isScrollZoomEnabled = value;
    },
    setFeatureTypes(state, featureTypes) {
      state.featureTypes = featureTypes;
    },
    setUtilityDrawerWidth(state, payload) {
      state.utilityDrawerWidth = payload;
    },
    setRightRailDrawerWidth(state, payload) {
      state.rightRailDrawerWidth = payload;
    },
    setBottomDrawerHeight(state, payload) {
      state.bottomDrawerHeight = payload;
    },
    setElevationData(state, elevationData) {
      state.elevationData = elevationData;
    },
    setClearMapDialogVisibility(state, payload) {
      state.isClearMapDialogVisible = payload;
    },
    removeWaypoint(state, index) {
      state.waypoints.splice(index, 1);
    },
    clearWayPoints(state) {
      state.waypoints = [];
    },
    setAutoCenter(state, value) {
      state.isAutoCenterEnabled = value;
    },
    clearElevationData(state) {
      state.elevationData = {};
    },
    setRoutingOption(state, option) {
      state.routingOption = option;
    },
    setSnapToRoad(state, value) {
      state.snapToRoad = value;
    },
    setChartOptions(state, options) {
      state.chartOptions = options;
    },
    clearElevationChartOptions(state) {
      state.chartOptions = DEFAULT_CHART_OPTIONS;
    },
    setIsCuePopupOpen(state, status) {
      state.isCuePopupOpen = status;
    },
    saveCue(state, {id, description, maneuver, maneuverImage}) {
      const cue = state.waypoints.flatMap((waypoint) => waypoint.cues).find((cue) => cue.id === id);
      if (!cue) return;
      cue.description = description;
      cue.maneuver = maneuver;
      cue.maneuverImage = maneuverImage;
    },
    deleteCue(state, {id}) {
      const waypointContainingCueToDelete = state.waypoints.find((waypoint) =>
        waypoint.cues.some((cue) => cue.id == id)
      );
      const indexToRemove = waypointContainingCueToDelete.cues.findIndex((cue) => cue.id === id);
      const distanceOfCueBeingDeleted = waypointContainingCueToDelete.cues[indexToRemove].distance;
      const priorCue = waypointContainingCueToDelete.cues[indexToRemove - 1];
      priorCue.distance += distanceOfCueBeingDeleted;
      waypointContainingCueToDelete.cues.splice(indexToRemove, 1);
    },
    clearUndoRedoStack(state) {
      state.routeHistory.length = 0;
      state.currentHistoryIndex = -1;
    },
    setClearMapFeatures(state, value = false) {
      state.clearMapFeatures = value;
    },
    updateWaypoints(state, waypoints) {
      state.waypoints = [...waypoints];
    },
    setSaveMapDialogVisibility(state, payload) {
      state.isSaveMapDialogVisible = payload;
    },
    setSavedRouteId(state, routeId) {
      state.savedRouteId = routeId;
    },
    setReserveMapState(state, mapState) {
      state.reserveMapState = mapState;
    },
    setRouteSaveFormDefaults(state, value) {
      state.routeSaveFormDefaults = value;
    },
    setUserSpecifiedSpeed(state, value) {
      state.userSpecifiedSpeed = value;
    },
    resetEstimatedRouteTime(state) {
      state.rideTimeDefault = RouteBuilderSpeedTimeDefaults.RIDE_TIME;
    },
    setHasUnsavedChanges(state, value) {
      state.hasUnsavedChanges = value;
    },
    setIsLoadingRoute(state, value) {
      state.isLoadingRoute = value;
    },
    setDeleteRouteDialogVisibility(state, payload) {
      state.isDeleteRouteDialogVisible = payload;
    },
    setRouteDeletedMsg(state, message) {
      state.routeDeletedMsg = message;
    },
  },
  actions: {
    async fetchFeatureTypes({commit}) {
      try {
        const response = await occInstance.get('rcp/featuretypes?fields=DEFAULT');
        const featureTypes = response?.data;
        commit('setFeatureTypes', featureTypes);
      } catch (error) {
        console.error('fetchFeatureTypes error:', error);
        return error;
      }
    },
    updateElevationData({commit, state, getters, rootState}, markerCallbacks) {
      const elevationChartData = state.waypoints
        .map((waypoint) => waypoint.segmentElevationData)
        .filter((elevation) => elevation);
      commit('setElevationData', createElevationDataObject(elevationChartData, rootState.backend.preferredUnit));
      if (!elevationChartData.length) return;
      const chartPoints = convertChartDataToPoints(elevationChartData, rootState.backend.preferredUnit);
      commit(
        'setChartOptions',
        convertChartArrayToChartOptions(
          chartPoints,
          rootState.backend.preferredUnit,
          getters.elevationRange,
          markerCallbacks,
          {left: 10, right: 10}
        )
      );
    },
    saveCurrentMapState({state}) {
      state.hasUnsavedChanges = true;
      state.currentHistoryIndex++;
      state.routeHistory.length = state.currentHistoryIndex;
      state.routeHistory.push([...state.waypoints]);
      if (state.routeHistory.length > RouteBuilderMapDefaults.UNDO_REDO_STACK_SIZE_LIMIT) {
        state.reserveMapState = state.routeHistory.shift();
        state.currentHistoryIndex--;
      }
    },
    saveStateOnUndo({state}) {
      state.hasUnsavedChanges = true;
      state.currentHistoryIndex--;
      if (state.currentHistoryIndex >= 0) {
        state.waypoints = [...state.routeHistory[state.currentHistoryIndex]];
      } else {
        state.hasUnsavedChanges = false;
        state.waypoints = [...state.reserveMapState];
      }
      state.clearMapFeatures = true;
    },
    saveStateOnRedo({state}) {
      state.hasUnsavedChanges = true;
      state.currentHistoryIndex++;
      state.waypoints = [...state.routeHistory[state.currentHistoryIndex]];
      state.clearMapFeatures = true;
    },
    saveCueChanges({commit, getters, state}, payload) {
      const maneuverImage = getters.cuesManeuverImageMapping[payload.maneuver];
      commit('saveCue', {...payload, maneuverImage});
      state.hasUnsavedChanges = true;
    },
  },
};

export default rcpBuilder;
