import occInstance from '@/api/instances/occ';
import axios from 'axios';
import {Cloudinary} from '@/constants/cloudinary.js';

const CloudinaryApi = {
  async getCloudinarySignature(params = {}) {
    const {data} = await occInstance.get('/cloudinary/signature', {params});
    return data.signatureToken;
  },
  async deleteAssetFromCloudinary(assetId) {
    const CLOUDINARY_DESTROY_URL = 'https://api.cloudinary.com/v1_1/trekbikes/image/destroy';
    const timestamp = Date.now();
    const signature = await this.getCloudinarySignature({public_id: assetId, timestamp});
    const params = {
      public_id: assetId,
      timestamp,
      api_key: Cloudinary.API_KEY,
      signature,
    };
    await axios.post(CLOUDINARY_DESTROY_URL, params);
  },
};

export {CloudinaryApi};
