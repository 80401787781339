import storefrontInstance from '@/api/instances/storefront';

const SizeChartApi = {
  getSizeChartData: async (productId) => {
    if (!productId) return;
    try {
      const url = `/v1/api/product/${productId}/sizeChart/`;
      const sizeChartData = await (await storefrontInstance.get(url)).data?.data;
      return sizeChartData;
    } catch (error) {
      throw Error(`Failed to fetch size chart data for product ${productId}`);
    }
  },
};

export default SizeChartApi;
