'use strict';
import storefrontInstance from '@/api/instances/storefront';

export default function () {
  $('.geo-prompt-modal--dismiss-button').click(async function () {
    $('.geo-prompt-modal').css('display', 'none');
    const endpointUrl = '/set-location-prompt-cookie';
    await storefrontInstance.post(endpointUrl);
  });
}
