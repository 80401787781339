class Storage {
  constructor(storage) {
    if (!storage) {
      console.error('Storage not defined!');
    }
  }

  /**
   * Read a value from storage.
   * @param name
   * @returns {*}
   */
  read(name) {
    let value = window.localStorage.getItem(name);
    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  /**
   * Check whether a record with the given name exists in the storage.
   * @param name
   * @return {boolean}
   */
  exists(name) {
    return window.localStorage.getItem(name) !== null;
  }

  /**
   * Save a value to storage.
   * @param name
   * @param value
   */
  save(name, value) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }

  /**
   * Remove value from storage
   * @param name
   */
  remove(name) {
    window.localStorage.removeItem(name);
  }
}

export {Storage};
