import Vue from 'vue';
import {AllocatedItemsApi} from '@/api/allocated-items';
import {convertMapToArrayDeep, groupByDeep, sortList} from '@/utils/entries-helpers';
import {ProductTypeOrder} from '@/constants/product';

const allocatedItemsModule = {
  namespaced: true,
  state: {
    entries: [],
    totals: null,
    freightTotals: null,
    warehousesSubtotals: null,
    warehouses: [],
    holdOptions: {},
  },
  getters: {
    entriesTree(state) {
      return groupByDeep(
        state.entries,
        (entry) => entry.productType,
        (entry) => entry.warehouse
      );
    },
    // VUE 2 do not support Map iteration inside v-for
    // Convert Map to Array to be able to render tree in template
    entriesListTree(_, getters) {
      return convertMapToArrayDeep(getters.entriesTree);
    },
    eligibleEntriesForTotals(state) {
      return state.entries.filter((entry) =>
        Object.keys(state.holdOptions).length > 0 ? !state.holdOptions[entry.productType] : true
      );
    },
    isAllEntriesUnselected(state) {
      return state.entries.every((item) => !item.selected);
    },
    entriesProductTypes(state) {
      return [...new Set(state.entries.map((entry) => entry.productType))];
    },
    warehouseById(state) {
      return groupByDeep(state.warehouses, (warehouse) => warehouse.id);
    },
    hasSelectedAllocatedItems(state) {
      return state.entries.some((entry) => entry.selected);
    },
    hasProductTypesOnHold(state, getters) {
      return getters.entriesProductTypes.some((productType) => state.holdOptions[productType]);
    },
    productTypesOnHold(state) {
      return Object.keys(state.holdOptions).filter((item) => state.holdOptions[item]);
    },
  },
  mutations: {
    setEntries(state, payload) {
      const warehousesOrder = state?.warehouses.map((warehouse) => warehouse.id);
      state.entries = sortList(
        payload,
        [ProductTypeOrder, (entry) => entry.productType],
        [warehousesOrder, (entry) => entry.warehouse]
      );
      state.entries.forEach((entry) => {
        Vue.set(entry, 'selected', entry.selected);
      });
    },
    setTotals(state, payload) {
      state.totals = payload;
    },
    setFreightTotals(state, payload) {
      state.freightTotals = payload;
    },
    setWarehousesSubtotals(state, payload) {
      state.warehousesSubtotals = payload;
    },
    setWarehouses(state, payload = []) {
      state.warehouses = payload.sort((w1, w2) => w1.weight - w2.weight);
    },
    setHoldOptions(state, payload) {
      state.holdOptions = payload;
    },
    toggleAllocatedItemsSelection(state, payload) {
      state.entries = state.entries.map((entry) => ({
        ...entry,
        selected: payload,
      }));
    },
    toggleSelection(state, payload) {
      const entryIndex = state.entries.findIndex((entry) => entry.id === payload.id);
      state.entries[entryIndex].selected = payload.selected;
    },
  },
  actions: {
    toggleEntriesSelection({state}, {productTypes = [], isSelected}) {
      const entries = [...state.entries];
      entries.forEach((entry) => {
        if (productTypes.includes(entry.productType)) {
          Vue.set(state.entries[state.entries.indexOf(entry)], 'selected', isSelected);
        }
      });
    },
    async fetchCart({commit}) {
      const response = await AllocatedItemsApi.getCart();

      commit('setEntries', response.entries);
      commit('setWarehouses', response.warehouses);
      commit('setWarehousesSubtotals', response.subtotals);
    },
    async fetchTotals({getters, commit}) {
      const response = await AllocatedItemsApi.getTotalsWithAllocated(getters.eligibleEntriesForTotals);

      commit('setTotals', response);
      commit('setFreightTotals', null);
    },
    async fetchTotalsWithFreight({getters, commit}) {
      const response = await AllocatedItemsApi.getTotalsWithFreight(getters.eligibleEntriesForTotals);

      commit('setFreightTotals', response);
    },

    async fetchHoldOptions({commit}) {
      const response = await AllocatedItemsApi.getHoldOptions();

      commit('setHoldOptions', response);
    },
  },
};

export default allocatedItemsModule;
