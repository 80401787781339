'use strict';

export default function (element) {
  function iPhoneSniff() {
    return /iP(ad|hone|od).*OS/.test(window.navigator.userAgent);
  }

  function androidSniff() {
    return /Android/.test(window.navigator.userAgent);
  }

  function mobileSniff() {
    return iPhoneSniff() || androidSniff();
  }

  var mousey;

  function delayedHover(obj) {
    var $relation = $(obj).attr('id');
    $('.primary-navigation__link a').removeClass('over');
    $(obj).addClass('over');
    $('.primary-navigation__large-lifestyle').removeClass('over');
    $("[data-rel='" + $relation + "']").addClass('over');
  }

  $('.primary-navigation__link a').on('mouseover', function () {
    var moused = $(this);
    var $isMobile = mobileSniff();
    if ($isMobile === false) {
      mousey = window.setTimeout(function () {
        delayedHover(moused);
      }, 150);
    }
  });

  $('.primary-navigation__link a').on('mouseout', function () {
    var $isMobile = mobileSniff();
    if ($isMobile === false) {
      window.clearTimeout(mousey);
    }
  });

  // WCP-3585
  // This makes sure the main nav and secondary menu bars disappear after clicking on a link
  // since on Android the menus don't get dismissed after tap
  $('.primary-navigation__link a').on('click', function () {
    if (androidSniff() === true) {
      $('#primaryNavigationBar').hide();
      $('.primary-navigation__secondary-links').hide();
    }
  });

  $('.hamburger-icon').on('click', function () {
    if ($(this).hasClass('open')) {
      $('html').removeClass('navOpen');
      $('body').removeClass('navOpen');

      //force repaint to fix ios 10.3 issues with fixed position items.
      $('html').removeClass('repaint');
    } else {
      $('html').addClass('navOpen');
      $('body').addClass('navOpen');
      $('html').addClass('repaint');
    }
    $(this).toggleClass('open');
    $('.reveal').foundation('close');
    $('#primaryNavigationBar').toggleClass('open');
    $('#abbreviatedNavigationBar').toggleClass('open');
  });

  $('.tabs-title > a').on('click', function () {
    $(document).find('.over').removeClass('over');
  });

  // This is a non standard way to open the reveal but the desire to
  // toggle the menu required a deviation from the OTB way.
  var menuBlocker = false;
  var selectedMenuItemId = '';
  var visibleMenuModal = '';
  $('.menu-trigger').on('click keypress', function (e) {
    selectedMenuItemId = $(this).attr('id');
    var clickKeyCode = 1;
    var enterKeyCode = 13;
    if ((e.which === clickKeyCode || e.which === enterKeyCode) && menuBlocker === false) {
      $('html').removeClass('repaint');
      $('html').addClass('repaint');

      //double clicking the mnu can cause the script to get crazy, so this helps fast clickers
      var modal = $(this).attr('data-opens');
      var $relation = $(this).attr('id');

      visibleMenuModal = modal;

      menuBlocker = true;
      window.setTimeout(function () {
        menuBlocker = false;
      }, 500);
      $(document).find('.menu-trigger.active').removeClass('active');
      $(this).addClass('active');
      if ($('#' + modal).length > 0) {
        $('#' + modal).foundation('toggle');
      }
      if ($('body').hasClass('is-reveal-open') === true) {
        // Force always open the first pane
        var firstTab = $('#' + modal).find('.tabs-panel')[0];
        if ($('#' + modal + '__tabs').length > 0) {
          $('#' + modal + '__tabs').foundation('selectTab', $(firstTab));
        }
        $("[data-rel='" + $relation + "']").addClass('over');
      }
    }
  });

  $(document).on('change.zf.tabs', function () {
    // Tabs panel content shifts whn scroll bars are required,  this equalizes the tabs position
    var myTabs = $(document).find('.tabs-panel.is-active');
    myTabs.scrollTop(0);

    if (visibleMenuModal) {
      var activeTabContent = $(`#${visibleMenuModal}`).find('.tabs-panel.is-active');
      var activeTabContainer = activeTabContent.attr('id');
      var lastMenuItem = $(`#${activeTabContainer} > .primary-navigation__links`).children().last();
      // clean up old focusout event listeners to avoid memory leaks
      lastMenuItem.children().off('focusout');
      lastMenuItem.children().on('focusout', function () {
        $(`#${activeTabContainer}-label`).trigger('focus');
      });
    }
  });

  $(document).on('open.zf.reveal', function (e) {
    $('body').attr('data-revealed', $(e.target).attr('id'));
    $(document).find('.over').removeClass('over');
  });

  $(document).on('closed.zf.reveal', function () {
    $('.primary-navigation__secondary-links').attr('data-animation-out', '');
    if (selectedMenuItemId) {
      $('#' + selectedMenuItemId).trigger('focus');
    }
  });

  $('.js-site-search-icon').on('click', function () {
    $('.js-site-search-input').trigger('focus');
  });

  $('#primary-navigation__search').on('click', function () {
    $('.js-site-search-input').trigger('focus');
  });

  // #search needed since there are two instances of input_SearchBox
  $('#search .js-site-search-input').on('focus', function () {
    $('#search').removeClass('exitStageTop');
  });

  $('.js-site-search-input').on('focusout', function () {
    $('#search').addClass('exitStageTop');
  });

  // Bind the separate button/input for B2B
  $('#mobile-header-search-button').on('click', function () {
    $('.js-mobile-search-input').trigger('focus');
  });

  $('.js-mobile-search-input').on('focus', function () {
    $('#search').removeClass('exitStageTop');
  });

  $('.js-mobile-search-input').on('focusout', function () {
    $('#search').addClass('exitStageTop');
  });

  $('.search_submit').on('mousedown', function (e) {
    e.preventDefault();
    $('.js-site-search-input').trigger('focus');
    $(this).parent('form').trigger('submit');
  });

  // TBC-5085
  // Dropdown Menu for Login / My Account / Logout
  // ------------------------------------------
  var utilityMenu = $('.dropdown.menu.utility');

  // On show, have menu fade-in
  utilityMenu.on('show.zf.dropdownmenu', function () {
    var dropdown = $(this).find('.is-dropdown-submenu');
    dropdown.css('display', 'none');
    dropdown.fadeIn(400);
  });

  // On hide, have menu fade-out
  utilityMenu.on('hide.zf.dropdownmenu', function () {
    var dropdown = $(this).find('.is-dropdown-submenu');
    dropdown.css('display', 'inherit');
    dropdown.fadeOut(400);
  });

  // Trigger initial resize
  $(element).trigger('resize');

  if ($('#isKioskMode').length) {
    $('[id*="retailersLink-"]').parent().hide();
    $('[id*="viewRetailerLocator-"]').parent().hide();
    $('[id*="viewElectraRetailerLocator-"]').parent().hide();
  }
}
