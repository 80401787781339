/**
 * Generate a QAID from the given string.
 * @param input
 * @return {*}
 */
const toId = (input) => {
  if (!input) {
    return '';
  }

  input = input.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, idx) => {
    if (/\s+/.test(match)) {
      return '';
    }

    return !idx ? match.toLowerCase() : match.toUpperCase();
  });

  return input.replace(/[^\w\s]/gi, '');
};

/**
 * A qaid helper mixin.
 * @type {{methods: {toQaid: (function(*=): *)}}}
 */
const qaidMixin = {
  props: {
    qaid: {
      type: String,
      default: '',
    },
  },
  methods: {
    toQaid: (input) => toId(input),
  },
};

export {toId, qaidMixin};
