import {ProductApi} from '@/api/product';
import {Cookie} from '@/utils/cookie';
import isEmpty from 'lodash/isEmpty';
import {asyncQueryAction} from '@/store/util/store-utils';

const localRetailerActions = Object.freeze({
  LOAD_RETAILER_DATA: 'loadRetailerData',
  UPDATE_RETAILER_AVAILABILITY: 'updateRetailerAvailability',
  UPDATE_LOCATION: 'updateLocation',
});

export const retailerActions = Object.entries(localRetailerActions).reduce((obj, [key, value]) => {
  obj[key] = `retailerSearch/${value}`;
  return obj;
}, {});

export const retailerTypes = Object.freeze({
  FETCH_RETAILERS_REQUEST: 'FETCH_RETAILERS_REQUEST',
  FETCH_RETAILERS_SUCCESS: 'FETCH_RETAILERS_SUCCESS',
  FETCH_RETAILERS_FAILURE: 'FETCH_RETAILERS_FAILURE',
  UPDATE_RPA_COOKIE: 'UPDATE_RPA_COOKIE',
  INVALIDATE_CACHE: 'INVALIDATE_CACHE',
});

export const RPA_COOKIE_NAME = 'trek_rpa_user_search_phrase';
export const RPA_COOKIE_EXPIRATION_MINUTES = 1440;

export const mutations = {
  //On retailer load error, set retailer data to empty object and cancel loading
  [retailerTypes.FETCH_RETAILERS_FAILURE](state) {
    state.isLoadingRetailerData = false;
    state.retailerData = {};
  },
  //on load request, set loading state to true
  [retailerTypes.FETCH_RETAILERS_REQUEST](state) {
    state.isLoadingRetailerData = true;
  },
  //on success, cancel loading & set data
  [retailerTypes.FETCH_RETAILERS_SUCCESS](state, {retailerData}) {
    state.isLoadingRetailerData = false;
    state.isLocationPostalCodeCorrect = true;
    state.retailerData = retailerData;
  },
  //set rpa cookie value
  [retailerTypes.UPDATE_RPA_COOKIE](state, {locationText}) {
    const rpaCookieValue =
      locationText || state.retailerData?.locationPostalCode || state.retailerData?.locationPostalCode;
    Cookie.set(RPA_COOKIE_NAME, rpaCookieValue, RPA_COOKIE_EXPIRATION_MINUTES);
  },
  //invalidate current location code
  [retailerTypes.INVALIDATE_CACHE](state) {
    state.retailerData.locationPostalCode = Cookie.get(RPA_COOKIE_NAME);
    state.isLocationPostalCodeCorrect = false;
  },
};

const actions = {
  /**
   * Make async call to product api to populate retailer data
   */
  async loadRetailerData({commit, state}, {variant, inStockOnly, locationPostalCode} = {}) {
    const retrieveRetailerData = async () => {
      if (!variant || isEmpty(variant)) {
        return;
      }

      const options = {
        productCode: variant.code,
        retailersMax: 10,
        inStockOnly,
        isInStockOnline: variant.isInStockOnline ?? false,
        locationText: locationPostalCode || state.retailerData.locationPostalCode || Cookie.get(RPA_COOKIE_NAME) || '',
      };

      const retailerData = await ProductApi.getRetailerAvailability(options);
      if (!retailerData) {
        throw Error('Failed to load retailer data');
      }
      return {retailerData, locationText: options.locationText};
    };
    asyncQueryAction(
      retrieveRetailerData,
      retailerTypes.FETCH_RETAILERS_REQUEST,
      retailerTypes.FETCH_RETAILERS_SUCCESS,
      retailerTypes.FETCH_RETAILERS_FAILURE
    ).run({commit});
  },
  /**
   * Action for refetching retailer list
   */
  async updateRetailerAvailability({dispatch, commit, state}, options) {
    await dispatch(localRetailerActions.LOAD_RETAILER_DATA, options);
    //check retailer list - if no retailers, invalidate retailer data cache
    if (!state?.retailerData?.retailers || !state.retailerData?.retailers.length) {
      commit(retailerTypes.INVALIDATE_CACHE);
    }
  },
  /**
   * Action to update zip code
   */
  async updateLocation({commit}, {location}) {
    commit(retailerTypes.UPDATE_RPA_COOKIE, {locationText: location});
  },
};

const retailerSearch = {
  namespaced: true,
  state: {
    isLoadingRetailerData: false,
    isLocationPostalCodeCorrect: true,
    retailerData: {},
  },
  mutations: mutations,
  actions: actions,
};

export default retailerSearch;
