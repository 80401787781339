import {UnitTypes} from '@/constants/unit-types';

const DEFAULT_CHART_OPTIONS = {
  chart: {
    height: 120,
    type: 'area',
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
    },
    area: {
      fillColor: 'var(--gray-30)',
      shadow: false,
      opacity: 0.6,
      marker: {
        fillColor: 'var(--blue-100)',
        radius: 5,
        lineColor: 'var(--blue-140)',
        lineWidth: '1px',
      },
    },
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },

  xAxis: {
    labels: {
      format: '',
    },
    title: {
      text: '',
    },
    min: 0,
    distance: 0,
    tickLength: 0,
    max: null,
    crosshair: {
      color: 'var(--blue-100)',
      dashstyle: 'solid',
    },
  },

  yAxis: {
    startOnTick: true,
    endOnTick: true,
    tickAmount: 5,
    tickPosition: 'inside',
    gridLineColor: 'var(--gray-100)',
    gridLineDashStyle: 'dot',
    maxPadding: 0,
    tickInterval: null,
    max: null,
    min: null,
    title: {
      text: null,
    },
    labels: {
      format: '',
    },
  },

  tooltip: {
    headerFormat: '',
    pointFormat: '',
    backgroundColor: 'var(--white)',
    opacity: 1,
    distance: 0,
    borderWidth: 0,
    hideDelay: 0,
    style: {
      fontSize: '0.75rem',
      color: 'var(--gray-100)',
      fontFamily: 'var(--font-body-regular)',
    },
    shadow: false,
    positioner: function (labelWidth, labelHeight, point) {
      let toolTipX = point.plotX - labelWidth / 2 - 12;
      let toolTipY = point.plotY - labelHeight / 2 + 10;
      if (toolTipX < labelWidth + 20) {
        toolTipX += labelWidth + 36;
      }
      return {x: toolTipX, y: toolTipY};
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      data: [],
      lineColor: 'var(--gray-30)',
      fillOpacity: 0.6,
      name: 'Elevation',
      marker: {
        enabled: false,
      },
      threshold: null,
      point: {
        events: {},
      },
    },
  ],
};

const convertChartArrayToChartOptions = (
  chartArray,
  userPreferredUnit,
  elevationRange,
  markerCallbacks,
  chartSpacing
) => {
  //To update values of labels and tooltip units
  const xAxisLabel =
    UnitTypes.IMPERIAL.milesString === userPreferredUnit
      ? window.vm.$t('storeFinder.distance.unit.mi')
      : window.vm.$t('storeFinder.distance.unit.km');
  const yAxisLabel =
    UnitTypes.IMPERIAL.milesString === userPreferredUnit
      ? window.vm.$t('rcp.routeBuilder.distance.unit.ft')
      : window.vm.$t('rcp.routeBuilder.distance.unit.m');

  const xAxisLabelFormat = (value) => `${value} ${xAxisLabel}`;
  const yAxisLabelFormat = (value) => `${value} ${yAxisLabel}`;

  const lastDataPoint = chartArray?.length - 1;

  //Map data as expected in highcharts
  const updatedChartArray = chartArray?.map((point) => {
    return {
      x: point?.elevation[0],
      y: point?.elevation[1],
      geometry: point?.geometry,
    };
  });

  const updateChartOptions = {
    ...DEFAULT_CHART_OPTIONS,
    chart: {...DEFAULT_CHART_OPTIONS.chart, spacingLeft: chartSpacing.left, spacingRight: chartSpacing.right},
    xAxis: {
      ...DEFAULT_CHART_OPTIONS.xAxis,
      labels: {
        formatter: function () {
          return xAxisLabelFormat(this.value);
        },
      },
      max: updatedChartArray[lastDataPoint]?.x ?? null,
    },
    yAxis: {
      ...DEFAULT_CHART_OPTIONS.yAxis,
      labels: {
        formatter: function () {
          return yAxisLabelFormat(this.value);
        },
      },
      max: elevationRange?.yAxisTickLabel,
      tickPositioner: function () {
        const positions = [];
        const tickAmount = 5;
        let tick = Math.floor(elevationRange?.yAxisMin);
        const increment = (elevationRange?.yAxisTickLabel - elevationRange?.yAxisMin) / 4;
        if (elevationRange?.yAxisTickLabel !== null && elevationRange?.yAxisMin !== null) {
          for (let tickNumber = 0; tickNumber < tickAmount; tickNumber++) {
            positions.push(Math.ceil(tick));
            tick += increment;
          }
        }
        return positions;
      },
    },
    tooltip: {
      ...DEFAULT_CHART_OPTIONS.tooltip,
      headerFormat: `${window.vm.$t(
        'rcp.routeBuilder.utility.distance'
      )} <span class='font-bold'> {point.x:.1f} ${xAxisLabel} </span><br/>`,
      pointFormat: `${window.vm.$t(
        'rcp.routeBuilder.utility.elevation'
      )} <span class='font-bold'> {point.y:.0f} ${yAxisLabel} </span>`,
    },
    series: [
      {
        data: updatedChartArray,
        lineColor: 'var(--gray-30)',
        fillOpacity: 0.6,
        marker: {
          enabled: false,
        },
        threshold: null,
        point: {
          events: {
            mouseOver(event) {
              markerCallbacks.handleChartPointHover(event);
            },
            mouseOut() {
              markerCallbacks.hideHoverIconFromChart();
            },
          },
        },
      },
    ],
  };

  return updateChartOptions;
};

function updateChartSize(chart) {
  //reducing 20px from full width for spacing
  const chartContainerWidth =
    document
      .getElementById('rcp-builder-elevation-graph__drawer')
      ?.getElementsByClassName('pdl-drawer__content-wrapper')[0]?.clientWidth - 20;

  // calling the setSize method to update chart width
  chart.chart.setSize(chartContainerWidth, 120);
}

export {DEFAULT_CHART_OPTIONS, convertChartArrayToChartOptions, updateChartSize};
