export default {
  _default: function (n) {
    return 'Поље ' + n + ' није валидно';
  },
  after: function (n, e) {
    return 'Поље ' + n + ' мора бити после ' + e[0];
  },
  alpha: function (n) {
    return 'Поље ' + n + ' може садржати само слова';
  },
  alpha_dash: function (n) {
    return 'Поље ' + n + ' може садржати алфанумеричке карактере и повлаке';
  },
  alpha_num: function (n) {
    return 'Поље ' + n + ' може садржати само алфанумеричке карактере';
  },
  alpha_spaces: function (n) {
    return 'Поље ' + n + ' може садржати само алфанумеричке карактере и размаке';
  },
  before: function (n, e) {
    return 'Поље ' + n + ' мора бити пре ' + e[0];
  },
  between: function (n, e) {
    return 'Поље ' + n + ' мора бити између ' + e[0] + ' и ' + e[1];
  },
  confirmed: function (n) {
    return 'Потврда поља ' + n + ' се не поклапа';
  },
  credit_card: function (n) {
    return 'Поље ' + n + ' није валидно';
  },
  date_between: function (n, e) {
    return 'Поље ' + n + ' мора бити између ' + e[0] + ' и ' + e[1];
  },
  date_format: function (n, e) {
    return 'Поље ' + n + ' мора бити у формату ' + e[0];
  },
  decimal: function (n, e) {
    void 0 === e && (e = []);
    var t = e[0];
    return (
      void 0 === t && (t = '*'),
      'Поље ' + n + ' мора бити број и може садржати' + (t && '*' !== t ? ' ' + t : '') + ' децималних места'
    );
  },
  digits: function (n, e) {
    return 'Поље ' + n + ' мора бити број и садржати тачно ' + e[0] + ' цифара';
  },
  dimensions: function (n, e) {
    return 'Поље ' + n + ' мора бити ' + e[0] + ' x ' + e[1] + ' пиксела';
  },
  email: function (n) {
    return 'Поље ' + n + ' мора бити валидан имејл';
  },
  excluded: function (n) {
    return 'Поље ' + n + ' мора имати валидну вредност';
  },
  ext: function (n) {
    return 'Поље ' + n + ' мора бити валидан фајл';
  },
  image: function (n) {
    return 'Поље ' + n + ' мора бити слика';
  },
  included: function (n) {
    return 'Поље ' + n + ' мора бити валидна вредност';
  },
  ip: function (n) {
    return 'Поље ' + n + ' мора бити валидна "ај пи" адреса';
  },
  max: function (n, e) {
    return 'Поље ' + n + ' не сме бити дуже од ' + e[0] + ' карактера';
  },
  max_value: function (n, e) {
    return 'Поље ' + n + ' не сме бити веће од ' + e[0];
  },
  mimes: function (n) {
    return 'Поље ' + n + ' мора бити валидан тип фајла';
  },
  min: function (n, e) {
    return 'Поље ' + n + ' мора садржати најмање ' + e[0] + ' карактера';
  },
  min_value: function (n, e) {
    return 'Поље ' + n + ' не сме бити мање од ' + e[0];
  },
  numeric: function (n) {
    return 'Поље ' + n + ' мора бити број';
  },
  regex: function (n) {
    return 'Формат поља ' + n + ' није валидан';
  },
  required: function (n) {
    return 'Поље ' + n + ' је обавезно';
  },
  size: function (n, e) {
    return (
      'Поље ' +
      n +
      ' мора бити мање од ' +
      (function (n) {
        var e = 1024,
          t = 0 === (n = Number(n) * e) ? 0 : Math.floor(Math.log(n) / Math.log(e));
        return 1 * (n / Math.pow(e, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t];
      })(e[0])
    );
  },
  url: function (n) {
    return 'Поље ' + n + ' није валидна веб адреса';
  },
};
