import {stockStatusConfig} from '@/constants/stock-status';

const stockActions = Object.freeze({
  RETRIEVE_STOCK_MESSAGE: 'getStockMessage',
});

export const stockDispatchActions = Object.entries(stockActions).reduce((obj, [key, value]) => {
  obj[key] = `productStockData/${value}`;
  return obj;
}, {});

export const stockTypes = Object.freeze({
  GET_STOCK_MESSAGE: 'GET_STOCK_MESSAGE',
});

export const mutations = {
  [stockTypes.GET_STOCK_MESSAGE](state, {stockStatus}) {
    state.stockMessage = stockStatusConfig[stockStatus]?.stockStatusMessage;
  },
};

const actions = {
  /**
   * Pull stock data from variant stock status
   */
  async getStockMessage({commit}, {stockStatus}) {
    commit(stockTypes.GET_STOCK_MESSAGE, {stockStatus});
  },
};

const productStockData = {
  namespaced: true,
  state: {
    isLoadingStockData: false,
    stockMessage: {},
  },
  mutations: mutations,
  actions: actions,
};

export default productStockData;
