import axios from 'axios';
import occConfig from '@/api/instances/occ.config';
import AuthTokens from '@/utils/oauth/auth-tokens';
import userStore, {userActions} from '@/store/modules/user';
import backendModule from '@/store/modules/backend.js';

const url = occConfig.url.BASE_URL;

const occInstance = axios.create({baseURL: url});

const createAuthHeader = (accessToken) => ({Authorization: `Bearer ${accessToken}`});

const addAccessTokenToRequest = async (config, accessToken) => {
  if (!accessToken) return config;
  const wait = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  await wait(1);
  config.headers = {
    ...config.headers,
    ...createAuthHeader(accessToken),
  };
  return config;
};

const handleOccErrorMessage = (errorList) => {
  throw new Error(errorList.map((err) => err.message).join(', '));
};

const handleErrorResponse = async (error) => {
  const request = error.config;

  try {
    if (error.response?.status === 401) {
      //401 indicates expired tokens. Refresh tokens and add them to the request
      const accessToken = await AuthTokens.refreshAuthTokens(userStore?.state?.userEmail);
      //only add access token to request if refresh produced a new token.
      if (!accessToken) {
        window.store.dispatch(userActions.LOGOUT_USER);
        window.location.href = `${backendModule.state.encodedContextPath}/logout`;
        return;
      }
      occInstance.interceptors.request.use(
        (config) => addAccessTokenToRequest(config, accessToken),
        () => {
          console.error(error);
        }
      );
      return occInstance(request);
    }

    if (error.response?.data?.errors?.length > 1) return handleOccErrorMessage(error.response.data.errors);

    throw new Error(error.message, {
      cause: error.response,
    });
  } catch (err) {
    //We get here if tokens fail to be refreshed - log the error and send forward a new error
    console.error(err);
    throw err;
  }
};

const accessTokenInterceptor = async (config) => {
  const accessToken = AuthTokens.getAccessToken();
  return addAccessTokenToRequest(config, accessToken);
};

//Add the tokens we have to every request
occInstance?.interceptors?.request?.use(accessTokenInterceptor);

occInstance?.interceptors?.response?.use((response) => {
  return response;
}, handleErrorResponse);

export default occInstance;
