const rcpRoutes = {
  namespaced: true,

  state: {
    mapPoint: [0, 0],
    isModifyRideDailogVisible: false,
    detailsPageData: null,
  },
  mutations: {
    setMapPoint(state, payload) {
      state.mapPoint = payload;
    },
    setModifyRideDailogVisibility(state, payload) {
      state.isModifyRideDailogVisible = payload;
    },
    setdetailsPageData(state, payload) {
      state.detailsPageData = payload;
    },
    updateRideDetailsData(state, payload) {
      state.detailsPageData = {...state.detailsPageData, ...payload};
    },
  },
};

export default rcpRoutes;
