<script>
import CompareRack from '@/components/containers/plp/CompareRack';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {mapGetters, mapState} from 'vuex';

export default {
  components: {CompareRack, TrekButton, TrekLink, PdlDialog, PdlSectionHeader, PdlHeading},

  computed: {
    ...mapState('compare', ['compareRackDialogVisible']),
    ...mapGetters('viewSize', ['viewBreakpointLessThan']),
  },

  created() {
    this.closeCompareRackDialog();
  },

  methods: {
    closeCompareRackDialog() {
      this.$store.commit('compare/setCompareRackDialog', false);
    },
  },
};
</script>

<style scoped lang="scss">
//duplicating custom class selector to avoid use of pedal component class for styling
::v-deep .compare-rack-dialog.compare-rack-dialog {
  width: 100%;
  max-width: 75rem;
}
</style>
