const generateScript = (id, src) => {
  if (document.getElementById(id)) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', id);
  script.src = src;
  return script;
};
const generateLink = (id, href, rel) => {
  if (document.getElementById(id)) {
    return;
  }
  const link = document.createElement('link');
  link.setAttribute('href', href);
  link.setAttribute('id', id);
  link.setAttribute('rel', rel);
  return link;
};
const append = async (script) => {
  return new Promise((resolve, reject) => {
    if (!script) resolve();
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);
    document.head.appendChild(script);
  });
};
export default {
  appendScript(id, src) {
    append(generateScript(id, src));
  },
  appendLink(id, href, rel) {
    append(generateLink(id, href, rel));
  },
  async appendAsyncScript(id, src) {
    let script = generateScript(id, src);
    if (!script) return;
    script.async = true;
    await append(script);
  },
};
