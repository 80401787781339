import Vue from 'vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import storefrontInstance from '@/api/instances/storefront';
import unionBy from 'lodash/unionBy';

const productBundleModule = {
  namespaced: true,

  state: {
    bundleTemplateIds: [],
    bundleData: {},
    activeIds: [],
    skusWithBundle: [],
    isVariantsChanged: true,
    buyingZoneQuantity: null,
    bundleMainProducts: [
      {
        bundleMainProductUrl: null,
        bundleMainProductAssetId: null,
        bundleMainProductName: null,
        bundleMainProductMetaData: [{key: '', value: ''}],
        bundleMainProductQuantity: null,
        bundleVariantCode: null,
      },
    ],
    bundleIndex: 0,
    plpBundleProducts: [],
  },

  getters: {
    // Check if every children products array is empty for every active bundle id. If yes then hide certain things.
    isBundleDataEmpty: (state) => state.activeIds.every((id) => isEmpty(get(state.bundleData, [id, 'bundles'], []))),
  },

  mutations: {
    setBundleChildProducts(state, payload) {
      Vue.set(state, 'bundleData', payload);
    },

    setBundleTemplateIds(state, payload) {
      state.bundleTemplateIds = union(state.bundleTemplateIds, payload);
    },

    setActiveIds(state, payload) {
      Vue.set(state, 'activeIds', payload);
    },

    setSkusWithBundle(state, payload) {
      state.skusWithBundle = union(state.skusWithBundle, payload);
    },

    setIsVariantsChanged(state, payload) {
      Vue.set(state, 'isVariantsChanged', payload);
    },

    setBuyingZoneQuantity(state, payload) {
      state.buyingZoneQuantity = payload;
    },

    setBundleMainProduct(state, payload) {
      state.bundleMainProducts = payload;
    },

    setIsVariantCode(state, payload) {
      state.bundleMainProducts[state.bundleIndex].bundleVariantCode = payload;
    },

    setBundleMainProductUrl(state, payload) {
      state.bundleMainProducts[state.bundleIndex].bundleMainProductUrl = payload;
    },

    setBundleMainProductName(state, payload) {
      state.bundleMainProducts[state.bundleIndex].bundleMainProductName = payload;
    },

    setBundleMainProductMetaData(state, payload) {
      state.bundleMainProducts[state.bundleIndex].bundleMainProductMetaData = payload;
    },

    setBundleMainProductQuantity(state, payload) {
      state.bundleMainProducts[state.bundleIndex].bundleMainProductQuantity = payload;
    },

    setBundleMainProductAssetId(state, payload) {
      state.bundleMainProducts[state.bundleIndex].bundleMainProductAssetId = payload;
    },

    setBundleIndex(state, payload) {
      state.bundleIndex = payload;
    },

    addPlpBundleProducts(state, payload) {
      state.plpBundleProducts = unionBy(state.plpBundleProducts, payload, 'code');
    },
  },

  actions: {
    async fetchBundleProducts({state, commit}) {
      try {
        if (state.activeIds.length < 1) throw new Error('No Template Id present');
        const response = await storefrontInstance.get(
          `/v1/api/bundle/info?bundleTemplateIds=${state.activeIds.join()}`
        );
        const data = response?.data?.data ?? {};
        commit('setBundleChildProducts', data);
        commit('setIsVariantsChanged', false);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    addBundleTemplates({commit}, payload) {
      commit('setBundleTemplateIds', payload.id);
      commit('setSkusWithBundle', payload.sku);
    },

    clearMainBundleProducts({commit}) {
      commit('setBundleIndex', 0);
      commit('setBundleMainProduct', [
        {
          bundleMainProductUrl: '',
          bundleMainProductAssetId: null,
          bundleMainProductName: '',
          bundleMainProductMetaData: [{key: '', value: ''}],
          bundleMainProductQuantity: null,
        },
      ]);
    },
  },
};

export default productBundleModule;
