var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-list-vue",attrs:{"qaid":`nav-list-${_vm.uid}`}},[(_vm.columnHeader && !_vm.columnHeaderUrl)?_c('h4',{staticClass:"nav-list-vue__column-header",domProps:{"innerHTML":_vm._s(_vm.columnHeader)}}):_vm._e(),_vm._v(" "),(_vm.columnHeader && _vm.columnHeaderUrl)?_c('trek-link',{staticClass:"nav-list-vue__column-header",attrs:{"href":_vm.composeLink(_vm.columnHeaderUrl)},domProps:{"innerHTML":_vm._s(_vm.columnHeader)}}):_vm._e(),_vm._v(" "),_c('ul',{class:[
      'nav-list-vue__ul',
      {
        'nav-list-vue__ul--columns': _vm.colSpan,
        [`nav-list-vue__ul--col-count-${_vm.colSpan}`]: _vm.colSpan,
        [`nav-list-vue__ul--col-available-${_vm.colsAvailable}`]: _vm.colsAvailable,
      },
    ]},_vm._l((_vm.links),function(link,index){return _c('li',{key:link.uid,class:['nav-list-vue__li', {'nav-list-vue__li--columns': _vm.colSpan}]},[_c('div',{class:['nav-list-vue__li-content', {'nav-list-vue__li-content--last-item': index === _vm.links.length - 1}]},[(link.linkNavMenuIconName)?_c('pdl-icon',{staticClass:"nav-list-vue__icon",attrs:{"size":"18","name":"bolt"}}):_vm._e(),_vm._v(" "),_c('a',{staticClass:"nav-list-vue__link",attrs:{"id":_vm.generateLinkId(link.uid),"qaid":_vm.generateLinkId(link.uid),"href":_vm.composeLink(link.url),"target":_vm.setTarget(link.target)}},[_c('span',{domProps:{"innerHTML":_vm._s(link.linkName)}})])],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }