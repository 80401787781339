const Cloudinary = Object.freeze({
  API_KEY: '116784616392191',
  CLOUD_NAME: 'trekbikes',
});

const RideClubChallengePresets = Object.freeze({
  PROD: 'RideClubChallenges_Prod',
  NON_PROD: 'RideClubChallenges_Non_Prod',
});

export {Cloudinary, RideClubChallengePresets};
