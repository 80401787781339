const apiHost = window.ACC?.customVariables?.occ?.api.host || window.location.host;

const occ = {
  url: {
    BASE_URL: `//${apiHost}/occ/v2/${window.ACC?.siteUid}`,
    CART_URL: `//${apiHost}/occ/v2/${window.ACC?.siteUid?.replace('electra', '')}`,
  },
};

export default occ;
