import { render, staticRenderFns } from "./B2cMiniCart.vue?vue&type=template&id=926b5654&scoped=true&"
import script from "./B2cMiniCart.vue?vue&type=script&lang=js&"
export * from "./B2cMiniCart.vue?vue&type=script&lang=js&"
import style0 from "./B2cMiniCart.vue?vue&type=style&index=0&id=926b5654&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926b5654",
  null
  
)

export default component.exports