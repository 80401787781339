import {PdlToastType} from '@/constants/pdl-toast-type';

export const StatusCodeTypes = {
  SUCCESS: 'success',
  LOW_STOCK: 'lowStock',
  LOW_STOCK_QUANTITY_UPDATE: 'lowStockQuantityUpdate',
  NO_STOCK: 'noStock',
};

const CartModificationConfig = (t) => ({
  [StatusCodeTypes.SUCCESS]: () => ({
    type: PdlToastType.SUCCESS,
    message: t('basket.page.message.update'),
    showClose: true,
  }),
  [StatusCodeTypes.LOW_STOCK]: ({entryName, entryUrl, quantity, requestedQuantity}) => ({
    type: PdlToastType.INFO,
    message: t('basket.page.message.update.reducedNumberOfItemsAdded.lowStock', [
      entryName,
      quantity,
      requestedQuantity,
      entryUrl,
    ]),
    dangerouslyUseHTMLString: true,
  }),
  [StatusCodeTypes.LOW_STOCK_QUANTITY_UPDATE]: ({requestedQuantity}) => ({
    type: PdlToastType.INFO,
    message: t('basket.restoration.requestedQuantityAdjusted', [requestedQuantity]),
    showClose: true,
  }),
  [StatusCodeTypes.NO_STOCK]: ({entryName, entryUrl}) => ({
    type: PdlToastType.ERROR,
    message: t('basket.page.message.update.reducedNumberOfItemsAdded.noStock', [entryName, entryUrl]),
    showClose: true,
    dangerouslyUseHTMLString: true,
  }),
});

const CartRestorationConfig = (t, deliveryModeChanged) => {
  const getMessageKey = (statusCode) =>
    deliveryModeChanged ? 'basket.restoration.delivery.changed' : `basket.restoration.${statusCode}`;

  return ({entryName, entryUrl, quantity, quantityAdded, statusCode}) => ({
    type: PdlToastType.SUCCESS,
    message: t(getMessageKey(statusCode), [entryName, entryUrl, quantity, quantityAdded]),
    showClose: true,
    dangerouslyUseHTMLString: true,
  });
};

function getStatusCode({statusCode, quantity, isQuantityUpdate}) {
  // If isQuantityUpdate flag is on, modification is the result of a PUT to /entries
  // and we should key into a different toast message
  if (statusCode === StatusCodeTypes.LOW_STOCK && isQuantityUpdate) {
    return quantity > 0 ? StatusCodeTypes.LOW_STOCK_QUANTITY_UPDATE : StatusCodeTypes.NO_STOCK;
  }
  return statusCode;
}

export function handleCartModifications(cartModifications) {
  cartModifications?.forEach((modification) => {
    const statusCode = getStatusCode(modification);
    const configFunction = CartModificationConfig((key, positionalParams) => window.VueI18n.t(key, positionalParams))[
      statusCode
    ];
    if (!configFunction) return;

    const payload = {
      entryName: modification?.entry?.product?.name,
      quantity: modification?.quantityAdded,
      requestedQuantity: modification?.quantity,
      entryUrl: modification?.entry?.product?.url,
    };
    const toastConfig = configFunction(payload);
    window.vm?.$notify(toastConfig);
  });
}

export function handleCartRestorations(cartRestorations) {
  cartRestorations?.forEach((restoration) => {
    const configFunction = CartRestorationConfig(
      (key, positionalParams) => window.VueI18n.t(key, positionalParams),
      restoration.deliveryModeChanged
    );
    if (!configFunction) return;

    const payload = {
      entryName: restoration?.entry?.product?.name,
      entryUrl: restoration?.entry?.product?.url,
      ...restoration,
    };

    const toastConfig = configFunction(payload);
    window.vm?.$notify(toastConfig);
  });
}
