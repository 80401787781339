<template>
  <component
    :is="tag"
    :class="{...buttonClasses, ...classes}"
    v-bind="$attrs"
    :type="type || 'button'"
    v-on="$listeners"
  >
    <template v-if="isLoading">
      <span>Loading...</span>
    </template>
    <template v-else>
      <pdl-icon v-if="icon" :name="icon" aria-hidden="true" />
      <span v-if="!iconOnly">
        <slot />
      </span>
    </template>
  </component>
</template>

<script>
import buttonMixin from '@/mixins/button';

export default {
  name: 'TrekButton',
  mixins: [buttonMixin],
  inheritAttrs: false,
  props: {
    /**
     * "button" | "input"
     */
    tag: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'input'].indexOf(value) !== -1;
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'button--loading': this.isLoading,
        'button--disabled': this.$attrs.disabled,
      };
    },
    type() {
      return this.$attrs.type;
    },
  },
};
</script>
