export default {
  id: null,
  color: '',
  contactEmail: '',
  contactName: '',
  contactPhoneNumber: '',
  lastSavedDate: '',
  lastSavedTime: '',
  nonWarClaimFlagCode: 'EnumYesNoTranslated2',
  nonWarClaimFlagValue: 'No',
  notes: '',
  poNumber: '',
  selectedDamagedOccurred: '',
  selectedRequestedAction: '',
  warrantyConsumerWaiting: '',
  isWarrantyRepar: false,

  claimLines: [
    {
      id: '1',
      productType: '',
      qty: null,
      selectedDamagedOccurred: '',
      selectedRequestedAction: '',
      warrantyConsumerWaiting: '',
      warrantyDamageImage: {name: null, url: null, encodedImage: null},
      warrantyPurchaseProofImages: [],
      warrantySerialNumberImage: {name: null, url: null, encodedImage: null},
      warrantyClaimIssueUfoCode: '',
      warrantyLineNumber: '',
      warrantyOptionalAttachments: [],
      serialNumberImage: {name: null, url: null, encodedImage: null},
      damageMethod: {code: null},
      purchasedDate: '',
      purchaseDateDisplay: '',
      serialNumber: '',
      status: '',
      size: '',
      sku: '',
      modelYear: '',
      modelName: '',
    },
  ],
};
