import BaseProvider from '@/utils/checkout/payment-providers/base-provider.js';
import {PaymentProvider} from '@/constants/payment-provider.js';

class CreditCardProvider extends BaseProvider {
  constructor(options) {
    super(options);
    const {billingAddress, cardType, cardNumber, cardName, cvn, expiryMonth, expiryYear} = options;

    if (
      [billingAddress, cardType, cardNumber, cardName, cvn, expiryMonth, expiryYear].some((val) => val === undefined)
    ) {
      throw new Error('All card fields are required');
    }

    this.billingAddress = billingAddress;
    this.providerCode = PaymentProvider.CYBERSOURCE;

    this.cardType = cardType;
    this.cardNumber = cardNumber.replace(/\s/g, '');
    this.cardName = cardName;
    this.cvn = cvn;

    this.expiryMonth = expiryMonth;
    this.expiryYear = expiryYear;
    this.expiryDate = `${this.expiryMonth}-${this.expiryYear}`;
  }

  #getCardData() {
    return {
      billingAddress: this.billingAddress,
      paymentOption: this.providerCode,
      paymentProviderCode: this.providerCode,
      saveInAccount: this.shouldSaveBillingInfo,
      newBillingAddress: !this.isBillingAddressSameAsDelivery,
      cardTypeCode: this.cardType,
      expiryDate: this.expiryDate,
      expiryMonth: this.expiryMonth,
      expiryYear: this.expiryYear,
    };
  }

  async initializePayment() {
    const data = this.#getCardData();
    return this.submitPaymentInitForm(data);
  }
}

export default CreditCardProvider;
