import AllocatedBackorderAPI from '@/api/allocated-backorder';
import {AllocatedBackorderHideFilter, DefaultFiltersPayload, EmptyFilters} from '@/constants/allocated-backorder';
import {ProductType} from '@/constants/product';
import {groupByDeep} from '@/utils/entries-helpers';
import {isEqual} from 'lodash/lang';
import {transform} from 'lodash/object';
import {AllocatedBackorderSortCode, AllocatedBackorderSortOption} from '@/constants/allocated-backorder';

const allocatedBackorderModule = {
  namespaced: true,
  state: {
    searchHeader: null,
    entries: null,
    warehouses: null,
    subTotals: null,
    sort: null,
    pagination: null,
    filters: null,
    ETA: {},
    ETAMessage: {},
    savedState: false,
  },
  getters: {
    productTypes(state) {
      if (state.searchHeader) {
        return Array.from(state.searchHeader.keys());
      }

      return [];
    },
    firstProductType(_, getters) {
      const [firstProductType] = getters.productTypes ?? [];

      return firstProductType;
    },
    productTypeSortCodes(state) {
      return (productType) => state.searchHeader?.[productType]?.sortCodes;
    },
    searchEntriesPayload(state, getters) {
      return (productType) => {
        return {
          productTypes: getters.searchEntriesProductTypesPayload(productType),
          pagination: {
            pageSize: state.pagination?.pageSize ?? 50,
            currentPage: state.pagination?.currentPage ?? 0,
            needsTotal: true,
          },
          filters: getters.searchEntriesFiltersPayload(productType),
          sort: state.sort[productType],
        };
      };
    },
    searchEntriesProductTypesPayload(state) {
      return (productType) => {
        switch (productType ?? state.filters[productType]) {
          case ProductType.Bike:
            // If filter is selected show only P1_BIKES
            return state.filters[productType].showProjectOne
              ? [ProductType.ProjectOne]
              : [ProductType.Bike, ProductType.ProjectOne];
          case ProductType.Aftermarket:
            return [ProductType.Aftermarket];
          default:
            return [ProductType.Bike];
        }
      };
    },
    searchEntriesFiltersPayload(state) {
      return (productType) => {
        const {
          hideUnallocated,
          hideLinesWithoutComments,
          hideWarranties,
          hidePreseason,
          showSoldOut,
          description,
          orderNumber,
          customerPo,
          sku,
          shipToNumber,
        } = state.filters[productType];

        const hide = transform(
          {
            [AllocatedBackorderHideFilter.Unallocated]: hideUnallocated,
            [AllocatedBackorderHideFilter.LinesWithoutComments]: hideLinesWithoutComments,
            [AllocatedBackorderHideFilter.Warranties]: hideWarranties,
            [AllocatedBackorderHideFilter.Preseason]: hidePreseason,
            [AllocatedBackorderHideFilter.NotSoldOut]: showSoldOut,
          },
          function iteratee(acc, value, key) {
            if (value) {
              acc.push(key);
            }
          },
          []
        );

        return {
          hide,
          searchTerms: {
            description,
            orderNumber,
            customerPo,
            sku,
            shipToNumber,
          },
        };
      };
    },
    isFiltersEmpty(state) {
      return (productType) => isEqual(state.filters[productType], DefaultFiltersPayload);
    },
    isFiltersApplied(state) {
      return (productType) => !isEqual(state.filters[productType], DefaultFiltersPayload);
    },
    isEveryEntryAllowedToShip(state) {
      return state.entries?.every((entry) => entry.isAllowedToShip) ?? false;
    },
  },
  mutations: {
    setSearchHeader(state, searchHeader) {
      state.searchHeader = groupByDeep(searchHeader, (e) => e.productType);
    },
    setEntries(state, entries) {
      state.entries = entries;
    },
    setProductTypeETA(state, ETA) {
      state.ETA = {...state.ETA, ...ETA};
    },
    setProductTypeETAMessage(state, ETAMessage) {
      state.ETAMessage = {...state.ETAMessage, ...ETAMessage};
    },
    setWarehouses(state, warehouses) {
      state.warehouses = warehouses?.sort((w1, w2) => w1.weight - w2.weight);
    },
    setSubTotals(state, subTotals) {
      state.subTotals = subTotals;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    changeFilters(state, {productType, filters = {}}) {
      state.filters = {
        ...state.filters,
        [productType]: {
          ...state.filters[productType],
          ...filters,
        },
      };
    },
    resetFilters(state, productTypes) {
      state.filters = Object.fromEntries(
        productTypes.map((productType) => [productType, Object.assign({}, EmptyFilters)])
      );
    },
    setSort(state, sort) {
      state.sort = sort;
    },
    changeSort(state, {productType, sort = {}}) {
      state.sort = {
        ...state.sort,
        [productType]: {
          ...state.sort[productType],
          ...sort,
        },
      };
    },

    setAttributesByKey(state, {id, value, key}) {
      const entryIndex = state.entries?.findIndex((entry) => entry.id === id);

      if (entryIndex < 0) return;

      state.entries[entryIndex][key] = value;
    },

    setSavedState(state, payload) {
      state.savedState = payload;
    },
  },
  actions: {
    async getSearchHeader({commit, getters}) {
      const searchHeader = await AllocatedBackorderAPI.fetchSearchHeader();

      commit('setSearchHeader', searchHeader);
      commit(
        'setFilters',
        Object.fromEntries(getters.productTypes.map((productType) => [productType, Object.assign({}, EmptyFilters)]))
      );
      commit(
        'setSort',
        Object.fromEntries(
          getters.productTypes.map((productType) => [
            productType,
            Object.assign({}, AllocatedBackorderSortOption[AllocatedBackorderSortCode.QuantityAllocatedOrShipped]),
          ])
        )
      );
    },
    async getProductTypeETA({commit}, productType) {
      const data = await AllocatedBackorderAPI.fetchProductTypeETA(productType);

      commit('setProductTypeETA', {[productType]: data?.data ?? []});
      commit('setProductTypeETAMessage', {
        [productType]: data?.meta?.feedback?.message?.basePropertyValue,
      });

      return data;
    },
    async filterEntries({commit, dispatch, getters}, {productType, filters}) {
      commit('changeFilters', {productType, filters});

      if (getters.isFiltersApplied(productType)) {
        await dispatch('searchEntries', {productType});
      }
    },
    async changeSortEntries({commit}, {productType, sort}) {
      commit('changeSort', {productType, sort});
    },
    async searchEntries({commit, getters}, {productType}) {
      const {entries, warehouses, subtotals, pagination} = await AllocatedBackorderAPI.searchEntries(
        getters.searchEntriesPayload(productType)
      );

      commit('setEntries', entries);
      commit('setWarehouses', warehouses);
      commit('setSubTotals', subtotals);
      commit('setPagination', pagination);
    },
  },
};

export default allocatedBackorderModule;
