<script>
import {mapActions, mapMutations} from 'vuex';

export default {
  name: 'BackendProps',
  props: {
    backendData: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.setBackendProps(this.backendData);
    this.getSupportedPaymentMethods();
  },
  methods: {
    ...mapMutations('backend', ['setBackendProps']),
    ...mapActions('backend', ['getSupportedPaymentMethods']),
  },

  render: () => null,
};
</script>
