const UnitTypes = Object.freeze({
  IMPERIAL: {
    feetAbbreviation: 'ft',
    milesAbbreviation: 'mi',
    milesString: 'miles',
    milesPerHour: 'mph',
    weightString: 'lbs',
  },
  METRIC: {
    metersAbbreviation: 'm',
    kilometersAbbreviation: 'km',
    kilometersString: 'kilometers',
    kilometersPerHour: 'kph',
    weightString: 'kg',
  },
});

const RideTemperatureUnits = Object.freeze({
  CELSIUS: 'celsius',
  FAHRENHEIT: 'fahrenheit',
});

export {UnitTypes, RideTemperatureUnits};
