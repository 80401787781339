import {isIterable} from '@/utils/util';

function groupBy(data, keyGetter) {
  if (data instanceof Map) {
    return groupMapBy(data, keyGetter);
  } else if (isIterable(data)) {
    return groupListBy(data, keyGetter);
  }

  function groupMapBy(map, keyGetter) {
    const result = new Map();

    for (const [key, entry] of map) {
      const collection = groupBy(entry, keyGetter);

      result.set(key, collection);
    }

    return result;
  }

  function groupListBy(list, keyGetter) {
    const result = new Map();

    for (const item of list) {
      const keys = keyGetter(item);

      if (typeof keys == 'string') {
        fillCollection(result, item, keys);
      } else if (Array.isArray(keys)) {
        for (const key of keys) {
          fillCollection(result, item, key);
        }
      }
    }

    return result;
  }

  function fillCollection(result, item, key) {
    const collection = result.get(key) ?? [];

    collection.push(item);

    result.set(key, collection);
  }
}

function groupByDeep(data, ...keyGetters) {
  let result = data;

  for (const keyGetter of keyGetters) {
    result = groupBy(result, keyGetter);
  }

  return result;
}

function convertMapToArrayDeep(map = new Map()) {
  if (!(map instanceof Map) || map.size === 0) return [];

  return Array.from(map.entries(), ([key, value]) => {
    return value instanceof Map ? [key, convertMapToArrayDeep(value)] : [key, value];
  });
}

function sortList(entries = [], ...order) {
  return order.reduce((result, [orderType, keyGetter]) => sortListByOrder(result, orderType, keyGetter), [...entries]);

  function sortListByOrder(list = [], order = [], keyGetter = (v) => v) {
    return list.sort((firstItem, secondItem) => {
      const firstKey = keyGetter(firstItem);
      const secondKey = keyGetter(secondItem);
      if (order.includes(firstKey) && order.includes(secondKey)) {
        return order.indexOf(firstKey) - order.indexOf(secondKey);
      }

      if (order.includes(firstKey)) {
        return -1;
      }

      if (order.includes(secondKey)) {
        return 1;
      }

      return firstKey.localeCompare(secondKey);
    });
  }
}

export {groupByDeep, convertMapToArrayDeep, sortList};
