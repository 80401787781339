import storefrontInstance from '@/api/instances/storefront.js';
import {fetchAndCache} from '@/utils/cached-promise.js';
import occInstance from '@/api/instances/occ.js';

const ArchivedStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  HYBRID: 'HYBRID',
});

const ProductApi = {
  /**
   * Get nearest retailers with availability for the given variant.
   * @param {object} options
   * @return {*}
   */
  async getRetailerAvailability(options) {
    const defaults = {
      productCode: '',
      isInStockOnline: true,
      retailersMax: 3,
      inStockOnly: false,
      locationText: '',
    };

    options = Object.assign(defaults, options);

    let url = `/rpa/${options.productCode}?retailers=${options.retailersMax}&isInStockOnline=${Boolean(
      options.isInStockOnline
    )}&inStockOnly=${options.inStockOnly}`;

    if (options.locationText) {
      url = `${url}&locationText=${options.locationText}`;
    }

    const response = await storefrontInstance.get(url, {
      headers: {'X-Requested-With': 'XMLHttpRequest'},
    });

    return response.data ?? {};
  },

  /**
   * Load product data by product's code.
   * @param {string} code
   * @return {Promise}
   */
  async loadProduct(code) {
    const queryProductId = window.location.href.match(/\/[ap]\/([a-zA-Z0-9-]+)\//i)?.[1] ?? '';
    const productResponse = await storefrontInstance.get(`/v1/api/product/${queryProductId}/status/`);
    const productResponseData = productResponse?.data?.data;
    const isPdpArchivedStatus = productResponseData?.archivedStatus;
    let endpointUrl = '';

    if (isPdpArchivedStatus === ArchivedStatus.HYBRID) {
      const isArchivedPdp = window.location.href.includes('/a/');
      endpointUrl = `/v1/api/product/${code}/full/${isArchivedPdp ? '?type=archive' : ''}`;
    } else {
      endpointUrl = `/v1/api/product/${code}/full/${isPdpArchivedStatus == 'ARCHIVED' ? '?type=archive' : ''}`;
    }

    const productData = await new Promise((resolve) => {
      fetchAndCache(`product-${code}`, () => {
        return storefrontInstance.get(endpointUrl);
      }).then((response) => {
        const data = response?.data?.data ?? {};

        resolve(data);
      });
    });
    productData.status = productResponseData;

    return productData;
  },

  /**
   * Check product IDs.
   * @param {array} ids
   */
  productIdsToCommaSeparated(ids) {
    if (!ids || !ids.length) {
      throw new Error('Product IDs cannot be empty!');
    }

    return ids.join(',');
  },

  /**
   * Batch load a list of products by given list of IDs.
   * @param {array} ids
   * @return {Promise<array>}
   */
  batchLoadProducts(ids) {
    const idsList = this.productIdsToCommaSeparated(ids);
    const endpointUrl = `/products/grid?codes=${idsList}`;

    return new Promise((resolve) => {
      fetchAndCache(`products-${idsList}`, () => {
        return storefrontInstance.get(endpointUrl);
      }).then((response) => {
        resolve(response?.data?.data ?? []);
      });
    });
  },

  /**
   * Load a full data of products grid by given list of IDs.
   * @param {array} ids
   * @return {Promise<object>}
   */
  loadSkuGrid(ids) {
    const idsList = this.productIdsToCommaSeparated(ids);
    const endpointUrl = `/order-copy-paste/grid?codes=${idsList}`;

    return new Promise((resolve) => {
      fetchAndCache(`products-${idsList}`, () => {
        return storefrontInstance.get(endpointUrl);
      }).then((response) => {
        resolve(response.data ?? {});
      });
    });
  },

  /**
   * Batch load allocated / back ordered counts for given list of product IDs.
   * @param {array} ids
   * @return {Promise<array>}
   */
  batchLoadAllocatedData(ids) {
    const idsList = this.productIdsToCommaSeparated(ids);
    const endpointUrl = `/products/allocated-lines?codes=${idsList}`;

    return new Promise((resolve) => {
      fetchAndCache(`allocated-lines-${idsList}`, () => {
        return storefrontInstance.get(endpointUrl);
      }).then((response) => {
        resolve(response?.data?.data ?? []);
      });
    });
  },

  /**
+   * Batch load a list of variants by given list of sku codes.
+   * @param {array} ids
+   * @return {Promise<array>}
+   */
  fetchVariantsPrices(ids) {
    const idsList = this.productIdsToCommaSeparated(ids);
    const endpointUrl = `/v1/api/batch/price/all/`;

    return new Promise((resolve) => {
      fetchAndCache(`products-prices-${idsList}`, () => {
        return storefrontInstance.post(endpointUrl, {variantCodesList: ids});
      }).then((response) => {
        resolve(response?.data?.data ?? []);
      });
    });
  },
  /* Required for B2C Tech Select */
  filterAttributesWithVariants(data) {
    let attributes = [];
    data.variantAttributesDirectory.forEach((attribute) => {
      let options = [];
      attribute.options.forEach((option) => {
        const hasMatch = data.variants.find((variant) => variant.variantAttributes[attribute.code] === option.value);
        if (hasMatch) {
          options.push(option);
        }
      });
      attributes.push({
        ...attribute,
        options,
      });
    });
    return attributes;
  },
  async loadFilteredProduct(code) {
    const data = await this.loadProduct(code);
    const filteredVariantAttributesDirectory = this.filterAttributesWithVariants(data);
    return {
      ...data,
      variantAttributesDirectory: filteredVariantAttributesDirectory,
    };
  },
  async getProductFulfillmentOptions(code, options) {
    const response = await occInstance.get(`/products/${code}/fulfillments`, {
      params: options,
    });
    return response.data;
  },
};

export {ProductApi};
