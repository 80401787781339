const KEY_USAGES = Object.freeze({
  ENCRYPT: 'encrypt',
  DECRYPT: 'decrypt',
});

function base64Decode(str) {
  return new Uint8Array(
    window
      .atob(str)
      .split('')
      .map((c) => c.charCodeAt(0))
  );
}

class CryptoUtils {
  /**
   *
   * @param {String} seed string used in decryption key
   */
  constructor(seed) {
    this.seed = seed;
    this.algoName = 'AES-GCM';
    this.encoder = new TextEncoder();
    this.decoder = new TextDecoder();
  }

  /**
   * Creates a fixed size array of random bytes
   * @returns initialization vector to be used in encryption
   */
  getIv() {
    return window.crypto.getRandomValues(new Uint8Array(12));
  }

  /**
   * Creates an object to be used as the algorithm configuration in SubtleCrypto encryption/decryption
   * @param iv initialization vector used in encryption/decryption
   * @returns SubtleCrypto algorithm config object
   */
  getAlgo(iv) {
    if (iv) iv = Uint8Array.from(iv);
    return {name: this.algoName, iv: iv || this.getIv(), tagLength: 128};
  }

  /**
   * Given a string - generates a seed seed, generates a fixed size seed to be passed into
   * SubtleCrypto encryption decryption.
   * @param {String} seed - string value
   * @param {Object} algo - SubtleCrypto configuration object
   * @param {Array} usages - array of enumerated usage permissions.
   * @returns
   */
  async getKey(seed, algo, usages) {
    if (!seed) throw new Error('Seed not provided - key cannot be generated');
    const seedStr = this.encoder.encode(seed.repeat(Math.ceil(16 / seed.length)).substring(0, 16));
    return crypto.subtle.importKey('raw', seedStr, algo, false, usages);
  }

  /**
   * Encrypts text following AES-128-GCM
   * @param {String} rawText - the text to encrypt
   * @param {Object} iv - an optional byte array that can be used to ensure a specific encryption.
   * @returns
   */
  async encrypt(rawText, iv) {
    const algo = this.getAlgo(iv);
    const ivStr = Array.from(algo.iv)
      .map((byte) => String.fromCharCode(byte))
      .join('');
    const key = await this.getKey(this.seed, algo, [KEY_USAGES.ENCRYPT]);

    const encryptedTextBuffer = await window.crypto.subtle.encrypt(algo, key, this.encoder.encode(rawText));
    const encryptedText = Array.from(new Uint8Array(encryptedTextBuffer))
      .map((byte) => String.fromCharCode(byte))
      .join('');

    return window.btoa(ivStr + encryptedText);
  }

  /**
   * Decrypts text and returns the text along with the initialization vector used to
   * decrypt the text
   * @param {*} cipher the encrypted text to be decrypted
   * @returns an object containing the decrypted text and the byte array iv
   * that can be used to generate an identical encryption.
   */
  async decrypt(cipher) {
    const cipherStr = base64Decode(cipher);

    const algo = this.getAlgo(cipherStr.slice(0, 12));
    const key = await this.getKey(this.seed, algo, [KEY_USAGES.DECRYPT]);
    const encryptedTextBuffer = cipherStr.slice(12);

    try {
      const decryptedTextBuffer = await crypto.subtle.decrypt(algo, key, encryptedTextBuffer);
      return {
        decryptedText: this.decoder.decode(decryptedTextBuffer),
        iv: cipherStr.slice(0, 12),
      };
    } catch (e) {
      throw new Error('Failed to complete decryption');
    }
  }
}

export default CryptoUtils;
