import axios from 'axios';

const cancelers = {};
const abortControllers = {};

export function cancelRequest(cancelTokenId) {
  if (cancelers[cancelTokenId]) {
    cancelers[cancelTokenId]();
  }

  if (abortControllers[cancelTokenId]) {
    abortControllers[cancelTokenId].abort();
  }
}

export function createCancellation(cancelTokenId) {
  const controller = new AbortController();
  abortControllers[cancelTokenId] = controller;

  // deprecated in latest axios version. https://axios-http.com/docs/cancellation
  const cancelToken = new axios.CancelToken((c) => (cancelers[cancelTokenId] = c));

  return {
    cancelToken,
    signal: controller.signal,
  };
}

export function cancelPreviousRequest(cancelTokenId) {
  cancelRequest(cancelTokenId);
  return createCancellation(cancelTokenId);
}
