/* 
  Adds a border and label to a component with the component's name.
  
  Usage: v-debug-highlight={ 
    color: '...||red', // the color to apply
    align: 'left(default)||center||right' // where the label should be positioned
    applyRelativePosition: true||false(default), // whether the container needs position relative applied or not
  }

*/

const directive = {
  bind: (el, options, vnode) => {
    // Vars
    const color = (options.value && options.value.color) || 'red';

    // Position the component
    if (options.value && options.value.applyRelativePosition) {
      el.style.position = 'relative';
    }

    // Create a child element with a border
    const border = document.createElement('div');
    border.style = `
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100000000;
      box-shadow: inset 0 0 0 2px ${color};
      pointer-events: none;
    `;
    el.appendChild(border);

    // Create a child element with a label
    const label = document.createElement('div');
    label.textContent = `${vnode.context.$options.name}.vue`; // ComponentName.vue
    let align = 'left: 0;';

    if (options.value && options.value.align) {
      if (options.value.align === 'center') {
        align = 'left: 50%; transform: translateX(-50%);';
      }
      if (options.value.align === 'right') {
        align = 'right: 0;';
      }
    }

    label.style = `
      position: absolute;
      top: 0;
      ${align}
      z-index: 100000000;
      padding: 2px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      background: ${color};
      pointer-events: none;
    `;
    el.appendChild(label);
  },
};

export default directive;
