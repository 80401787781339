import {ShellBase} from '@/entryPoints/lib/shell-base';
import B2cMiniCart from '@/components/mini-cart/b2c/B2cMiniCart.vue';
//Vue Components
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';

class Shell extends ShellBase {
  constructor(pageComponents = {}, vueRouter = {}) {
    super({...pageComponents, PdlCollapse, PdlCollapseItem, B2cMiniCart}, vueRouter);
  }

  loadPlugins() {
    // Load common plugins from Shell
    super.loadPlugins();
  }
}

export {Shell};
