import storefrontInstance from '@/api/instances/storefront';
import {axiosToast} from '@/utils/axios';

storefrontInstance.interceptors.response.use(
  (response) => {
    const {data, config} = response;
    const useToast = config.toast ?? true;
    if (useToast) {
      axiosToast(data);
    }

    return response;
  },

  (error) => {
    const {data} = error.response;

    axiosToast(data);

    return Promise.reject(error);
  }
);
