import storefrontInstance from '@/api/instances/storefront';
import occInstance from '@/api/instances/occ';
import {CloudinaryApi} from '@/api/cloudinary.js';
import {ChallengeDataExportStatus} from '@/constants/rcp-challenges.js';
import {Url} from '@/utils/url.js';

const rootUrl = '/rcp/challenges/';

const RcpChallengesApi = {
  async saveNewChallenge(challenge) {
    const {data: response} = await occInstance.post(rootUrl, challenge);
    return response;
  },
  async fetchChallenge(id) {
    const {data: response} = await occInstance.get(`${rootUrl}${id}`);
    return response;
  },
  async fetchChallengeList(params) {
    const {data: response} = await occInstance.get(
      `${rootUrl}?continuationToken=${params.token}&b2bUnit=${params.b2bUnit}`
    );
    return response;
  },
  async updateExistingChallenge(id, challenge) {
    const {data: response} = await occInstance.put(`${rootUrl}${id}`, challenge);
    return response;
  },
  async deleteChallenge(challenge) {
    const {data: response} = await occInstance.delete(`${rootUrl}${challenge.id}`);
    const bannerAsset = challenge.basicInformation?.customHeaderAssetId?.split('.')?.[0];
    const rewardAsset = challenge.reward?.awardAssetId?.split('.')?.[0];
    if (bannerAsset) CloudinaryApi.deleteAssetFromCloudinary(bannerAsset);
    if (rewardAsset) CloudinaryApi.deleteAssetFromCloudinary(rewardAsset);
    return response;
  },
  async fetchUserMetrics(challengeId, userEmail) {
    const {data: response} = await occInstance.get(`/users/${userEmail}/challenges/${challengeId}`);
    return response;
  },
  async joinChallenge(challengeId, userEmail) {
    const {data: response} = await occInstance.post(`/users/${userEmail}/challenges/${challengeId}/join`, {
      challengeId,
    });
    return response;
  },
  async leaveChallenge(challengeId, userEmail) {
    const {data: response} = await occInstance.post(`/users/${userEmail}/challenges/${challengeId}/leave`, {
      challengeId,
    });
    return response;
  },
  async fetchChallengeDataSummary(challengeId) {
    const {data: response} = await occInstance.get(`${rootUrl}data/${challengeId}`);
    return response;
  },
  async fetchChallengeParticipantData(challengeId, token = '') {
    const {data: response} = await occInstance.get(`${rootUrl}${challengeId}/getallusers?continuationToken=${token}`);
    return response;
  },
  async startChallengeDataDownload(challengeId) {
    const response = await storefrontInstance.get(`/challenges/participants/export/start/${challengeId}/`);
    return response.data.data;
  },
  async isFileReady(exportId) {
    const response = await storefrontInstance.get(`/challenges/participants/export/status/${exportId}/`);
    const status = response?.data?.data;
    return status === ChallengeDataExportStatus.Finished;
  },
  getFileDownloadUrl(exportId) {
    return Url.withContextPath(`/challenges/participants/export/generate/${exportId}/`);
  },
};

export default RcpChallengesApi;
