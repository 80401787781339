'use strict';

export default function (element) {
  // requirements are to provide your button and reveal target
  var button = element.find('[data-toggler-click]').first();
  var target = element.find('[data-toggler-target]').first();
  var open = element.data('open');

  function update(show) {
    open = show;

    if (open) {
      button.addClass('open');
      target.addClass('open');
    } else {
      button.removeClass('open');
      target.removeClass('open');
    }

    if (target.hasClass('open')) {
      element.trigger('toggle-open', target);
    }
  }

  button.on('click', function (event) {
    event.preventDefault();
    update(!open);
  });

  if (element.data('collapse-small')) {
    // Add media query listener and initial check if we collapse at small
    window.matchMedia('(min-width: 600px)').addListener(function (data) {
      update(data.matches);
    });
    // Initial check
    update(window.matchMedia('(min-width: 600px)').matches);
  } else if (element.data('collapse-medium')) {
    // Add media query listener and initial check if we collapse at medium
    window.matchMedia('(min-width: 1024px)').addListener(function (data) {
      update(data.matches);
    });
    // Initial check
    update(window.matchMedia('(min-width: 1024px)').matches);
  } else {
    // default to open otherwise
    update(open);
  }
}
