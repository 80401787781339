import Vue from 'vue';

export const SET_FEEDBACK_MSG = 'SET_FEEDBACK_MSG';

const storageModule = {
  namespaced: true,
  state: {
    lastPlp: null,
    bikeTechSKU: null,
    feedbackMsg: '',
    purchaseMode: localStorage.purchaseMode ? localStorage.purchaseMode : false,
    usersTouchedAllocatedItems: [],
  },
  getters: {
    purchaseMode(state) {
      return state.purchaseMode;
    },
    hasUserTouchedAllocatedItems(state) {
      return state.usersTouchedAllocatedItems.some(
        ({email, b2bUnit}) => email === ACC?.customVariables?.currentUserEmail && b2bUnit === ACC.currentB2BUnit
      );
    },
    /**
     * Gets the window location object. Combines the pathname and searh
     * to handle plp path and search queries
     * @returns concatenated pathname and search
     */
    getPlpLocationUrl() {
      const loc = window.location;
      const locConcat = `${loc.pathname}${loc.search}`;
      return locConcat;
    },
  },
  mutations: {
    setBikeTechSKU(state, sku) {
      Vue.set(state, 'bikeTechSKU', sku);
    },
    setLastPlp(state, payload) {
      state.lastPlp = payload;
    },
    setPurchaseMode(state, value) {
      Vue.set(state, 'purchaseMode', value);
    },
    [SET_FEEDBACK_MSG](state, message) {
      state.feedbackMsg = message;
    },
    addUserTouchedAllocatedItems(state) {
      state.usersTouchedAllocatedItems.push({
        email: ACC?.customVariables?.currentUserEmail,
        b2bUnit: ACC.currentB2BUnit,
      });
    },
    removeUserTouchedAllocatedItems(state) {
      state.usersTouchedAllocatedItems = state.usersTouchedAllocatedItems.filter(
        ({email, b2bUnit}) => !(email === ACC?.customVariables?.currentUserEmail && b2bUnit === ACC.currentB2BUnit)
      );
    },
  },
  actions: {
    setFeedbackMsg({commit}, message) {
      commit(SET_FEEDBACK_MSG, message);
    },
    togglePurchaseMode({commit, state}) {
      let value = !state.purchaseMode;
      commit('setPurchaseMode', value);
    },
    updateLastPlp({commit, getters}) {
      commit('setLastPlp', getters.getPlpLocationUrl);
    },
  },
};

export default storageModule;
