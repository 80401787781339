<script>
import headTag from '@/utils/head-tag';
export default {
  name: 'SemRush',
  mounted() {
    this.loadScript();
  },
  methods: {
    loadScript() {
      const semRushId = '61ccc905-3dc5-4678-88f7-dbf125681081';
      const semRushSrc = 'https://seoab.io';
      headTag.appendAsyncScript(semRushId, semRushSrc);
    },
  },

  render: () => null,
};
</script>
