<template>
  <!-- Checkbox & Radio - uses <fieldset class="form-fieldset"> & <legend class="form-fieldset__legend"> -->
  <fieldset
    v-if="type === 'checkbox' || type === 'radio'"
    class="form-fieldset"
    :class="{
      'has-error': !!error,
      'is-required': required,
      'has-warning': warning,
    }"
  >
    <slot name="label">
      <legend
        v-if="label"
        :for="name"
        class="form-fieldset__legend"
        :class="{'show-for-sr': !showLabel}"
        :qaid="qaid ? qaid + '__label' : null"
      >
        <span v-html="label"></span>
        <span v-if="required" class="text-red ml-25">
          *
          <span class="show-for-sr">(required)</span>
        </span>
      </legend>
    </slot>

    <div v-if="type === 'checkbox'">
      <!-- Checkbox -->
      <slot name="input">
        <label
          v-for="(multiItem, index) in multiItems"
          :key="index"
          class="checkbox"
          :class="labelClasses"
          :qaid="qaid"
        >
          <input
            :id="multiItem.value"
            ref="input"
            :aria-describedby="helpText ? helpId : null"
            :type="type"
            :name="multiItem.name || name"
            :value="multiItem.value"
            :disabled="disabled"
            :checked="multiItem.checked"
            :qaid="`formGroup${multiItem.value}`"
            @change="emitChange"
          />
          <span class="control-indicator"></span>
          <component :is="multiItem.component" v-if="multiItem.component" />
          <pdl-link
            v-else-if="multiItem.linkText"
            :id="`form-group-${multiItem.label}-label`"
            :qaid="`form-group-${multiItem.label}-label`"
            :href="multiItem.linkText"
            target="_blank"
            inline
            >{{ multiItem.displayName }}
          </pdl-link>
          <template v-else>
            <span
              :id="`form-group-${multiItem.label}-label`"
              :qaid="`form-group-${multiItem.label}-label`"
              v-html="multiItem.displayName"
            >
            </span>
            <span v-if="required" class="text-red ml-25">
              *
              <span class="show-for-sr">(required)</span>
            </span>
          </template>
        </label>
      </slot>
    </div>

    <div v-else>
      <!-- Radio -->
      <slot name="input">
        <label v-for="(multiItem, index) in multiItems" :key="index" class="radio" :class="labelClasses">
          <input
            :id="multiItem.value"
            ref="input"
            :aria-describedby="helpText ? helpId : null"
            :type="type"
            :name="name"
            :value="multiItem.value"
            :disabled="disabled"
            :checked="multiItem.value === value"
            :qaid="`formGroup${multiItem.value}`"
            @change="emitChange"
          />
          <span class="control-indicator"></span>
          <component :is="multiItem.component" v-if="multiItem.component" />
          <pdl-link
            v-else-if="multiItem.linkText"
            :qaid="`formGroup${multiItem.value}_label`"
            :href="multiItem.linkText"
            target="_blank"
            inline
            >{{ multiItem.displayName }}
          </pdl-link>
          <span v-else :qaid="`formGroup${multiItem.value}_label`" v-html="multiItem.displayName"></span>
        </label>
      </slot>
    </div>

    <slot v-if="helpText" name="form-help">
      <span :id="helpId" :qaid="`${qaid}-help-text`" class="form-help" v-html="helpText"></span>
    </slot>

    <slot v-if="showErrorMessages" name="form-errors">
      <span v-show="error" class="form-feedback is-invalid" v-html="error"></span>
    </slot>
  </fieldset>

  <!-- Input, Textarea & Select - uses <div class="form-group"> & <label class="form-label"> -->
  <div
    v-else
    class="form-group"
    :qaid="qaid"
    :class="{
      'has-error': canDisplayError,
      'is-required': required,
      'has-warning': warning,
    }"
  >
    <slot name="label">
      <label
        v-if="label"
        class="form-label"
        :for="name"
        :class="{'show-for-sr': !showLabel}"
        :qaid="qaid ? qaid + '__label' : null"
      >
        <span v-html="label"></span>
        <span v-if="required" class="text-red ml-25">
          *
          <span class="show-for-sr">(required)</span>
        </span>
      </label>
    </slot>

    <slot name="input">
      <!-- Select -->
      <div v-if="type === 'select'" class="select">
        <select
          v-if="isMultipleSelect"
          :id="name"
          v-model="inputValue"
          :qaid="qaid"
          :name="name"
          class="form-control"
          :disabled="disabled"
          multiple
          :size="size"
          @blur="emitBlur"
          @input="emitChange"
        >
          <option
            v-for="(multiItem, index) in multiItems"
            :id="`${name}-${index}-${multiItem.value}`"
            ref="input"
            :key="`${name}-${index}-${multiItem.value}`"
            :type="type"
            :value="multiItem.value"
            :selected="value === multiItem.value"
            :aria-describedby="helpText ? helpId : null"
            :disabled="multiItem.disabled"
            v-html="multiItem.displayName"
          ></option>
        </select>
        <select
          v-else
          :id="name"
          v-model="inputValue"
          :qaid="qaid"
          :name="name"
          class="form-control"
          :disabled="disabled"
          @input="emitChange"
        >
          <option
            v-for="(multiItem, index) in multiItems"
            :id="`${name}-${index}-${multiItem.value}`"
            ref="input"
            :key="`${name}-${index}-${multiItem.value}`"
            :type="type"
            :value="multiItem.value"
            :selected="value === multiItem.value"
            :aria-describedby="helpText ? helpId : null"
            :disabled="multiItem.disabled"
            v-html="multiItem.displayName"
          ></option>
        </select>
      </div>

      <!-- Textarea -->
      <textarea
        v-else-if="type === 'textArea' || type === 'textarea'"
        :id="name"
        ref="input"
        class="form-control"
        v-bind="$attrs"
        :aria-describedby="helpText ? helpId : null"
        :step="step"
        :type="type"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :rows="rows"
        :maxlength="maxlength"
        :disabled="disabled"
        @blur="emitBlur"
        @focus="emitFocus"
        @change="emitChange"
        @input="updateValue"
      ></textarea>

      <!-- Input -->
      <input
        v-else
        :id="name"
        ref="input"
        class="form-control"
        v-bind="$attrs"
        :aria-describedby="helpText ? helpId : null"
        :step="step"
        :type="type"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :disabled="disabled"
        @keypress="checkInput"
        @keyup="emitKeyup"
        @keydown="emitKeydown"
        @blur="emitBlur"
        @focus="emitFocus"
        @change="emitChange"
        @input="updateValue"
      />
      <slot name="controls"></slot>
    </slot>

    <slot v-if="helpText && helpText !== error" name="form-help">
      <span :id="helpId" class="form-help" v-html="helpText"></span>
    </slot>

    <slot v-if="canDisplayError" name="form-errors">
      <span v-show="error" class="form-feedback is-invalid" v-html="error"></span>
    </slot>
  </div>
</template>

<script>
import first from 'lodash/first';
import {PdlLink} from '@pedal/pdl-link';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  name: 'FormGroup',
  components: {PdlLink},
  mixins: [TrekValidationMixin],

  inheritAttrs: false,

  props: {
    allowedCharactersRegex: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    forceDisplayError: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelClasses: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: 250,
    },
    multiItems: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    qaid: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    showErrorMessages: Boolean,
    showLabel: {
      type: Boolean,
      default: true,
    },
    step: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
      validator: (val) => {
        return (
          [
            'url',
            'text',
            'password',
            'email',
            'search',
            'number',
            'tel',
            'textArea',
            'textarea',
            'select',
            'checkbox',
            'radio',
          ].indexOf(val) !== -1
        );
      },
    },
    value: {
      type: null,
      default: null,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    isMultipleSelect: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputValue: this.isMultipleSelect ? [] : '',
    };
  },

  computed: {
    helpId() {
      return `${this.name}-help`;
    },
    handle() {
      return `${this.name}`;
    },
    canDisplayError() {
      return this.error && this.showErrorMessages && (this.v$?.$errors?.length > 0 || this.forceDisplayError);
    },
  },

  watch: {
    inputValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      if (value !== this.inputValue) {
        this.inputValue = value;
      }
    },
    multiItems(items) {
      if (!items || !items.length || !this.inputValue) {
        return;
      }

      const hasItem = items.findIndex((item) => item.value === this.inputValue) > -1;
      if (!hasItem) {
        const itemsWithValues = items.filter((item) => item.value && item.value.length);
        if (itemsWithValues.length) {
          this.inputValue = first(itemsWithValues).value;
        }
      }
    },
  },

  mounted() {
    if (this.$refs.input && this.$refs.input.value) {
      this.$refs.input.value = this.value;
    }

    if (this.value) {
      this.inputValue = this.value;
    }
  },

  methods: {
    checkInput(e) {
      if (this.allowedCharactersRegex) {
        const regex = RegExp(this.allowedCharactersRegex);
        if (regex.test(String.fromCharCode(e.charCode))) {
          return true;
        } else {
          e.preventDefault();
        }
      }
    },

    emitKeyup(e) {
      this.$emit('keyup', e);
    },

    emitKeydown(e) {
      this.$emit('keydown', e);
    },

    emitBlur(e) {
      this.$emit('blur', e);
    },

    emitFocus(e) {
      this.$emit('focus', e);
    },

    emitChange(e) {
      this.$emit('change', e);
    },

    updateValue(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>
