import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';
import {staticNotifications} from '../static-data/notifications-static';

const notiUid = document.getElementById('notiUid') ? document.getElementById('notiUid').value : '';
const notiRid = document.getElementById('notiRid') ? document.getElementById('notiRid').value : '';

const notificationsModule = {
  namespaced: true,
  state: {
    notificationsList: null,
    notificationsTippyUrl: '/notifications/all?count=20',
    notificationsPageUrl: '/notifications/all',
    useApi: true,
  },

  mutations: {
    setNotificationsList(state, payload) {
      Vue.set(state, 'notificationsList', payload);
    },

    setNotificationRead(state, data) {
      const index = state.notificationsList.findIndex((item) => item.id === data[0]);
      if (index > -1) {
        Vue.set(state.notificationsList[index], 'readStatus', data[1]);
      }
    },
  },

  getters: {
    notifications(state) {
      if (!state.useApi) {
        return staticNotifications;
      }

      return state.notificationsList;
    },
  },

  actions: {
    fetchNotifications({commit, state}, all) {
      let url = all === true ? state.notificationsPageUrl : state.notificationsTippyUrl;
      if (state.useApi) {
        if (url.indexOf('?') === -1) {
          url = `${url}?uid=${notiUid}&rid=${notiRid}`;
        } else {
          url = `${url}&uid=${notiUid}&rid=${notiRid}`;
        }

        return storefrontInstance.get(url).then(({data: {data}}) => {
          commit('setNotificationsList', data.notifications);
          // NOTE: "active" is used for tracking checkboxes for each item on Notifications page
          // NOTE: != is used instead of !== in order to cover potential cases of 'undefined'
          if (data.notifications != null) {
            data.notifications.forEach((element) => {
              Vue.set(element, 'active', false);
            });
          }
        });
      }
    },

    updateNotificationRead({commit, state}, id) {
      if (state.useApi) {
        return storefrontInstance.patch(`notifications/updateStatus/${id}/true`).then(({data: {data}}) => {
          commit('setNotificationRead', data);
        });
      }

      return commit('setNotificationRead', id, window.read);
    },
  },
};

export default notificationsModule;
