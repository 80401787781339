import {PaymentProvider} from '@/constants/payment-provider';
import BaseProvider from '@/utils/checkout/payment-providers/base-provider';

class StoredPaymentProvider extends BaseProvider {
  constructor({paymentProvider, savedPaymentId}) {
    super();
    this.providerCode = paymentProvider || PaymentProvider.CYBERSOURCE;
    this.savedPaymentId = savedPaymentId;
  }
  initializePayment() {
    return this.submitPaymentInitForm({
      paymentProviderCode: this.providerCode,
      savedPaymentId: this.savedPaymentId,
    });
  }
}

export default StoredPaymentProvider;
