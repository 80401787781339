const ProductType = Object.freeze({
  Bike: 'BIKE',
  Aftermarket: 'AFTERMARKET',
  CustomWaterBottles: 'BONTRAGER_CUSTOM_BOTTLE',
  ProjectOne: 'P1_BIKE',
  DIProjectOne: 'Project One Bikes',
  DIProjectOneBTO: 'P1BTO',
});
const ProductTypeOrder = Object.freeze([
  ProductType.Bike,
  ProductType.Aftermarket,
  ProductType.CustomWaterBottles,
  ProductType.ProjectOne,
]);

const ProductTypesWithVisibleQuantity = Object.freeze([ProductType.Bike]);

const ProductTypesWithVisibleDeliveryCost = Object.freeze([ProductType.Bike, ProductType.Aftermarket]);

const ProductDeliveryStatus = Object.freeze({
  SHIPPED: 'SHIPPED',
  NOT_SHIPPED: 'NOT_SHIPPED',
  ALLOCATED: 'ALLOCATED',
  BACKORDERED: 'BACKORDERED',
  UNDEFINED: 'UNDEFINED',
});

export {
  ProductType,
  ProductTypeOrder,
  ProductTypesWithVisibleQuantity,
  ProductTypesWithVisibleDeliveryCost,
  ProductDeliveryStatus,
};
