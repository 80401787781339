export default {
  after: function (e, n) {
    return e + '-feltet må vere etter ' + n[0];
  },
  alpha: function (e) {
    return e + '-feltet kan berre innehalde bokstaver';
  },
  alpha_dash: function (e) {
    return e + '-feltet kan berre innehalde alfa-numeriske tegn, samt bindestrek og understrek';
  },
  alpha_num: function (e) {
    return e + ' kan berre innehalde alfanumeriske tegn';
  },
  alpha_spaces: function (e) {
    return e + '-feltet kan berre innehalde alfanumeriske tegn og mellomrom';
  },
  before: function (e, n) {
    return e + '-feltet må vere før ' + n[0];
  },
  between: function (e, n) {
    return e + '-feltet må vere mellom verdiane ' + n[0] + ' og ' + n[1];
  },
  confirmed: function (e) {
    return e + '-feltet samsvarer ikkje';
  },
  credit_card: function (e) {
    return e + '-feltet er ugyldig';
  },
  date_between: function (e, n) {
    return e + '-feltet må vere imellom ' + n[0] + ' og ' + n[1];
  },
  date_format: function (e, n) {
    return e + '-feltet må vere i følgende format: ' + n[0];
  },
  decimal: function (e, n) {
    void 0 === n && (n = []);
    var t = n[0];
    return (
      void 0 === t && (t = '*'),
      e + '-feltet må vere numerisk, og kan innehalde' + (t && '*' !== t ? ' ' + t : '') + ' desimaler'
    );
  },
  digits: function (e, n) {
    return e + '-feltet må vere numerisk og innehalde nøyaktig ' + n[0] + ' siffer';
  },
  dimensions: function (e, n) {
    return e + '-feltet må vere ' + n[0] + ' gonger ' + n[1] + ' piksler';
  },
  email: function (e) {
    return e + '-feltet må innehalde ein gyldig E-post adresse';
  },
  excluded: function (e) {
    return e + '-feltet må ha ein gyldig verdi';
  },
  ext: function (e) {
    return e + '-feltet må innehalde ei gyldig fil';
  },
  image: function (e) {
    return e + '-feltet må vere eit bilete';
  },
  included: function (e) {
    return e + '-feltet må vere ein gyldig verdi';
  },
  ip: function (e) {
    return e + '-feltet må vere ei gyldig IP-adresse';
  },
  max: function (e, n) {
    return e + '-feltet kan ikkje vere lengre enn ' + n[0] + ' tegn';
  },
  max_value: function (e, n) {
    return e + ' kan ikkje vere lengre enn ' + n[0] + ' tegn';
  },
  mimes: function (e) {
    return e + '-feltet må ha ein gyldig filtype';
  },
  min: function (e, n) {
    return e + '-feltet må innehalde minst ' + n[0] + ' tegn';
  },
  min_value: function (e, n) {
    return e + '-feltet må vere ' + n[0] + ' eller mer';
  },
  numeric: function (e) {
    return e + '-feltet kan berre innehalde nummer';
  },
  regex: function (e) {
    return e + ' har ugyldig formatering';
  },
  required: function (e) {
    return e + ' er eit obligatorisk felt';
  },
  size: function (e, n) {
    return (
      e +
      '-feltet må vere mindre enn ' +
      (function (e) {
        var n = 1024,
          t = 0 === (e = Number(e) * n) ? 0 : Math.floor(Math.log(e) / Math.log(n));
        return 1 * (e / Math.pow(n, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t];
      })(n[0])
    );
  },
  url: function (e) {
    return e + ' er ikkje ein gyldig URL';
  },
};
