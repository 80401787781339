<template>
  <div class="space-x-2 flex items-center">
    <span>
      {{ $t('checkout.financing.applePay') }}
    </span>
    <apple-pay-icon />
  </div>
</template>

<script>
import ApplePayIcon from '@/icons/ApplePayIcon.vue';
export default {
  components: {ApplePayIcon},
};
</script>
