import ApplePayPaymentOption from '@/components/checkout/providers/apple-pay/ApplePayPaymentOption.vue';
import CreditCardPaymentOption from '@/components/checkout/providers/credit-card/CreditCardPaymentOption.vue';
import IdealPaymentOption from '@/components/checkout/providers/ideal/IdealPaymentOption.vue';
import {ApplePayProvider} from '@/utils/checkout/payment-providers/apple-pay-provider.js';
import CreditCardProvider from '@/utils/checkout/payment-providers/credit-card-provider.js';
import {Gr4vyProvider} from '@/utils/checkout/payment-providers/gr4vy-provider.js';
import IdealProvider from '@/utils/checkout/payment-providers/ideal-provider.js';
import KcpProvider from '@/utils/checkout/payment-providers/kcp-provider.js';

const applyProviderFilters = (providerName) => {
  return PaymentProviderFilter[providerName]?.() ?? true;
};

const PaymentProvider = Object.freeze({
  CYBERSOURCE: 'CYBERSOURCE',
  CYBERSOURCE_IDEAL: 'CYBERSOURCE_IDEAL',
  CYBERSOURCE_KCP: 'CYBERSOURCE_KCP',
  CYBERSOURCE_APPLE: 'CYBERSOURCE_APPLE',
  STORED_PAYMENT: 'STORED_PAYMENT',
  GR4VY: 'GR4VY_CARD',
  GR4VY_APPLE: 'GR4VY_APPLEPAY',
  GR4VY_KCP: 'GR4VY_KCP',
  GR4VY_IDEAL: 'GR4VY_IDEAL',
  applyProviderFilters,
});

const PaymentProviderConfig = Object.freeze({
  CYBERSOURCE: {
    value: PaymentProvider.CYBERSOURCE,
    component: CreditCardPaymentOption,
    Provider: CreditCardProvider,
  },
  GR4VY_CARD: {
    value: PaymentProvider.GR4VY,
    component: CreditCardPaymentOption,
    Provider: Gr4vyProvider,
  },
  CYBERSOURCE_APPLE: {
    value: PaymentProvider.CYBERSOURCE_APPLE,
    component: ApplePayPaymentOption,
  },
  GR4VY_APPLEPAY: {
    value: PaymentProvider.GR4VY_APPLE,
    component: ApplePayPaymentOption,
  },
  CYBERSOURCE_IDEAL: {
    value: PaymentProvider.CYBERSOURCE_IDEAL,
    component: IdealPaymentOption,
    Provider: IdealProvider,
  },
  CYBERSOURCE_KCP: {
    value: PaymentProvider.CYBERSOURCE_KCP,
    displayName: 'KCP',
    Provider: KcpProvider,
  },
  GR4VY_KCP: {
    value: PaymentProvider.GR4VY_KCP,
    displayName: 'KCP',
    Provider: KcpProvider,
  },
  GR4VY_IDEAL: {
    value: PaymentProvider.GR4VY_IDEAL,
    component: IdealPaymentOption,
    Provider: IdealProvider,
  },
});

const PaymentProviderFilter = {
  [PaymentProvider.CYBERSOURCE_APPLE]: () => {
    return ApplePayProvider.canMakePayments();
  },
  [PaymentProvider.GR4VY_APPLE]: () => {
    return ApplePayProvider.canMakePayments();
  },
};

export {PaymentProviderConfig, PaymentProvider};
