const AllocatedBackorderSortCode = Object.freeze({
  Sku: 'Sku',
  OrderNumber: 'OrderNumber',
  CustomerPO: 'CustomerPO',
  OrderDate: 'OrderDate',
  RequestedDate: 'RequestedDate',
  UnitPrice: 'UnitPrice',
  EstimatedShipDate: 'EstimatedShipDate',
  QuantityAllocatedOrShipped: 'QuantityAllocatedOrShipped',
  QuantityBackordered: 'QuantityBackordered',
  ShipToNumber: 'ShipToNumber',
});

const AllocatedBackorderFileExportStatus = Object.freeze({
  Completed: 'Completed',
});

const AllocatedBackorderSortOption = {
  [AllocatedBackorderSortCode.Sku]: {
    code: AllocatedBackorderSortCode.Sku,
    order: 'ASC',
  },
  [AllocatedBackorderSortCode.OrderNumber]: {
    code: AllocatedBackorderSortCode.OrderNumber,
    order: 'DESC',
  },
  [AllocatedBackorderSortCode.CustomerPO]: {
    code: AllocatedBackorderSortCode.CustomerPO,
    order: 'ASC',
  },
  [AllocatedBackorderSortCode.OrderDate]: {
    code: AllocatedBackorderSortCode.OrderDate,
    order: 'ASC',
  },
  [AllocatedBackorderSortCode.RequestedDate]: {
    code: AllocatedBackorderSortCode.RequestedDate,
    order: 'ASC',
  },
  [AllocatedBackorderSortCode.UnitPrice]: {
    code: AllocatedBackorderSortCode.UnitPrice,
    order: 'ASC',
  },
  [AllocatedBackorderSortCode.EstimatedShipDate]: {
    code: AllocatedBackorderSortCode.EstimatedShipDate,
    order: 'ASC',
  },
  [AllocatedBackorderSortCode.QuantityAllocatedOrShipped]: {
    code: AllocatedBackorderSortCode.QuantityAllocatedOrShipped,
    order: 'DESC',
  },
  [AllocatedBackorderSortCode.QuantityBackordered]: {
    code: AllocatedBackorderSortCode.QuantityBackordered,
    order: 'DESC',
  },
  [AllocatedBackorderSortCode.ShipToNumber]: {
    code: AllocatedBackorderSortCode.ShipToNumber,
    order: 'DESC',
  },
};

const AllocatedBackorderHideFilter = Object.freeze({
  Unallocated: 'UNALLOCATED',
  LinesWithoutComments: 'LINES_WITHOUT_COMMENTS',
  NotSoldOut: 'NOT_SOLD_OUT',
  Warranties: 'WARRANTIES',
  Preseason: 'PRESEASON',
});

const AllocatedBackorderTabNameETA = Object.freeze({
  BIKE: 'BIKE-ETA',
  AFTERMARKET: 'AFTERMARKET-ETA',
});
const allocatedBackorderTabNamesETA = Object.values(AllocatedBackorderTabNameETA);

const DefaultFiltersPayload = Object.freeze({
  hide: [],
  searchTerms: {
    description: '',
    orderNumber: '',
    customerPo: '',
    sku: '',
    shipToNumber: '',
  },
});

const EmptyFilters = Object.freeze({
  hideUnallocated: false,
  hideLinesWithoutComments: false,
  hideWarranties: false,
  hidePreseason: false,
  showSoldOut: false,
  showProjectOne: false,
  descriptionSearchTerm: '',
  orderNumberSearchTerm: '',
  customerPoSearchTerm: '',
  sku: '',
  shipToNumberSearchTerm: '',
});

export {
  AllocatedBackorderSortCode,
  AllocatedBackorderFileExportStatus,
  AllocatedBackorderSortOption,
  AllocatedBackorderHideFilter,
  AllocatedBackorderTabNameETA,
  allocatedBackorderTabNamesETA,
  DefaultFiltersPayload,
  EmptyFilters,
};
