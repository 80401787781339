import {PdlToastType} from '@/constants/pdl-toast-type';

const MetaFeedbackType = Object.freeze({
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
});

const FeedbackTypeToToastType = Object.freeze({
  [MetaFeedbackType.SUCCESS]: PdlToastType.SUCCESS,
  [MetaFeedbackType.INFO]: PdlToastType.INFO,
  [MetaFeedbackType.WARNING]: PdlToastType.WARNING,
  [MetaFeedbackType.ERROR]: PdlToastType.ERROR,
});

function getMetaFeedbackType(responseData) {
  return responseData?.meta?.feedback?.type;
}

function checkMetaFeedbackType(responseData, feedbackType) {
  if (!Object.hasOwn(MetaFeedbackType, feedbackType)) {
    throw new Error(`${feedbackType} is not supported. Use value of MetaFeedbackType constant instead.`);
  }

  return getMetaFeedbackType(responseData).toLowerCase() === feedbackType.toLowerCase();
}

function resolveToastType(metaFeedbackType) {
  if (metaFeedbackType in MetaFeedbackType) {
    return FeedbackTypeToToastType[metaFeedbackType];
  }

  throw new Error(`${metaFeedbackType} is not supported. Use value of MetaFeedbackType constant instead.`);
}

export {MetaFeedbackType, getMetaFeedbackType, checkMetaFeedbackType, resolveToastType};
