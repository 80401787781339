<template>
  <div>
    <div class="compare-rack__product-container flex" qaid="compareRackProductContainer">
      <div
        v-for="n in 4"
        :key="`compare-item-${n}`"
        class="compare-rack__wrap small-6 medium-3 column flex items-stretch"
        :qaid="`compareRackProductCell-${n}`"
      >
        <div v-if="items[n - 1]" class="compare-rack__product flex flex-col justify-between" qaid="compareRackProduct">
          <a id="compareRackProductWrapperLink" :href="getProductUrl(items[n - 1].productUrl)">
            <div class="compare-rack__product-title" qaid="compareRackProductTitle">{{ items[n - 1].productName }}</div>
            <img alt="" :src="items[n - 1].imageUrl" qaid="compareRackProductImage" />
          </a>
          <div>
            <trek-button
              text
              small
              icon="highlight_off"
              class="mt-1 mb-2 text-left"
              qaid="compareRackProductRemove"
              @click="removeFromRack(items[n - 1])"
            >
              <span>{{ $t('productCompare.removeFromRack') }}</span>
              <input type="hidden" class="rack-product-code" value="items[n-1].productCode" />
            </trek-button>
          </div>
        </div>
        <trek-button
          v-else
          text
          small
          block
          icon="add_circle_outline"
          class="compare-rack__add-product py-4"
          type="button"
          qaid="compareRackAddProductButton"
          @click="addItemToRack"
        >
          <span>{{ $t('productCompare.addProduct') }}</span>
        </trek-button>
      </div>
    </div>
    <trek-link button primary block :href="compareHref" class="my-2 hide-for-large" qaid="compareRackCloseButtonMobile">
      <span>{{ $t('productCompare.startComparison') }}</span>
    </trek-link>
    <trek-button
      text
      small
      icon="delete"
      class="lg:mt-1 mb-2 lg:mb-0"
      qaid="compareRackDeleteRackButton"
      @click="emptyRack"
    >
      <span>{{ $t('productCompare.deleteRack') }}</span>
    </trek-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {Url} from '@/utils/url';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';

export default {
  components: {TrekButton, TrekLink},
  data() {
    return {
      testIndex: 0,
      scrollTopLocation: null,
      compareProductCardHeight: 0,
      isIphone: navigator.platform.indexOf('iPhone') != -1,
      isSafari: navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1,
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath', 'unitId']),
    ...mapState('compare', ['racks']),
    ...mapState('storage', ['lastPlp']),
    ...mapGetters('storage', ['getPlpLocationUrl']),

    compareHref() {
      return `${this.encodedContextPath}/compare/`;
    },

    items() {
      if (!Object.prototype.hasOwnProperty.call(this.racks, this.unitId)) return [];
      return this.racks[this.unitId];
    },
  },

  mounted() {
    this.createRack(this.unitId);
  },

  methods: {
    ...mapActions('compare', ['addToRack', 'createRack', 'emptyRack', 'removeFromRack']),
    ...mapMutations('compare', ['setCompareRackDialog']),

    getProductUrl: (url) => Url.withContextPath(url),

    addItemToRack() {
      if (this.lastPlp === this.getPlpLocationUrl) {
        this.setCompareRackDialog(false);
      } else {
        location.assign(this.lastPlp ? this.lastPlp : this.encodedContextPath);
      }
    },
  },
};
</script>
