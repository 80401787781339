import {RideClubB2BUserRoles, RideClubScopes} from '@/constants/ride-club.js';

const routeMapScope = function (isB2Bsite, userRideClubRole) {
  let scope;
  if (isB2Bsite) {
    switch (userRideClubRole) {
      case RideClubB2BUserRoles.ADMIN:
        scope = RideClubScopes.BUSINESS;
        break;
      case RideClubB2BUserRoles.MARKETING_ADMIN:
        scope = RideClubScopes.GLOBAL_MARKETING;
        break;
      case RideClubB2BUserRoles.RIDER:
      default:
        scope = RideClubScopes.PERSONAL;
        break;
    }
  } else {
    scope = RideClubScopes.PERSONAL;
  }
  return scope;
};

export {routeMapScope};
