function n(e) {
  return e.charAt(0).toUpperCase() + e.slice(1);
}
export default {
  _default: function (e) {
    return n(e) + ' ei oma sobivat väärtust';
  },
  after: function (e, a) {
    var t = a[0];
    return n(e) + ' peab olema hiljem kui ' + t;
  },
  alpha: function (e) {
    return n(e) + ' võib sisaldada ainult tähti';
  },
  alpha_dash: function (e) {
    return n(e) + ' võib sisaldada ainult tähti, numbreid, kriipse ja alakriipse';
  },
  alpha_num: function (e) {
    return n(e) + ' võib sisaldada ainult tähti ja numbreid';
  },
  alpha_spaces: function (e) {
    return n(e) + ' võib sisaldada ainult tähti ja tühikuid';
  },
  before: function (e, a) {
    var t = a[0];
    return n(e) + ' peab olema varem kui ' + t;
  },
  between: function (e, a) {
    var t = a[0],
      i = a[1];
    return n(e) + ' peab jääma vahemikku ' + t + ' kuni ' + i;
  },
  confirmed: function (e) {
    return n(e) + ' on kontrollist erinev';
  },
  credit_card: function (e) {
    return n(e) + ' ei oma sobivat väärtust';
  },
  date_between: function (e, a) {
    var t = a[0],
      i = a[1];
    return n(e) + ' peab olema vahemikus ' + t + ' kuni ' + i;
  },
  date_format: function (e, a) {
    var t = a[0];
    return n(e) + ' peab olema kujul ' + t;
  },
  decimal: function (e, a) {
    void 0 === a && (a = []);
    var t = a[0];
    return (
      void 0 === t && (t = '*'),
      n(e) + ' peab olema number ja võib sisaldada ' + ('*' === t ? 'komakohta' : t + ' numbrit pärast koma')
    );
  },
  digits: function (e, a) {
    var t = a[0];
    return n(e) + ' peab koosnema täpselt ' + t + '-st numbrist';
  },
  dimensions: function (e, a) {
    var t = a[0],
      i = a[1];
    return n(e) + ' peab olema ' + t + ' korda ' + i + ' pikslit suur';
  },
  email: function (e) {
    return n(e) + ' peab olema e-maili aadress';
  },
  excluded: function (e) {
    return n(e) + ' ei oma sobivat väärtust';
  },
  ext: function (e) {
    return n(e) + ' peab olema sobiv fail';
  },
  image: function (e) {
    return n(e) + ' peab olema pilt';
  },
  included: function (e) {
    return n(e) + ' ei oma sobivat väärtust';
  },
  ip: function (e) {
    return n(e) + ' peab olema IP-aadress';
  },
  max: function (e, a) {
    var t = a[0];
    return n(e) + ' ei tohi olla pikem kui ' + t + ' tähemärki';
  },
  max_value: function (e, a) {
    var t = a[0];
    return n(e) + ' peab olema ' + t + ' või väisem';
  },
  mimes: function (e) {
    return n(e) + ' peab olema sobivat tüüpi fail';
  },
  min: function (e, a) {
    var t = a[0];
    return n(e) + ' peab olema vähemalt ' + t + ' tähemärki pikk';
  },
  min_value: function (e, a) {
    var t = a[0];
    return n(e) + ' peab olema ' + t + ' või suurem';
  },
  numeric: function (e) {
    return n(e) + ' võib sisaldada ainult numbreid';
  },
  regex: function (e) {
    return n(e) + ' pole sobival kujul';
  },
  required: function (e) {
    return n(e) + ' on nõutud väli';
  },
  size: function (e, a) {
    var t = a[0];
    return (
      n(e) +
      ' peab olema väiksem kui ' +
      (function (e) {
        var a = 1024,
          t = 0 === (e = Number(e) * a) ? 0 : Math.floor(Math.log(e) / Math.log(a));
        return 1 * (e / Math.pow(a, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t];
      })(t)
    );
  },
  url: function (e) {
    return n(e) + ' peab olema URL';
  },
};
