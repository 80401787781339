var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.element,{tag:"component",class:[
    'nav-tab',
    {
      'is-active': _vm.isActive,
    },
  ],attrs:{"id":`${_vm.tabId}`,"qaid":`nav-tab-${_vm.activeLink}-${_vm.tabId}`,"href":_vm.composeLink(_vm.url),"role":_vm.url ? 'link' : 'button',"target":_vm.setTarget(_vm.target)},on:{"click":function($event){return _vm.clickTab(_vm.id)}}},[_c('div',{class:[
      'nav-tab__text',
      {
        'is-active': _vm.isActive && _vm.hasTabText,
      },
    ],domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._v(" "),(_vm.mobileSubText)?_c('div',{staticClass:"nav-tab__mobile-sub-text",domProps:{"innerHTML":_vm._s(_vm.mobileSubText)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }