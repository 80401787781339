import storefrontInstance from '@/api/instances/storefront';
import {injectForm} from '@/utils/checkout/checkout-utils';
import {ReCaptchaHelpers} from '@/utils/recaptcha-helpers';

class BaseProvider {
  constructor({shouldSaveBillingInfo, isBillingAddressSameAsDelivery} = {}) {
    this.shouldSaveBillingInfo = shouldSaveBillingInfo ?? false;
    this.isBillingAddressSameAsDelivery = isBillingAddressSameAsDelivery ?? true;
  }

  #populateInitForm(formData) {
    const replacementData = {
      card_type: this.cardType,
      card_number: this.cardNumber,
      card_cvn: this.cvn,
      card_expiry_date: this.expiryDate,
    };

    return Object.entries(replacementData).reduce((updatedData, [key, replacementValue]) => {
      const replacementKey = '${' + key + '}';
      return updatedData.replace(replacementKey, replacementValue);
    }, formData);
  }

  #getErrorPayload(response) {
    const feedback = response?.meta?.feedback;
    const message = feedback.message?.basePropertyKey;
    const errorFields = feedback.errorFields;
    const linkTarget = feedback.link?.target;

    return {
      message,
      errorFields,
      linkTarget,
    };
  }

  async #getReCaptchaToken() {
    return window.tk_gen?.reCaptchaSiteKey
      ? ReCaptchaHelpers().getReCaptchaToken(window.tk_gen?.reCaptchaSiteKey, {action: 'ecommerce'})
      : '';
  }

  async submitPaymentInitForm(data) {
    const token = await this.#getReCaptchaToken();
    const paymentInitData = {
      ...data,
      reCaptchaToken: token,
      saveInAccount: this.shouldSaveBillingInfo,
      newBillingAddress: !this.isBillingAddressSameAsDelivery,
    };

    let paymentInitResponse;
    try {
      paymentInitResponse = (
        await storefrontInstance.post('/checkout/multi/payment/billingDetails/v2/paymentInit', paymentInitData)
      ).data;
    } catch (error) {
      console.error(`Unexpected error when initializing payment: `, error);
    }
    const formData = paymentInitResponse?.data?.htmlOfAutosubmitForm;
    if (!formData) {
      const error = new Error('Failed to initialize payment');
      error.payload = this.#getErrorPayload(paymentInitResponse);
      throw error;
    }

    const updatedFormData = this.#populateInitForm(formData);

    const form = injectForm(updatedFormData);
    form.submit();
  }
}

export default BaseProvider;
