import {resolveToastType} from '@/constants/meta-feedback-type';
import {PDL_TOAST_METHOD} from '@/constants/pdl-toast-type';

function isValidToast(feedback) {
  const isToast = feedback?.method === PDL_TOAST_METHOD;
  const hasMessage = feedback?.message?.basePropertyValue;

  return Boolean(feedback?.type && hasMessage && isToast);
}

function hasToast(data) {
  return isValidToast(data?.meta?.feedback);
}

function getToast(feedback) {
  const {type, toastDismissable, toastDuration} = feedback;
  const message = feedback.message.basePropertyValue;

  return {type, message, toastDismissable, toastDuration};
}

function axiosToast(data) {
  if (hasToast(data)) {
    const {type, message, toastDismissable, toastDuration} = getToast(data.meta.feedback);
    const duration = typeof toastDuration === 'number' && toastDuration >= 0 ? toastDuration : 5000;
    const showClose = duration === 0 ? true : Boolean(toastDismissable);

    window.vm.$notify({
      type: resolveToastType(type),
      message,
      showClose: showClose,
      duration: duration,
      link: data.meta.feedback.link,
    });
  }
}

const axiosConfig = {
  baseURL: window.ACC?.config.encodedContextPath,
};

export {axiosConfig, axiosToast, getToast};
