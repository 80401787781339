export default {
  _default: function (n) {
    return n + '-н утга буруу байна';
  },
  after: function (n, e) {
    return n + '-н утга ' + e[0] + '-с бага ' + (e[1] ? 'эсвэл тэнцүү ' : '') + 'байх ёстой';
  },
  alpha: function (n) {
    return n + '-н утга зөвхөн үсэг агуулах боломжтой';
  },
  alpha_dash: function (n) {
    return n + '-н утга зөвхөн үсэг, дундуур зураас, доогуур зураас агуулах боломжтой';
  },
  alpha_num: function (n) {
    return n + '-н утга зөвхөн тоо болон үсэг агуулах боломжтой';
  },
  alpha_spaces: function (n) {
    return n + '-н утга зөвхөн үсэг болон зай агуулах боломжтой';
  },
  before: function (n, e) {
    return n + '-н утга ' + e[0] + '-с өмнө ' + (e[1] ? 'эсвэл тэнцүү ' : '') + 'байх ёстой';
  },
  between: function (n, e) {
    return n + '-н утга зөвхөн ' + e[0] + '-с ' + e[1] + ' -ны хооронд байх ёстой';
  },
  confirmed: function (n) {
    return n + '-н утга буруу байна';
  },
  credit_card: function (n) {
    return n + '-н утга зөв картын дугаар байх ёстой';
  },
  date_between: function (n, e) {
    return n + '-н утга ' + e[0] + ' болон ' + e[1] + '-ы хооронд байх шаардлагатай';
  },
  date_format: function (n, e) {
    return n + '-н утга ' + e[0] + ' форматтай байна';
  },
  decimal: function (n, e) {
    void 0 === e && (e = []);
    var t = e[0];
    return (
      void 0 === t && (t = '*'),
      n + '-н утга зөвхөн тоо байх ба ' + (t && '*' !== t ? t : '') + ' бутархай тоо агуулах боломжтой'
    );
  },
  digits: function (n, e) {
    return n + '-н утга зөвхөн тоо байх ба яг  ' + e[0] + ' оронтой байна';
  },
  dimensions: function (n, e) {
    return n + '-н хэмжээ ' + e[0] + 'x' + e[1] + ' пикселээс хэтрэх байх шаардлагатай';
  },
  email: function (n) {
    return n + '-н утга бодит майл байх ёстой';
  },
  excluded: function (n, e) {
    return n + '-н утга ' + e[0] + ' байж болохгүй';
  },
  ext: function (n, e) {
    return n + ' заавал файл заавал ' + e[0] + ' форматтай байх ёстой';
  },
  image: function (n) {
    return n + ' заавал зураг байх ёстой';
  },
  included: function (n, e) {
    return n + '-н утга заавал дараахын нэг нь байна (' + e[0] + ')';
  },
  integer: function (n) {
    return n + '-н утга тоо байх ёстой';
  },
  ip: function (n) {
    return n + '-н утга зөвхөн IP хаяг байна';
  },
  length: function (n, e) {
    var t = e[0],
      r = e[1];
    return r ? n + '-н урт нь ' + t + ' болон ' + r + '-ны хооронд байна' : n + '-н урт нь ' + t + ' байна';
  },
  max: function (n, e) {
    return n + '-н урт нь хамгийн ихдээ ' + e[0] + ' байна';
  },
  max_value: function (n, e) {
    return n + '-н утга ' + e[0] + '-с хэтрэхгүй байна';
  },
  mimes: function (n) {
    return n + ' зөвшөөрөгдөөгүй файл форматтай байна';
  },
  min: function (n, e) {
    return n + '-н урт нь хамгийн багадаа ' + e[0] + ' байна';
  },
  min_value: function (n, e) {
    return n + '-н утга ' + e[0] + '-с багагүй байна';
  },
  numeric: function (n) {
    return n + '-н утга зөвхөн тоо байна';
  },
  regex: function (n) {
    return n + '-н утга буруу форматтай байна';
  },
  required: function (n) {
    return n + '-н утга заавал байх ёстой';
  },
  required_if: function (n) {
    return n + '-н утга заавал байх ёстой';
  },
  size: function (n, e) {
    return (
      n +
      ' хэмжээ ' +
      (function (n) {
        var e = 1024,
          t = 0 === (n = Number(n) * e) ? 0 : Math.floor(Math.log(n) / Math.log(e));
        return 1 * (n / Math.pow(e, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t];
      })(e[0]) +
      '-с хэтрэхгүй байна'
    );
  },
  url: function (n) {
    return n + ' зөв URL хаяг биш байна';
  },
};
