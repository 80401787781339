<template>
  <div
    ref="nav-lists-layout"
    :class="[
      'nav-lists-layout',
      {
        'nav-lists-layout--grid': isGridLayout,
        [`nav-lists-layout--grid--${availableColumns}-col`]: isGridLayout,
      },
    ]"
  >
    <div v-for="list in lists" :key="list.columnHeader">
      <nav-list
        ref="nav-lists-layout__list"
        :column-header="list.columnHeader"
        :column-header-url="list.url"
        :uid="list.uid"
        :links="filterLinks(list.links)"
        :col-span="singularListColSpan"
        :cols-available="singularListColsAvailable"
      />
    </div>
  </div>
</template>

<script>
import NavList from '@/navigation/components/NavList';
import {mapState} from 'vuex';
import throttle from 'lodash/throttle';

export default {
  name: 'NavListsLayout',
  components: {NavList},
  props: {
    hasCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      singularListColSpan: null,
      singularListColsAvailable: null,
    };
  },
  computed: {
    ...mapState('navigation', ['activeTab', 'currentOverlay']),
    lists() {
      const lists = this.currentOverlay?.tabs.find((tab) => tab.uid === this.activeTab && tab.visible == true)?.lists;
      return lists ? lists.filter((list) => list.visible && this.filterLinks(list.links).length) : [];
    },
    isGridLayout() {
      // Grid is preferred for multiple lists or singular lists that have a column header
      return this.lists?.length > 1 || (this.lists?.length === 1 && this.lists[0]?.columnHeader);
    },
    availableColumns() {
      return this.hasCard ? 3 : 4;
    },
  },
  mounted() {
    this.updateMasonryLayout();
    window.addEventListener('resize', this.throttledResize);
  },
  updated() {
    this.wrapSingularList();
    this.updateMasonryLayout();
  },
  destroyed() {
    window.removeEventListener('resize', this.throttledResize);
  },
  methods: {
    filterLinks(links) {
      // This conditional logic matches what's in navNode.tag
      return links?.filter((link) => link.visible && this.linkNotRestricted(link) && this.linkHasUrl(link));
    },
    linkNotRestricted(link) {
      return this.currentOverlay.hideLinksWithoutProducts ? link.hasVisibleProducts : true;
    },
    linkHasUrl(link) {
      return link.url && link.url.charAt(0) !== '#';
    },
    wrapSingularList() {
      if (!this.isGridLayout) {
        this.singularListColsAvailable = this.availableColumns;
        const numOfLinks = this.filterLinks(this.lists[0]?.links)?.length || 0;
        const minLinksPerCol = this.currentOverlay?.minLinksPerCol;
        const desiredColumns = Math.floor(numOfLinks / minLinksPerCol);
        this.singularListColSpan = desiredColumns < this.availableColumns ? desiredColumns : this.availableColumns;
      }
    },
    throttledResize: throttle(function () {
      // Prevents resize event listener from running all the time & reducing performance
      this.updateMasonryLayout();
    }, 250),
    updateMasonryLayout() {
      /* Get the grid object, its row-gap, and the size of its implicit rows */
      const grid = this.$refs['nav-lists-layout'];
      const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

      if (this.activeTab) {
        this.$refs['nav-lists-layout__list'].forEach((item) => {
          /* Calculate and set how many rows each list item should span */
          const rowSpan = Math.ceil((item.$el.clientHeight + rowGap) / (rowHeight + rowGap));
          item.$el.parentElement.style.gridRowEnd = `span ${rowSpan}`;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-lists-layout {
  padding-bottom: 5rem; /* Cushion to aid with scroll height when banners exist/search open */
  &--grid {
    display: grid;
    gap: 2rem;
    grid-auto-rows: 0; /* Sizes the implicit rows for Masonry layout */

    &--3-col {
      @include breakpoint(large) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &--4-col {
      @include breakpoint(large) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
</style>
