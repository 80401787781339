const ExpressCheckoutAddressTemplate = {
  id: '',
  title: '',
  titleCode: '',
  firstName: 'Express',
  lastName: 'Checkout',
  companyName: '',
  line1: '123 Setup Address',
  line2: '',
  town: 'Madison',
  region: {
    isocodeShort: 'WI',
    countryIso: 'US',
    isocode: 'US-WI',
    name: '',
  },
  district: '',
  postalCode: '53704',
  phone: '',
  cellphone: '',
  email: '',
  country: {
    isocode: 'US',
    name: '',
  },
  shippingAddress: true,
  defaultAddress: false,
  visibleInAddressBook: false,
  formattedAddress: '',
};

export {ExpressCheckoutAddressTemplate};
