import {computed} from 'vue';
import store from '@/store';

export function useCartUtils() {
  const cartUrl = computed(() => {
    return store.state.backend.hostUrl.includes('electra')
      ? `${store.state.backend.trekBrandNavURL}/cart?clear=true&retailer=${store.state.user.selectedRetailer?.name}&postCode=${store.state.user.selectedLocation?.postcode}`
      : `${store.state.backend.trekBrandNavURL}/cart`;
  });

  return {cartUrl};
}
