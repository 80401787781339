const navigationModule = {
  namespaced: true,
  state: {
    activeLink: null,
    activeTab: null,
    mobileMenuOpen: false,
    currentOverlay: null,
    overlays: [],
    overlayHeight: 0,
  },
  mutations: {
    setActiveLink(state, payload) {
      state.activeLink = payload;
    },
    setActiveTab(state, payload) {
      state.activeTab = payload;
    },
    setCurrentOverlay(state, payload) {
      state.currentOverlay = payload;
    },
    setMobileMenuOpen(state, payload) {
      state.mobileMenuOpen = payload;
    },
    setOverlays(state, payload) {
      state.overlays = payload;
    },
    appendToOverlays(state, payload) {
      state.overlays.push(payload);
    },
    setOverlayHeight(state, payload) {
      state.overlayHeight = payload;
    },
  },
  actions: {
    // This action takes the dense JSON object coming from BE into a clean one with only those items we actually need
    optimizeOverlayData({commit}, payload) {
      const tabs = [];

      if (payload.jsonData && payload.jsonData.length) {
        payload.jsonData.forEach((tab) => {
          // Setup lists and card vars for this tab
          const tabLists = [];
          let tabCard = null;

          if (tab.children && tab.children.length) {
            // Separate children into lists & cards
            const lists = tab.children.filter((child) => child.links && child.links.length);
            const cardNode = tab.children.find((child) => child.editorialCard);

            // Build the tabLists array
            lists.forEach((list) => {
              tabLists.push({
                uid: list.uid,
                visible: list.visible,
                columnHeader: list.url ? list.url.linkName : null,
                url: list.url ? list.url.url : null,
                target: list.url ? list.url.target : null,
                links: list.links,
              });
            });

            // Set card data
            if (cardNode && cardNode.editorialCard.visible) {
              tabCard = {};
              tabCard.uid = cardNode.uid;
              tabCard.img = `https://media.trekbikes.com/image/upload/${cardNode.editorialCard.image} `;
              tabCard.text = cardNode.editorialCard.editorialText;
              if (cardNode?.url?.visible) {
                tabCard.action = {
                  uid: cardNode.url.uid,
                  text: cardNode.url.linkName,
                  url: cardNode.url.url,
                  target: cardNode.url.target === 'NEWWINDOW' ? '_blank' : null,
                };
              }
            }
          }

          // Build this tab's data and push it to the tabs array
          const isLink = tab.uid.includes('view'); // tab uid's will either be view... or expand...
          tabs.push({
            uid: tab.uid,
            visible: tab.visible,
            hasVisibleProducts: tab.hasVisibleProducts,
            text: tab.url ? tab.url.linkName : null,
            target: isLink && tab.url ? tab.url.target : null,
            url: isLink && tab.url ? tab.url.url : null,
            mobileSubText: tab.mobileSubText,
            lists: tabLists,
            card: tabCard,
          });
        });
      }

      // Append this overlay data to the overlays array
      commit('appendToOverlays', {
        parentLink: payload.link,
        parentLinkText: payload.linkText,
        tabs: tabs,
        hideLinksWithoutProducts: payload.hideLinksWithoutProducts,
        isAutoExpandMobile: payload.isAutoExpandMobile,
        minLinksPerCol: payload.minLinksPerCol,
      });
    },
    setDefaultActiveTab({commit, state}) {
      commit('setActiveTab', state.currentOverlay?.tabs.find((tab) => tab.lists.length && tab.visible)?.uid);
    },
    updateActiveLink({commit, dispatch, state}, payload) {
      if (state.activeLink === payload) {
        dispatch('unsetNav');
      } else {
        commit('setActiveLink', payload);

        const overlayData = state.overlays.length
          ? state.overlays.find((item) => item.parentLink === state.activeLink)
          : null;
        commit('setCurrentOverlay', overlayData);

        // If there is only 1 tab, it should be pre-selected so that mobile drilldowns skip the choose-a-tab step
        if (overlayData.tabs.length === 1) {
          dispatch('setDefaultActiveTab', payload);
        }
      }
    },
    unsetNav({commit}) {
      commit('setActiveLink', null);
      commit('setActiveTab', null);
      commit('setMobileMenuOpen', false);
    },
    calculateOverlayHeight({commit}) {
      /* Phase 2: Consider refactoring in such a way where the overlay's
      height doesn't need to be calculated on resize with JavaScript. If it must be calculated via
      JavaScript, then perhaps NavApp can do the calculation since compact/large
      overlays will need the same height calculated. */

      const header = document.body.querySelector('[qaid="nav-app"]');
      /* Account for banner only if user hasn't scrolled */
      const topNavigationSlotHeight = window.scrollY === 0 ? header.offsetParent.offsetTop : 0;

      /* Calculate for any banner size */
      const overlayHeight = `height: calc(100vh - ${topNavigationSlotHeight}px - ${header.offsetHeight}px);`;

      commit('setOverlayHeight', overlayHeight);
    },
  },
};

export default navigationModule;
