<template>
  <div class="primary-navigation__cart siteMenu__button">
    <button
      ref="miniCartButton"
      v-tippy="tippy"
      qaid="b2c-mini-cart-menu-button"
      :title="$t('cartPage')"
      data-ga-cart="cart"
      :data-ga-label="totalItems"
      class="header-bottom__cart-button menu-trigger flex"
      @show="fetchMiniCart"
      @click="addTooltipEventListener"
    >
      <pdl-icon qaid="mini-cart-icon" name="shopping_cart" />
      <pdl-badge
        v-if="totalItems > 0"
        id="miniCartItemCount"
        qaid="mini-cart-badge"
        class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        :value="totalItems"
      />
      <div v-cloak :class="['text-sm p-2 overflow-y-auto b2c-mini-cart', {'py-4': items.length < 1}]">
        <pdl-loading
          v-if="isLoading && items.length < 1"
          :is-loading="isLoading"
          qaid="b2c-mini-cart-loading"
          :message="$t('text.loadingMessage')"
        />

        <template v-if="totalItems < 1">
          <pdl-heading
            v-if="!isLoading"
            qaid="b2c-mini-cart-empty"
            :level="4"
            size="sm"
            v-text="$t('popup.cart.empty')"
          />

          <p
            v-for="potentialOrderPromotion in potentialOrderPromotions"
            :key="potentialOrderPromotion.code"
            qaid="b2c-mini-cart-potential-promotions"
            class="minicart__promo text-weak"
            v-text="potentialOrderPromotion.description"
          />
          <p
            v-for="potentialProductPromotion in potentialProductPromotions"
            :key="potentialProductPromotion.code"
            qaid="b2c-mini-cart-potential-promotions"
            class="minicart__promo text-weak"
            v-text="potentialProductPromotion.description"
          />

          <p
            v-for="appliedOrderPromotion in appliedOrderPromotions"
            :key="appliedOrderPromotion.code"
            qaid="b2c-mini-cart-applied-promotions"
            class="minicart__promo text-weak"
            v-text="appliedOrderPromotion.description"
          />

          <p
            v-for="appliedProductPromotion in appliedProductPromotions"
            :key="appliedProductPromotion.code"
            qaid="b2c-mini-cart-applied-promotions"
            class="minicart__promo text-weak"
            v-text="appliedProductPromotion.description"
          />
        </template>
        <template v-else>
          <div qaid="b2c-mini-cart-items">
            <mini-cart-item v-for="item in items" :key="item.sku" :item="item" />
          </div>

          <div class="mt-3 mb-3 flex justify-between">
            <div v-text="$t('text.order.subtotal')" />
            <div v-text="subTotalPrice" />
          </div>

          <div v-if="appliedOrderPromotions.length || appliedProductPromotions.length" class="flex justify-between">
            <div v-text="$t('basket.couponCode.discount')" />
            <div v-text="totalDiscounts" />
          </div>

          <div class="mb-3 flex justify-between text-lg font-bold">
            <div v-text="$t('popup.cart.total')" />
            <div qaid="b2c-mini-cart-total-price" v-text="totalPrice" />
          </div>

          <p
            v-for="potentialOrderPromotion in potentialOrderPromotions"
            :key="potentialOrderPromotion.code"
            qaid="b2c-mini-cart-potential-promotions"
            v-text="potentialOrderPromotion.description"
          />

          <p
            v-for="potentialProductPromotion in potentialProductPromotions"
            :key="potentialProductPromotion.code"
            qaid="b2c-mini-cart-potential-promotions"
            v-text="potentialProductPromotion.description"
          />

          <p
            v-for="appliedOrderPromotion in appliedOrderPromotions"
            :key="appliedOrderPromotion.code"
            qaid="b2c-mini-cart-applied-promotions"
            v-text="appliedOrderPromotion.description"
          />
          <p
            v-for="appliedProductPromotion in appliedProductPromotions"
            :key="appliedProductPromotion.code"
            qaid="b2c-mini-cart-applied-promotions"
            v-text="appliedProductPromotion.description"
          />

          <div v-if="hasVatTax" class="justify-between mb-2 flex italic">
            <div v-text="$t('basket.page.totals.netTotal')" />
            <div v-text="cartNetTotalValue" />
          </div>

          <div v-if="hasVatTax" class="justify-between mb-2 flex italic">
            <div v-text="$t('basket.page.totals.estimatedVAT')" />
            <div v-text="cartTaxesValue" />
          </div>

          <pdl-link qaid="b2c-mini-cart-view-cart-link" block button primary :href="cartUrl">
            {{ $t('basket.view.basket') }}
          </pdl-link>
        </template>
      </div>
    </button>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {PdlBadge} from '@pedal/pdl-badge';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlLink} from '@pedal/pdl-link';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlHeading} from '@pedal/pdl-section-header';
import MiniCartItem from '@/components/mini-cart/b2c/MiniCartItem';
import NumberFormatter from '@/utils/NumberFormatter';
import {useCartUtils} from '@/composables/cart-utils.js';

const numberFormatter = new NumberFormatter({priceFormatter: window.ACC.customVariables.priceFormatter});

export const UPDATE_MINI_CART = 'update.mini.cart';
export default {
  components: {PdlBadge, PdlLink, PdlIcon, PdlLoading, PdlHeading, MiniCartItem},
  setup() {
    const {cartUrl} = useCartUtils();

    return {cartUrl};
  },
  data() {
    return {
      isInContainerConfigMode: false,
      tippy: {
        html: '.b2c-mini-cart',
        interactive: true,
        reactive: true,
        trigger: 'click',
        placement: 'bottom-end',
        theme: 'flush',
        flip: true,
        sticky: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            keepTogether: true,
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState('backend', ['trekBrandNavURL']),
    ...mapState('pdp', ['isElectraBrand']),
    ...mapState('miniCart', ['cart', 'isLoading']),
    ...mapState('user', ['selectedRetailer', 'selectedLocation']),
    items() {
      return this.cart?.entries ?? [];
    },
    totalItems() {
      return this.items.reduce((total, entry) => total + entry.quantity, 0);
    },
    totalPrice() {
      if (this.appliedOrderPromotions.length || this.appliedProductPromotions.length) {
        if (this.items.length) {
          return numberFormatter.formatPrice(this.cart?.totalPrice.value);
        }
        return numberFormatter.formatPrice(this.cart?.totalPriceWithoutCharges?.value);
      }

      return numberFormatter.formatPrice(this.cart?.itemGrossPrice.value);
    },
    subTotalPrice() {
      return numberFormatter.formatPrice(this.cart?.itemGrossPrice?.value);
    },
    totalDiscounts() {
      return numberFormatter.formatPrice(this.cart?.totalDiscounts?.value);
    },
    appliedOrderPromotions() {
      return this?.cart?.appliedOrderPromotions ?? [];
    },
    potentialOrderPromotions() {
      return this?.cart?.potentialOrderPromotions ?? [];
    },
    appliedProductPromotions() {
      return this?.cart?.appliedProductPromotions ?? [];
    },
    potentialProductPromotions() {
      return this?.cart?.potentialProductPromotions ?? [];
    },
    hasVatTax() {
      return this?.cart?.displayTaxB2CEnum === 'VAT';
    },
    cartTaxesValue() {
      const cartTaxes = this?.cart?.cartTaxes;
      return cartTaxes?.value > 0 ? numberFormatter.formatPrice(cartTaxes.value) : this.$t('text.free');
    },
    cartNetTotalValue() {
      const cartNetTotal = this?.cart?.cartNetTotal;
      return cartNetTotal?.value > 0 ? numberFormatter.formatPrice(cartNetTotal.value) : this.$t('text.free');
    },
  },
  mounted() {
    this.fetchMiniCart();
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.closeMiniCartOnEsc);
  },
  methods: {
    ...mapActions('miniCart', ['fetchMiniCart']),

    addTooltipEventListener() {
      document.addEventListener('keyup', this.closeTooltip);
    },

    closeTooltip(event) {
      const miniCartButton = this.$refs['miniCartButton'];
      if (event.key === 'Escape' && miniCartButton?._tippy) {
        miniCartButton._tippy.hide();
        document.removeEventListener('keyup', this.closeTooltip);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.b2c-mini-cart {
  max-height: 75vh;
  width: 28rem;
  max-width: 100%;
}
</style>
