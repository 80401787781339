import ar from './locales/ar.js';
import az from './locales/az.js';
import bg from './locales/bg.js';
import ca from './locales/ca.js';
import cs from './locales/cs.js';
import da from './locales/da.js';
import de from './locales/de.js';
import el from './locales/el.js';
import en from './locales/en.js';
import es from './locales/es.js';
import et from './locales/et.js';
import eu from './locales/eu.js';
import fa from './locales/fa.js';
import fi from './locales/fi.js';
import fr from './locales/fr.js';
import he from './locales/he.js';
import hi from './locales/hi.js';
import hr from './locales/hr.js';
import hu from './locales/hu.js';
import id from './locales/id.js';
import it from './locales/it.js';
import ja from './locales/ja.js';
import ka from './locales/ka.js';
import ko from './locales/ko.js';
import lt from './locales/lt.js';
import lv from './locales/lv.js';
import mn from './locales/mn.js';
import ms_MY from './locales/ms_MY.js';
import nb_NO from './locales/nb_NO.js';
import ne from './locales/ne.js';
import nl from './locales/nl.js';
import nn_NO from './locales/nn_NO.js';
import pl from './locales/pl.js';
import pt_BR from './locales/pt_BR.js';
import pt_PT from './locales/pt_PT.js';
import ro from './locales/ro.js';
import ru from './locales/ru.js';
import sk from './locales/sk.js';
import sl from './locales/sl.js';
import sq from './locales/sq.js';
import sr_Latin from './locales/sr_Latin.js';
import sr from './locales/sr.js';
import sv from './locales/sv.js';
import th from './locales/th.js';
import tr from './locales/tr.js';
import uk from './locales/uk.js';
import vi from './locales/vi.js';
import zh_CN from './locales/zh_CN.js';
import zh_TW from './locales/zh_TW.js';
export default {
  ar,
  az,
  bg,
  ca,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  eu,
  fa,
  fi,
  fr,
  he,
  hi,
  hr,
  hu,
  id,
  it,
  ja,
  ka,
  ko,
  lt,
  lv,
  mn,
  ms_MY,
  nb_NO,
  ne,
  nl,
  nn_NO,
  pl,
  pt_BR,
  pt_PT,
  ro,
  ru,
  sk,
  sl,
  sq,
  sr_Latin,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  zh_CN,
  zh_TW,
  default: en,
};
