import Vue from 'vue';

const rcpRouteList = {
  namespaced: true,
  state: {
    pendingFileUploads: [],
    openRideTab: false,
  },
  mutations: {
    addPendingFileUpload(state, payload) {
      state.pendingFileUploads.push(payload);
    },
    removePendingFileUploads(state, payload) {
      const filteredUploads = state.pendingFileUploads.filter(
        (upload) => payload.findIndex((rideName) => rideName === upload.name) === -1
      );
      Vue.set(state, 'pendingFileUploads', filteredUploads);
    },
    setOpenRideTab(state, payload) {
      state.openRideTab = payload;
    },
  },
};

export default rcpRouteList;
