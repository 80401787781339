const staticNotifications = [
  {
    id: 1234,
    read: false,
    subject: 'Announcement',
    text: 'Stop, look and read the latest NEWS!',
    dateReceived: '2018-06-13T03:36:31.8641',
  },
  {
    id: 1235,
    read: false,
    subject: '',
    text: 'Product and SKU ETA has changed. Click here to view the update',
    dateReceived: '2018-06-18T03:36:33.8643',
  },
  {
    id: 1236,
    read: false,
    subject: 'Warranty Claim',
    text: 'Warranty claim number requires attention.  Failure to provide Trek with the required information within 14 days will result in termination of the claim.',
    dateReceived: '2018-06-17T03:36:32.8642',
  },
  {
    id: 1237,
    read: false,
    subject: 'Announcement',
    text: 'Stop, look and read the latest NEWS!',
    dateReceived: '2018-06-13T03:36:31.8641',
  },
  {
    id: 1238,
    read: false,
    subject: '',
    text: 'Product and SKU ETA has changed. Click here to view the update',
    dateReceived: '2018-06-18T03:36:33.8643',
  },
  {
    id: 1239,
    read: true,
    subject: 'Warranty Claim',
    text: 'Warranty claim number requires attention.  Failure to provide Trek with the required information within 14 days will result in termination of the claim.',
    dateReceived: '2018-06-17T03:36:32.8642',
  },
];

export {staticNotifications};
