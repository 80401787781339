const bikeGarage = {
  namespaced: true,
  state: {
    bikes: [],
    bikeCategories: [],
    isLoading: false,
    currentRoute: '/',
    router: null,
    successMessage: null,
  },
  mutations: {
    setCurrentRoute(state, payload) {
      state.currentRoute = payload;
    },
    setRouter(state, payload) {
      state.router = payload;
    },
    addBike(state, payload) {
      state.bikes.push(payload);
    },
    setBikes(state, payload) {
      state.bikes = payload;
    },
    updateBikeName(state, payload) {
      let bikeToUpdate = state.bikes.find(({id}) => id === payload.id);
      bikeToUpdate.customerBikeName = payload.customerBikeName;
    },
    setBikeCategories(state, payload) {
      state.bikeCategories = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    addSuccessMessage(state, payload) {
      state.successMessage = payload;
    },
    clearSuccessMessage(state) {
      state.successMessage = null;
    },
  },
  getters: {
    bikeCategoriesFormattedForSelect(state) {
      let formattedBikeCategories = [];
      state.bikeCategories.forEach((category) => {
        const formattedCategory = {
          displayName: category.categoryName,
          value: category.categoryCode,
        };
        formattedBikeCategories.push(formattedCategory);
      });
      return formattedBikeCategories;
    },
    bikeCategoryMapById(state) {
      const categoryMapping = {};
      state.bikeCategories.forEach((category) => {
        categoryMapping[category.categoryCode] = category.categoryName;
      });
      return categoryMapping;
    },
  },
};

export default bikeGarage;
