import occInstance from '@/api/instances/occ';

const DEFAULT_OPTIONS = {
  radius: 100000,
  fields: 'FULL',
  pageSize: 1,
};

const RetailerApi = {
  async getRetailers(options = DEFAULT_OPTIONS) {
    const baseUrl = `/stores`;
    const mergedOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    const params = new URLSearchParams(mergedOptions).toString();
    const url = baseUrl + (params.length ? `?${params}` : '');
    return occInstance.get(url);
  },

  async getNearestRetailer({latitude, longitude}, radius = 10000) {
    if (latitude === undefined || longitude === undefined) {
      throw new Error('lat/long location data required');
    }

    const {data: retailerData} = await this.getRetailers({latitude, longitude, radius, ecommEnabled: true});

    if (retailerData.pagination?.totalResults < 1) {
      return this.getNearestRetailer({latitude, longitude}, radius * 2);
    }

    return retailerData.stores[0];
  },
  async getById(retailerId) {
    const url = `/stores/${retailerId}`;
    const retailer = (await occInstance.get(url)).data;
    return retailer;
  },
};

export default RetailerApi;
