<template>
  <div v-if="isEnabled" class="flex flex-col justify-center items-center">
    <div :id="buttonContainerId" :class="classes"></div>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
export default {
  name: 'LiveChat',
  props: {
    productCompareEnabledOnSite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonContainerId: '__8x8-chat-button-container',
      isEnabled: false,
    };
  },
  computed: {
    ...mapState('backend', ['isB2Bsite', 'language', 'currentB2BUnit', 'liveChatOnPage']),
    ...mapState('user', ['userEmail', 'userName']),
    ...mapGetters('compare', ['currentRack']),
    classes() {
      return {
        'live-chat-button': true,
        'rack-displayed': this.currentRack?.length && this.productCompareEnabledOnSite,
      };
    },
  },
  mounted() {
    //8x8 Chat config is set up on page in CMS
    if (this.liveChatOnPage) {
      this.isEnabled = true;
      window.customerInfo = {
        currentUserName: this.userName,
        currentUserEmail: this.userEmail,
        languageISO: this.language,
      };
      if (this.isB2Bsite) {
        window.customerInfo.currentB2BUnit = this.currentB2BUnit;
      }

      window.dispatchEvent(new CustomEvent('live-chat-customer-info-loaded'), window.customerInfo);
    }
  },
};
</script>
<style scoped lang="scss">
.live-chat-button {
  position: fixed;
  z-index: 16000002; //legacy value
  right: 0.5rem;
  bottom: 1rem;

  &.rack-displayed {
    bottom: 3.5rem;
  }
}
</style>
