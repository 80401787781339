const DefaultChallenge = Object.freeze({
  type: '',
  status: '',
  id: '',
  scope: '',
  basicInformation: {
    name: '',
    summary: '',
    startDate: '',
    endDate: '',
    associatedB2BUnits: [],
    publisherName: '',
    publisherImage: '',
    sponsorDetails: '',
    rulesAndEligibility: '',
    legalNotes: '',
    brandingContentAssetId: '',
    customHeaderAssetId: '',
  },
  searchScope: {
    type: '',
    locationPoint: {
      lat: 0,
      lng: 0,
      elevation: 0,
    },
    radius: 0,
    countryCodes: [],
  },
  reward: {
    skipped: true,
    awardAssetId: '',
    awardDescription: '',
    awardRedemptionInstructions: '',
  },
  goal: {
    routeId: '',
    numberOfRides: 0,
    minimumQualifyingDistance: 0,
    totalDistance: 0,
    totalElevation: 0,
    skippedThresholds: true,
    thresholds: [
      {
        percentage: 100,
        active: false,
      },
      {
        percentage: 90,
        active: false,
      },
      {
        percentage: 75,
        active: false,
      },
      {
        percentage: 50,
        active: false,
      },
      {
        percentage: 25,
        active: false,
      },
    ],
    sustainability: false,
  },
});

const ChallengeStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
});

const ChallengeType = Object.freeze({
  DISTANCE: 'DISTANCE',
  FREQUENCY: 'FREQUENCY',
  ELEVATION: 'ELEVATION',
  ROUTE: 'ROUTE',
});

const ChallengeStep = Object.freeze({
  TYPE: 'type',
  BASIC_INFO: 'basic',
  IDENTITY: 'identity',
  REWARD: 'reward',
  PREVIEW: 'preview',
  DISTANCE_GOAL: 'distanceGoal',
  FREQUENCY_GOAL: 'frequencyGoal',
  ELEVATION_GOAL: 'elevationGoal',
  ROUTE: 'route',
  ROUTE_DESCRIPTION: 'description',
});

const ChallengeListColumnKeys = Object.freeze({
  NAME: 'name',
  STATUS: 'status',
  TYPE: 'type',
  SUSTAINABILITY: 'sustainability',
  DATE: 'date',
  JOINED: 'joined',
  COMPLETED: 'completed',
  ACTIONS: 'actions',
});

const ChallengeBadgeImages = Object.freeze({
  [ChallengeType.DISTANCE]: 'RideClub-RideMoreMiles-Badge',
  [ChallengeType.FREQUENCY]: 'RideClub-RideMoreOften-Badge',
  [ChallengeType.ROUTE]: 'RideClub-RideTheRoute-Badge',
  [ChallengeType.ELEVATION]: 'RideClub-RideMoreElevation-Badge',
});

const ChallengeSustainableBadgeImages = Object.freeze({
  [ChallengeType.DISTANCE]: 'RideClub-RideMoreMiles-Sustainability-Badge',
  [ChallengeType.FREQUENCY]: 'RideClub-RideMoreOften-Sustainability-Badge',
  [ChallengeType.ROUTE]: 'RideClub-RideTheRoute-Sustainability-Badge',
});

const ChallengeTypeBaseProperties = Object.freeze({
  [ChallengeType.DISTANCE]: 'rcp.challenges.listing.type.distance',
  [ChallengeType.FREQUENCY]: 'rcp.challenges.frequency',
  [ChallengeType.ELEVATION]: 'rcp.challenges.listing.type.elevation',
  [ChallengeType.ROUTE]: 'rcp.route',
});

const ChallengeDataColumnKeys = Object.freeze({
  NAME: 'displayName',
  EMAIL: 'email',
  COMPLETED: 'completed',
  PERCENT_COMPLETED: 'completePercentage',
  DISTANCE_RIDDEN: 'distanceDuringChallenge',
  ELEVATION_GAIN: 'elevationGain',
  QUALIFYING_RIDES: 'qualifyingRides',
});

const ChallengeDataExportStatus = Object.freeze({
  Finished: 'FINISHED',
});

const ChallengeAudience = Object.freeze({
  LOCATION: 'LOCATION',
  COUNTRY: 'COUNTRY',
});

export {
  DefaultChallenge,
  ChallengeStatus,
  ChallengeType,
  ChallengeStep,
  ChallengeListColumnKeys,
  ChallengeBadgeImages,
  ChallengeSustainableBadgeImages,
  ChallengeTypeBaseProperties,
  ChallengeDataColumnKeys,
  ChallengeDataExportStatus,
  ChallengeAudience,
};
