import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import find from 'lodash/find';
import {PdlCallout} from '@pedal/pdl-callout';
/**
 * Applies arguments ({0}, {1}, etc) to a string.
 * @param label
 * @param args
 * @return {*}
 */
const applyArguments = (label, args) => {
  if (!label || !args || !args.length) {
    return label;
  }
  args.forEach((value, index) => {
    label = label.replace(`{${index}}`, value);
  });
  return label;
};
export default {
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
  },
  components: {PdlCallout},
  created() {
    if (this.$attrs['router-base-properties'] && this.$attrs['router-base-properties'].length) {
      this.bpData = this.$attrs['router-base-properties'];
    } else if (this.baseProperties && this.baseProperties.length) {
      this.bpData = this.baseProperties;
    }
  },
  data() {
    return {
      bpData: [],
    };
  },
  computed: {
    baseProps() {
      const obj = keyBy(this.bpData, 'name');
      return mapValues(obj, (val) => val.label);
    },
  },
  methods: {
    bp(name, ...args) {
      if (isEmpty(this.baseProps)) {
        return null;
      }
      return name in this.baseProps ? applyArguments(this.baseProps[name], args) : '';
    },
    getBaseProperty(name, ...args) {
      if (!this.bpData || !this.bpData.length) {
        return null;
      }
      const baseProperty = find(this.bpData, (baseProperty) => baseProperty.name === name);
      return baseProperty ? applyArguments(baseProperty.label, args) : '';
    },
  },
};
