const cache = new Map();
const queue = new Map();

/**
 * Process queue of requests.
 * @param keyName
 * @param data
 */
const processQueue = (keyName, data) => {
  const q = queue.get(keyName);
  if (q && q.length) {
    q.forEach((resolver) => {
      resolver(data);
    });
  }
  queue.delete(keyName);
};

/**
 * Executes promise and caches returned result. Returns cached result via promise if operation repeats.
 * @param keyName
 * @param executor
 * @return {*}
 */
const fetchAndCache = (keyName, executor) =>
  new Promise((resolve) => {
    if (!keyName || !executor) {
      resolve(null);
    } else if (cache.has(keyName)) {
      resolve(cache.get(keyName));
    } else {
      if (!queue.has(keyName)) {
        ('then' in executor ? executor : executor()).then((data) => {
          cache.set(keyName, data);
          processQueue(keyName, data);
          resolve(data);
        });
        queue.set(keyName, []);
      } else {
        queue.set(keyName, [...queue.get(keyName), resolve]);
      }
    }
  });

export {fetchAndCache};
