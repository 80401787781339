var r = function (e, n, i, o) {
  return 1 === (o = Math.abs(o)) ? e : 2 <= o % 10 && o % 10 <= 4 && (o % 100 < 10 || 20 <= o % 100) ? n : i;
}.bind(null, 'znak', 'znaki', 'znaków');
export default {
  _default: function (e) {
    return 'Pole ' + e + ' jest nieprawidłowe';
  },
  after: function (e, n) {
    return 'Pole ' + e + ' musi być po polu ' + n[0];
  },
  alpha: function (e) {
    return 'Pole ' + e + ' może zawierać tylko litery';
  },
  alpha_dash: function (e) {
    return 'Pole ' + e + ' może zawierać litery, cyfry oraz myślnik lub podkreślnik';
  },
  alpha_num: function (e) {
    return 'Pole ' + e + ' może zawierać tylko litery i cyfry';
  },
  alpha_spaces: function (e) {
    return 'Pole ' + e + ' może zawierać tylko litery oraz spacje';
  },
  before: function (e, n) {
    return 'Pole ' + e + ' musi być przed ' + n[0];
  },
  between: function (e, n) {
    return 'Pole ' + e + ' musi być pomiędzy ' + n[0] + ' oraz ' + n[1];
  },
  confirmed: function (e, n) {
    return 'Pole ' + e + ' nie zgadza się z polem potwierdzającym ' + n[0];
  },
  credit_card: function (e) {
    return 'Pole ' + e + ' musi być poprawnym numerem karty kredytowej';
  },
  date_between: function (e, n) {
    return 'Pole ' + e + ' musi zawierać się między ' + n[0] + ' a ' + n[1];
  },
  date_format: function (e, n) {
    return 'Pole ' + e + ' musi pasować do formatu ' + n[0];
  },
  decimal: function (e, n) {
    void 0 === n && (n = []);
    var i = n[0];
    return (
      void 0 === i && (i = '*'),
      'Pole ' + e + ' musi być liczbą i może zawierać' + (i && '*' !== i ? ' ' + i : '') + ' miejsca po przecinku'
    );
  },
  digits: function (e, n) {
    return 'Pole ' + e + ' musi być liczbą i dokładnie ' + n[0] + ' cyfr';
  },
  dimensions: function (e, n) {
    return 'Obraz ' + e + ' musi być szeroki na ' + n[0] + ' pikseli i wysoki na ' + n[1] + ' pikseli';
  },
  email: function (e) {
    return 'Pole ' + e + ' musi być poprawnym adresem email';
  },
  excluded: function (e) {
    return 'Pole ' + e + ' musi być poprawną wartością';
  },
  ext: function (e) {
    return 'Plik ' + e + ' musi być poprawnym plikiem';
  },
  image: function (e) {
    return 'Pole ' + e + ' musi być obrazem';
  },
  included: function (e) {
    return 'Pole ' + e + ' musi być poprawną wartością';
  },
  integer: function (e) {
    return 'Pole ' + e + ' musi być liczbą całkowitą';
  },
  ip: function (e) {
    return 'Pole ' + e + ' musi być poprawnym adresem IP';
  },
  length: function (e, n) {
    var i = n[0],
      o = n[1];
    return o
      ? 'Pole ' + e + ' musi mieć długość od ' + i + ' do ' + o + ' znaków'
      : 'Pole ' + e + ' musi mieć długość ' + i + ' ' + r(i);
  },
  max: function (e, n) {
    var i = n[0];
    return 'Pole ' + e + ' nie może być dłuższe niż ' + i + ' ' + r(i);
  },
  max_value: function (e, n) {
    return 'Pole ' + e + ' musi mieć maksymalną wartość ' + n[0];
  },
  mimes: function (e) {
    return 'Plik ' + e + ' musi posiadać poprawne rozszerzenie';
  },
  min: function (e, n) {
    var i = n[0];
    return 'Pole ' + e + ' musi być długie na co najmniej ' + i + ' ' + r(i);
  },
  min_value: function (e, n) {
    return 'Pole ' + e + ' musi mieć minimalną wartość ' + n[0];
  },
  numeric: function (e) {
    return 'Pole ' + e + ' może zawierać tylko cyfry';
  },
  regex: function (e) {
    return 'Format pola ' + e + ' jest nieodpowiedni';
  },
  required: function (e) {
    return 'Pole ' + e + ' jest wymagane';
  },
  size: function (e, n) {
    return (
      'Plik ' +
      e +
      ' musi być mniejszy niż ' +
      (function (e) {
        var n = 1024,
          i = 0 === (e = Number(e) * n) ? 0 : Math.floor(Math.log(e) / Math.log(n));
        return 1 * (e / Math.pow(n, i)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i];
      })(n[0])
    );
  },
  url: function (e) {
    return 'Pole ' + e + ' nie jest poprawnym URL';
  },
};
