<template>
  <picture>
    <template v-if="isCarousel">
      <source
        v-for="(width, key, index) in breakpoints"
        :key="index"
        :data-srcset="srcset[index]"
        :class="[cssClass, {'owl-lazy': isCarousel}]"
        :media="`(max-width: ${width}px)`"
      />
    </template>
    <img
      :id="elementId"
      :class="[cssClass, {lazyload: !isCarousel, 'owl-lazy': isCarousel}]"
      :data-srcset="srcset.join(',')"
      :data-src="getSrc"
      :alt="altText"
      :data-fit-object="isDataObjectFit"
      :data-asset-id="trimmedAssetId"
      :data-sizes="sizes"
      :srcset="srcset.join(',')"
    />
  </picture>
</template>

<script>
import {Cloudinary, CloudinaryConstants} from '@/utils/image/cloudinary-helpers';

export default {
  name: 'ImageComponent',
  props: {
    isCarousel: {
      type: Boolean,
      default: false,
    },
    isDataObjectFit: {
      type: Boolean,
      default: false,
    },
    elementId: {
      type: String,
      default: '',
    },
    altText: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    sizes: {
      type: String,
      default: '',
    },
    cssClass: {
      type: [Object, String, Array],
      default: () => '',
    },
    imgFormat: {
      type: String,
      default: '',
    },
    transformation: {
      type: String,
      default: '',
      validator: (val) => ['', 'pad', 'fill', 'limit', 'scale'].includes(val),
    },
    assetId: {
      type: String,
      default: '',
    },
    aspectRatio: {
      type: String,
      default: '',
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
    breakpointMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      breakpoints: {
        xsWidth: 480,
        smWidth: 640,
        mdWidth: 768,
        mlWidth: 1024,
        lgWidth: 1200,
        xlWidth: 1440,
        xxlWidth: 1920,
      },
    };
  },
  computed: {
    srcset() {
      if (!this.isResponsive) return [];
      return Object.entries(this.breakpoints).map(([key, breakpointWidth]) => {
        const width = this.breakpointMap?.[key] ?? breakpointWidth;
        return this.getImageUrl(width);
      });
    },
    calculatedAspectRatio() {
      return this.aspectRatio ? this.aspectRatio.split('x')[1] / this.aspectRatio.split('x')[0] : undefined;
    },
    trimmedAssetId() {
      //check for full URL vs assetId
      const urlRegex = new RegExp(
        /(http(s)?:\/\/.)?(\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.,~#?&//=]*)/
      );
      if (!urlRegex.test(this.assetId)) return this.assetId;
      return this.trimUrl(this.assetId);
    },
    getSrc() {
      if (!this.isResponsive) return this.src;
      return this.getImageUrl(this.breakpoints.xxlWidth);
    },
  },
  methods: {
    getImageUrl(width) {
      if (!this.trimmedAssetId) return;
      const height = this.calculatedAspectRatio ? Math.floor(width * this.calculatedAspectRatio) : undefined;
      const transformations = {
        w: width,
        ...(height && {h: height}),
        ...(this.transformation && {c: this.transformation}),
        ...CloudinaryConstants.DEFAULT_PARAMETERS,
      };

      return `${Cloudinary.createAssetUrl(this.trimmedAssetId, transformations)} ${width}w`;
    },
    trimUrl(url) {
      //this is a hack to get around the fact that the assetId might be a full url.
      //Cloudinary urls follow the format /image/upload/<transformations>/<assetId>
      //however the transformations are optional.
      let trimmedAssetId = url.split('/');
      return trimmedAssetId[trimmedAssetId.length - 1];
    },
  },
};
</script>
