const addUpWaypointCumulativeDistances = (waypoints) => {
  let runningTotalDistance = 0;
  let distanceToNextCue = 0;
  let waypointsCopy = structuredClone(waypoints);
  for (let waypoint of waypointsCopy) {
    if (!waypoint.snapTo) {
      runningTotalDistance += waypoint.distance;
    }
    for (let cue of waypoint.cues) {
      runningTotalDistance += distanceToNextCue;
      cue.displayDistance = runningTotalDistance;
      distanceToNextCue = cue.distance;
    }
  }
  return waypointsCopy;
};

const finalizeCueSheetList = (activeCuesList, totalRouteDistance) => {
  const departCue = {
    maneuver: 'depart',
    maneuverImage: 'location_on',
    displayDistance: 0.0,
    description: window.vm.$t('rcp.routeBuilder.cueSheet.startRoute'),
  };
  const arriveCue = {
    maneuver: 'arrive',
    maneuverImage: 'location_on',
    displayDistance: totalRouteDistance,
    description: window.vm.$t('rcp.routeBuilder.cueSheet.endRoute'),
  };

  let finalizedCueList = [];
  if (activeCuesList.length > 0) {
    finalizedCueList = [departCue, ...activeCuesList, arriveCue];
  } else if (activeCuesList.length === 0 && totalRouteDistance > 0) {
    finalizedCueList = [departCue, arriveCue];
  }
  return finalizedCueList;
};

const routeSegmentsDistance = (routeSegments) => {
  let routeDistance = 0;
  routeSegments.forEach((route) => {
    const cues = route.value?.cues;
    if (cues) {
      routeDistance += cues?.reduce((accumulator, cue) => accumulator + cue.distance, 0);
    } else {
      routeDistance += route.value?.distance;
    }
  });
  return routeDistance;
};

export {addUpWaypointCumulativeDistances, finalizeCueSheetList, routeSegmentsDistance};
