<script>
import headTag from '@/utils/head-tag';

export default {
  name: 'RecaptchaTokenScript',
  props: {
    reCaptchaSiteKey: {
      type: String,
      required: true,
    },
    reCaptchaTokenToggle: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.init();
    this.appendScript();
    this.loadToken();
  },
  methods: {
    init() {
      window.tk_gen = window.tk_gen || {};
      window.tk_gen.reCaptchaSiteKey = this.reCaptchaSiteKey;
      window.tk_gen.reCaptchaTokenToggle = this.reCaptchaTokenToggle;
    },
    appendScript() {
      const reCaptchaTokenScriptId = 'contact-us-captcha';
      const reCaptchaTokenScript = `https://www.google.com/recaptcha/api.js?render=${this.reCaptchaSiteKey}`;

      headTag.appendScript(reCaptchaTokenScriptId, reCaptchaTokenScript);
    },
    loadToken() {
      if (!window.grecaptcha) {
        return;
      }

      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(window.tk_gen.reCaptchaSiteKey, {action: 'ecommerce'}).then(function (token) {
          window.tk_gen.reCaptchaToken = token;
        });
      });
    },
  },
};
</script>
