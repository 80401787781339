import {mapState} from 'vuex';

const trackGlobalEvents = {
  mounted() {
    this.trackRegisteredUser();
    this.trackPageCategory();
  },

  computed: {
    ...mapState('backend', ['pageCategory', 'isUserLoggedIn']),

    /**
     * Computed mapping between hybris page categories and analytics values.
     * Categories can be added on the cms side to further expand and paths can be
     * added to the paths array.
     * @returns <String> mapped value
     */
    mappedPageCategory() {
      const pathName = window.location.pathname;
      const categories = {
        product: 'PDP',
        category: 'PLP',
        elp: 'ELP',
      };

      const paths = ['/store/', '/retail/'];
      const check = paths.some((path) => pathName.indexOf(path) !== -1);
      return check ? categories['elp'] : categories[this.pageCategory.toLowerCase()];
    },
  },

  methods: {
    trackRegisteredUser() {
      const trackRegisteredUserData = {
        event: 'registered-user',
        member: this.isUserLoggedIn,
      };

      ACC.track.trackEvent(trackRegisteredUserData);
    },

    trackPageCategory() {
      const trackPageCategoryData = {
        event: 'page',
        type: this.mappedPageCategory,
      };

      ACC.track.trackEvent(trackPageCategoryData);
    },
  },
};

export default trackGlobalEvents;
