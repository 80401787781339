import {ExpressCheckoutAddressTemplate} from '@/constants/express-checkout.js';
import store from '@/store';
import {cartActions} from '@/store/modules/cart.js';
import {getRegion} from '@/components/mapbox/utils/mapbox-api.js';

/**
 * Formats the cart address object for posting a final address to the cart.
 * @param {Object} address
 * @returns {Object} to update paysheet.
 */
const expressCartFinalAddress = async (address) => {
  let region = '';
  try {
    const result = await getRegion({
      search: [address.postalCode, address.administrativeArea, address.locality].join(' '),
      accessToken: store.state.backend.mapboxApiKey,
      country: store.state.backend.geolocation.countryCode,
    });
    region = result?.isocode;
  } catch (error) {
    region = {isocode: `${address?.countryCode}-${address?.administrativeArea}`};
  }
  const cartAddress = {
    ...ExpressCheckoutAddressTemplate,
    firstName: address?.givenName,
    lastName: address?.familyName,
    line1: address?.addressLines[0],
    line2: address?.addressLines?.[1] ?? '',
    town: address?.locality,
    postalCode: address?.postalCode,
    phone: address?.phoneNumber,
    email: address?.emailAddress ?? '',
    region: region,
    country: {
      isocode: address?.countryCode,
    },
  };
  return cartAddress;
};

/**
 * Handles the updating of the customers address to get shipping costs dynamically.
 * @param {Object} shippingAddress
 * @param {String} deliveryModeId
 * @param {String} dealerCode
 * @returns {Object} to update paysheet.
 */
const expressCartShippingUpdates = async (shippingAddress = {}, deliveryModeId = '', dealerCode = null) => {
  const setupAddress = {
    ...ExpressCheckoutAddressTemplate,
    postalCode: shippingAddress?.postalCode,
    town: shippingAddress?.city,
    region: {
      isocodeShort: shippingAddress?.region,
      countryIso: shippingAddress?.country,
      isocode: `${shippingAddress?.country}-${shippingAddress?.region}`,
    },
    country: {
      isocode: shippingAddress?.country,
    },
  };

  await store.dispatch(cartActions.UPDATE_CART_ADDRESS, setupAddress);

  if (deliveryModeId) {
    await store.dispatch(cartActions.UPDATE_CART_DEALER_AND_MODE, {
      dealerCode,
      deliveryModeId,
    });
  }

  const cart = await store.dispatch(cartActions.GET_CART_DATA);

  if (!cart) return;

  const paymentDetails = {
    itemGrossPrice: {currencyIso: cart.itemGrossPrice.currencyIso, value: cart.itemGrossPrice.value},
    deliveryMode: {name: cart.deliveryMode.name},
    shippingGrossPrice: {
      currencyIso: cart.deliveryMode.deliveryCost.currencyIso,
      value: cart.deliveryMode.deliveryCost.value,
    },
    totalTax: {currencyIso: cart.totalTax.currencyIso, value: cart.totalTax.value},
    totalPriceWithTax: {currencyIso: cart.totalPriceWithTax.currencyIso, value: cart.totalPriceWithTax.value},
  };
  return paymentDetails;
};

/**
 * Handles the init setup addresses.
 * @param {Object} address
 * @returns {Object} setup address object.
 */
const expressSetupCartAddress = (address) => {
  return {
    ...ExpressCheckoutAddressTemplate,
    ...(address?.line1 && {line1: address?.line1}),
    ...(address?.line2 && {line2: address?.line2}),
    town: address?.town,
    postalCode: address?.postalCode,
    region: {
      isocodeShort: address?.region?.isocodeShort,
      countryIso: address?.country?.isocode,
      isocode: address?.region?.isocode,
    },
    country: {
      isocode: address?.country?.isocode,
    },
  };
};

export {expressCartFinalAddress, expressCartShippingUpdates, expressSetupCartAddress};
