export default {
  _default: function (e) {
    return 'Il valore del campo ' + e + ' non è valido';
  },
  after: function (e, n) {
    return 'Il ' + e + ' deve essere dopo ' + n[0];
  },
  alpha: function (e) {
    return 'Il campo ' + e + ' può contenere solo caratteri alfabetici';
  },
  alpha_dash: function (e) {
    return 'Il campo ' + e + ' può contenere caratteri alfa-numerici così come lineette e trattini di sottolineatura';
  },
  alpha_num: function (e) {
    return 'Il campo ' + e + ' può contenere solo caratteri alfanumerici';
  },
  alpha_spaces: function (e) {
    return 'Il campo ' + e + ' può contenere solo caratteri alfanumerici così come spazi';
  },
  before: function (e, n) {
    return 'Il campo ' + e + ' deve essere prima di ' + n[0];
  },
  between: function (e, n) {
    return 'Il campo ' + e + ' deve essere compreso tra ' + n[0] + ' e ' + n[1];
  },
  confirmed: function (e, n) {
    return 'Il campo ' + e + ' non corrisponde con ' + n[0];
  },
  credit_card: function (e) {
    return 'Il campo ' + e + ' non è valido';
  },
  date_between: function (e, n) {
    return 'La ' + e + ' deve essere compresa tra ' + n[0] + ' e ' + n[1];
  },
  date_format: function (e, n) {
    return 'La ' + e + ' deve essere nel formato ' + n[0];
  },
  decimal: function (e, n) {
    void 0 === n && (n = []);
    var r = n[0];
    return (
      void 0 === r && (r = '*'),
      'Il campo ' + e + ' deve essere numerico e può contenere' + (r && '*' !== r ? ' ' + r : '') + ' punti decimali'
    );
  },
  digits: function (e, n) {
    return 'Il campo ' + e + ' deve essere numerico e contenere esattamente ' + n[0] + ' cifre';
  },
  dimensions: function (e, n) {
    return 'Il campo ' + e + ' deve essere ' + n[0] + ' x ' + n[1];
  },
  email: function (e) {
    return 'Il campo ' + e + ' deve essere un indirizzo email valido';
  },
  excluded: function (e) {
    return 'Il campo ' + e + ' deve avere un valore valido';
  },
  ext: function (e) {
    return 'Il campo ' + e + ' deve essere un file valido';
  },
  image: function (e) {
    return 'Il campo ' + e + " deve essere un'immagine";
  },
  included: function (e) {
    return 'Il campo ' + e + ' deve avere un valore valido';
  },
  ip: function (e) {
    return 'Il campo ' + e + ' deve essere un indirizzo IP valido';
  },
  length: function (e, n) {
    var r = n[0],
      o = n[1];
    return o
      ? 'La lunghezza del campo ' + e + ' deve essere compreso tra ' + r + ' e ' + o
      : 'La lunghezza del campo ' + e + ' deve essere ' + r;
  },
  max: function (e, n) {
    return 'Il campo ' + e + ' non può essere più lungo di ' + n[0] + ' caratteri';
  },
  max_value: function (e, n) {
    return 'Il campo ' + e + ' deve essere minore o uguale a ' + n[0];
  },
  mimes: function (e) {
    return 'Il campo ' + e + ' deve avere un tipo di file valido';
  },
  min: function (e, n) {
    return 'Il campo ' + e + ' deve avere almeno ' + n[0] + ' caratteri';
  },
  min_value: function (e, n) {
    return 'Il campo ' + e + ' deve essere maggiore o uguale a ' + n[0];
  },
  numeric: function (e) {
    return 'Il campo ' + e + ' può contenere solo caratteri numerici';
  },
  regex: function (e) {
    return 'Il campo ' + e + ' non ha un formato valido';
  },
  required: function (e) {
    return 'Il campo ' + e + ' è richiesto';
  },
  size: function (e, n) {
    return (
      'Il campo ' +
      e +
      ' deve essere inferiore a ' +
      (function (e) {
        var n = 1024,
          r = 0 === (e = Number(e) * n) ? 0 : Math.floor(Math.log(e) / Math.log(n));
        return 1 * (e / Math.pow(n, r)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][r];
      })(n[0])
    );
  },
  url: function (e) {
    return 'Il campo ' + e + ' non è un URL valido';
  },
};
