/**
 * Necessary evil for current temporary solution for critical CSS.
 */
const nonCriticalCssLoader = () => {
  window.loadNonCriticalCSS = (nonCriticalCSSPath) => {
    // Create CSS link tag to be inserted in head
    let nonCriticalCSSLink = document.createElement('link');
    nonCriticalCSSLink.rel = 'stylesheet';
    nonCriticalCSSLink.href = nonCriticalCSSPath;
    nonCriticalCSSLink.type = 'text/css';

    nonCriticalCSSLink.onload = () => {
      // Remove critical CSS after link has loaded
      let criticalCSSElement = $('#criticalCSS');
      if (criticalCSSElement) {
        criticalCSSElement.remove();
      }

      // Used by automation to determine when to start testing after page has stabilized
      window.isCSSLoaded = true;
    };

    // Find the critical css (located at the head) and insert the link tag right next to it
    let criticalCSSElement = document.getElementById('criticalCSS');
    criticalCSSElement.insertAdjacentElement('afterend', nonCriticalCSSLink);

    // Ensures the owl carousel displays correctly after noncritical css loads.
    if (window.sliderRefs !== undefined && window.sliderRefs.length) {
      window.sliderRefs.forEach(function (slider) {
        $(slider).trigger('to.owl.carousel', 0);
      });
    }
  };
};

export {nonCriticalCssLoader};
