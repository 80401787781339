<script>
import storefrontInstance from '@/api/instances/storefront';

export default {
  name: 'StoreDetailsScript',

  mounted() {
    window.preferred = function (url) {
      storefrontInstance.defaults.baseURL = '';
      storefrontInstance.post(url).catch();
    };
    window.displayPreferredRetailer = function () {
      document.getElementById('pref-retailer-display').closest('li').style.display = 'block';
      document.getElementById('pref-retailer-cta').style.display = 'none';
    };
    window.displayPreferredRetailerELP = function () {
      document.getElementById('store-finder-set-preferred').classList.remove('button--tertiary');
      document.getElementById('store-finder-set-preferred').classList.add('button--success');
      window.document.getElementById('store-finder-set-preferred').childNodes[0].innerHTML = 'check';
    };
  },
};
</script>
