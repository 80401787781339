'use strict';

export default function () {
  function fadeInLoadingAnim(bindTo) {
    var container = bindTo;
    if (container !== undefined) {
      container.append($('#loading-animation').detach());
      $('#loading-animation').addClass('show');
    } else {
      $('body').append($('#loading-animation').detach());
      $('#loading-animation').addClass('show body');
    }
  }

  function fadeOutLoadingAnim() {
    $('body').append($('#loading-animation').detach());
    $('#loading-animation').removeClass('show body');
  }

  return {hide: fadeOutLoadingAnim, show: fadeInLoadingAnim};
}
