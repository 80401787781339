import occInstance from '@/api/instances/occ';
import {ApplePayStatus} from '@/constants/apple-pay';

const ApplePayApi = {
  async validateMerchant(config) {
    const paymentSession = (await occInstance.post(`/payment/apple/authorize`, config)).data;
    return paymentSession;
  },
};

const ApplePayService = {
  async getPaymentSession(config) {
    try {
      const paymentSession = await ApplePayApi.validateMerchant(config);
      return paymentSession;
    } catch (error) {
      console.error(error);
      return ApplePayStatus.FAIL;
    }
  },
};

export {ApplePayService};
