const DistanceFormats = Object.freeze({
  MILES: 'mi',
  KM: 'km',
});
const DealersDefaultParams = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 10,
  FIELDS: 'DEALERS',
};
const ModesDefaultParams = {
  CURRENT_PAGE: 0,
  PAGE_SIZE: 10,
  FIELDS: 'DEFAULT',
};

const ShipToStoreMode = 'CLICK-AND-COLLECT-NO-BIKE';

export {DistanceFormats, DealersDefaultParams, ModesDefaultParams, ShipToStoreMode};
