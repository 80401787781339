<template>
  <div :qaid="`nav-tabs-${isCompact ? 'compact' : 'large'}-layout`" class="nav-tabs-layout px-2 pb-6 md:px-3 lg:px-0">
    <nav-tab
      v-for="tab in tabs"
      :id="tab.uid"
      :key="tab.uid"
      :text="tab.text"
      :mobile-sub-text="tab.mobileSubText"
      :url="tab.url"
      :target="tab.target"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import NavTab from '@/navigation/components/NavTab.vue';

export default {
  name: 'NavTabsLayout',
  components: {
    NavTab,
  },
  computed: {
    ...mapState('navigation', ['activeLink', 'activeTab', 'currentOverlay', 'overlays']),
    ...mapState('global', ['isCompact']),
    tabs() {
      return this.currentOverlay ? this.currentOverlay.tabs.filter((tab) => tab.visible === true) : null;
    },
  },
};
</script>
