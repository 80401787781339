<template>
  <pdl-dialog
    :visible="isDialogVisible"
    modal
    :title="$t('mobile.refLink.header')"
    qaid="ref-link-dialog"
    :z-index="16000011"
    :close-dialog-tool-tip="$t('popup.close')"
    @close="emitClose"
  >
    <p>{{ $t('mobile.refLink.message') }}</p>
    <template slot="footer">
      <div class="buttons buttons--right">
        <pdl-link button primary small :target="linkTarget" :href="externalLink">{{
          $t('checkout.multi.deliveryAddress.continue')
        }}</pdl-link>
        <pdl-button secondary small @click="emitClose">{{ $t('text.button.cancel') }}</pdl-button>
      </div>
    </template>
  </pdl-dialog>
</template>

<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
export default {
  components: {
    PdlDialog,
    PdlButton,
    PdlLink,
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      required: true,
    },
    externalLink: {
      type: String,
      required: true,
    },
    linkTarget: {
      type: String,
      required: true,
    },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
};
</script>
