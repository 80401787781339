<template>
  <component
    :is="element"
    :id="`${tabId}`"
    :qaid="`nav-tab-${activeLink}-${tabId}`"
    :class="[
      'nav-tab',
      {
        'is-active': isActive,
      },
    ]"
    :href="composeLink(url)"
    :role="url ? 'link' : 'button'"
    :target="setTarget(target)"
    @click="clickTab(id)"
  >
    <div
      :class="[
        'nav-tab__text',
        {
          'is-active': isActive && hasTabText,
        },
      ]"
      v-html="text"
    />
    <div v-if="mobileSubText" class="nav-tab__mobile-sub-text" v-html="mobileSubText" />
  </component>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import links from '../mixins/links';

export default {
  name: 'NavTab',
  mixins: [links],
  inject: ['idSuffix'],
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    mobileSubText: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      default: undefined,
    },
    target: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('navigation', ['activeLink', 'activeTab']),
    ...mapState('global', ['isCompact']),
    isLink() {
      return this.url;
    },
    element() {
      return this.isLink ? 'a' : 'button';
    },
    hasTabText() {
      return this.text.length > 0;
    },
    isActive() {
      return this.id === this.activeTab;
    },
    tabId() {
      return this.idSuffix ? `${this.id}${this.idSuffix}` : this.id;
    },
  },
  methods: {
    ...mapMutations('navigation', ['setActiveTab']),
    clickTab(text) {
      this.isLink ? null : this.setActiveTab(text);
    },
  },
};
</script>

<style scoped lang="scss">
.nav-tab {
  display: block;
  transition: opacity 0.25s ease-in-out;
  outline: 0;

  @include breakpoint(large) {
    opacity: 0.3;

    &.is-active,
    &:hover {
      opacity: 1;
    }
  }

  &__text {
    display: flex;

    @apply text-white;
    @apply leading-normal;
    @apply text-xl;
    @apply font-heading;

    @include breakpoint(large) {
      @apply font-heading;
      @apply leading-none;

      font-size: 1.5rem;

      &::before {
        content: '';
        border-left: 2px solid transparent;
        transition: margin 0.25s ease-in-out, border 0.25s ease-in-out;
      }

      &.is-active::before {
        border-left: 2px solid var(--nav-highlight-color);
        margin-right: 0.75rem;
      }
    }
  }

  &__mobile-sub-text {
    @apply text-gray-50;
    @apply text-sm;
    @apply font-medium;
    @apply leading-none;

    @include breakpoint(large) {
      display: none;
    }
  }

  & + .nav-tab {
    @apply mt-2;

    @include breakpoint(large) {
      @apply mt-3;
    }
  }
}
</style>
