import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';
import mockClaim from '../../data/claims';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';

const ROOT_URL = '/claims/';

const ClaimStatuses = Object.freeze({
  ACTION_REQUIRED: 'enumClaimStatusActionRequired',
  NEW: 'enumClaimStatusNew',
});

const warrantyClaims = {
  namespaced: true,

  state: {
    loading: true,
    saving: false,
    claimData: {},
    validators: [],
  },

  getters: {
    isActionRequiredStatus(state) {
      return state.claimData.status === ClaimStatuses.ACTION_REQUIRED;
    },
  },

  mutations: {
    setClaimData(state, claimData) {
      if (claimData?.claimLines) {
        claimData.claimLines.forEach((claimLine) => {
          if (!claimLine.warrantyPurchaseProofImages) claimLine.warrantyPurchaseProofImages = [];
          if (!claimLine.warrantyOptionalAttachments) claimLine.warrantyOptionalAttachments = [];
        });
        Vue.set(state, 'claimData', claimData);
      }
    },

    setLoading(state, val) {
      state.loading = val;
    },

    setSaving(state, val) {
      state.saving = val;
    },

    /**
     * Generic mutation to update a property on the claimData and trigger an autosave
     * @param {Object} state
     * @param {Object} payload - Destructured to propName and value
     * @param {string} payload.propName - The name of the property that will be mutated
     * @param {string} payload.value - The value to which the property will be set
     */
    updateClaimData(state, {propName, value}) {
      state.claimData[propName] = value;
    },

    updateClaimLineData(state, {claimLineId, propName, value}) {
      state.claimData.claimLines.find((item) => item.id === claimLineId)[propName] = value;
    },
    updateClaimLine(state, {index, value}) {
      Vue.set(state.claimData.claimLines, index, value);
    },
  },

  actions: {
    /**
     * Generic action to update a property on the claimData and trigger an autosave
     * @param {Object} context - Destructured to commit, dispatch, and state
     * @param {Object} payload - Destructured to propName and value
     * @param {string} payload.propName - The name of the property that will be mutated
     * @param {string} payload.value - The value to which the property will be set
     */
    updateProperty({commit, dispatch}, {propName, value}) {
      commit('updateClaimData', {propName, value});
      dispatch('saveClaim');
    },

    updateClaimLineProperty({commit, dispatch}, {claimLineId, propName, value}) {
      commit('updateClaimLineData', {claimLineId, propName, value});
      dispatch('saveClaim');
    },

    /**
     * Autosave claimData to the backend
     * @param {Object} payload
     */
    saveClaim({commit, state, getters}, options = {toast: false}) {
      // Destructure state to only the properties of it we need
      const {claimData} = state;

      if (state.saving || getters.isActionRequiredStatus) {
        return;
      }

      commit('setSaving', true);
      return storefrontInstance
        .put(ROOT_URL, claimData, {toast: options.toast})
        .then((response) => {
          commit('setClaimData', response.data.data);
        })
        .finally(() => {
          commit('setSaving', false);
        });
    },

    /**
     * Save claimData to the backend
     * @param {Object} payload
     */
    submitClaim({commit, state}) {
      // Destructure state to only the properties of it we need
      const {claimData} = state;

      return storefrontInstance.post(ROOT_URL, claimData).then((response) => {
        if (checkMetaFeedbackType(response.data, MetaFeedbackType.SUCCESS)) {
          commit('setClaimData', response.data.data);
          // If we're validating it means this was a submission and should redirect to the all-claims page
          // upon successful completion
          return response;
        } else {
          commit('setClaimData', response.data.data);
        }
      });
    },

    /**
     * Save claimData to the backend when triggered from an image input
     */
    async saveImageInput({commit, state}) {
      // Destructure state to only the properties of it we need
      const {claimData} = state;

      // Artificial delay after response to ensure that Azure has time to process and make the image available
      // before we try to retrieve it.
      const azureDelay = 4000;

      commit('setLoading', true);
      const {
        data: {data: newClaimData},
      } = await storefrontInstance.put(ROOT_URL, claimData, {toast: false});

      setTimeout(() => {
        commit('setClaimData', newClaimData);
        commit('setLoading', false);
      }, azureDelay);
    },

    /**
     * Fetch claim data from the API
     * @param {Object} context - Destructured to commit only
     * @param {string} claimId - The ID of the claim to fetch
     */
    fetchClaimData({commit}, claimId) {
      commit('setLoading', true);

      return storefrontInstance.get(`${ROOT_URL}${claimId}`).then((response) => {
        if (response?.data?.data) {
          if (response.data.data?.status === ClaimStatuses.NEW) {
            response.data.data.claimLines = mockClaim.claimLines;
          }

          commit('setClaimData', response.data.data);
          commit('setLoading', false);
        }
      });
    },
  },
};

export default warrantyClaims;
