import {ApplePayService} from '@/api/apple-pay-service.js';
import {ReCaptchaHelpers} from '@/utils/recaptcha-helpers.js';
import {injectForm} from '@/utils/checkout/checkout-utils.js';
import {
  ApplePaySetupData,
  ApplePayStatus,
  ApplePayShippingTypes,
  ApplePayContactEditingModes,
  ApplePayRequiredContactTypes,
  MERCHANT_IDENTIFIER,
} from '@/constants/apple-pay';
import {expressCartFinalAddress, expressCartShippingUpdates} from '@/utils/checkout/express-checkout-utils.js';
import {PaymentProvider} from '@/constants/payment-provider';
import storefrontApi from '@/api/instances/storefront';
import store from '@/store';
import {cartActions} from '@/store/modules/cart.js';
import {checkoutMutations} from '@/store/modules/checkout.js';

const ApplePayProvider = {
  defaultPaymentDetails: {
    total: {
      label: 'Express Checkout',
      amount: {
        value: '0.00',
        currency: 'USD',
      },
    },
  },
  isExpressCheckout: false,
  paymentRequest: {},
  paymentDetails: {},
  paymentMethodOptions: {},
  supportedNetworks: [],
  isCybersourceTransaction: false,
  isGr4vyTransaction: false,

  canMakePayments() {
    return !!(window.PaymentRequest && window.ApplePaySession?.canMakePayments());
  },

  /**
   * Setups our payment request object for Apple Pay.
   * @param {Object} options
   */
  setup(options = {}) {
    if (!ApplePayProvider.canMakePayments()) return;
    this.isExpressCheckout = options?.isExpressCheckout ?? false;
    this.isCybersourceTransaction = options?.isCybersourceTransaction ?? false;
    this.isGr4vyTransaction = options?.isGr4vyTransaction ?? false;
    this.paymentDetails = options?.paymentDetails
      ? this.getPaymentDetails(options?.paymentDetails)
      : this.defaultPaymentDetails;
    this.paymentMethodOptions = options?.paymentMethodOptions;
    this.supportedNetworks = options?.supportedNetworks;

    const paymentRequest = new PaymentRequest(
      this.getPaymentMethods(this.paymentMethodOptions, this.supportedNetworks),
      this.paymentDetails
    );

    this.paymentRequest = paymentRequest;

    paymentRequest.onmerchantvalidation = this.handleMerchantValidation;
    paymentRequest.onshippingaddresschange = this.handleShippingAddressChange;
  },

  async begin() {
    try {
      const paymentResponse = await ApplePayProvider.paymentRequest.show();
      const data = await this.processResponse(paymentResponse);
      paymentResponse.complete(data.status);
      if (!data.form && data.status !== ApplePayStatus.SUCCESS) {
        store.commit(checkoutMutations.SET_CHECKOUT_DIALOG_VISIBILITY, false);
        store.commit(checkoutMutations.SET_IS_APPLE_BUTTON_ENABLED, true);
        return;
      }
      const form = injectForm(data.form);
      store.commit(checkoutMutations.SET_IS_APPLE_BUTTON_ENABLED, false);
      form.submit();
    } catch (error) {
      store.commit(checkoutMutations.SET_IS_APPLE_BUTTON_ENABLED, true);
      console.error(error);
    }
  },

  async handleMerchantValidation(event) {
    let applePayRequestConfig;
    if (ApplePayProvider.isCybersourceTransaction) {
      applePayRequestConfig = {
        domain: new URL(event.validationURL).hostname,
        initiative: 'web',
        initiativeContext: `${window.location.protocol}//${window.location.host}`,
      };
    } else if (ApplePayProvider.isGr4vyTransaction) {
      applePayRequestConfig = {
        domain: event.validationURL,
        initiative: 'web',
        initiativeContext: document.location.hostname,
      };
    }

    store.commit(checkoutMutations.SET_VALIDATION_URL, event.validationURL);

    const session = await ApplePayService.getPaymentSession(applePayRequestConfig);

    this.session = session;
    event.complete(session);
  },

  async handleShippingAddressChange(event) {
    let paymentDetailsUpdate = {};

    const shippingAddress = event?.target?.shippingAddress;
    const deliveryModeId = store.state.checkout.selectedDeliveryMode;
    const dealerCode = store.state.checkout.selectedStore;

    if (ApplePayProvider.isExpressCheckout) {
      const paymentDetails = await expressCartShippingUpdates(shippingAddress, deliveryModeId, dealerCode);
      ApplePayProvider.paymentDetails = paymentDetails;

      paymentDetailsUpdate = ApplePayProvider.getPaymentDetails(paymentDetails);
      event.updateWith(paymentDetailsUpdate);
    }
  },

  async processResponse(paymentResponse) {
    const {details} = paymentResponse;
    details.session = ApplePayProvider.paymentRequest.session;

    if (ApplePayProvider.isExpressCheckout) {
      await ApplePayProvider.submitCustomerCartAddress(details);
    }
    const response = (
      await storefrontApi.post(
        '/checkout/multi/payment/billingDetails/v2/paymentInit',
        await ApplePayProvider.convertToPaymentDetails(details)
      )
    ).data;

    if (!response.data) {
      store.commit(checkoutMutations.SET_ERROR_MESSAGES, response?.meta?.feedback);
      return {status: ApplePayStatus.FAILED};
    }
    return {status: ApplePayStatus.SUCCESS, form: response.data.htmlOfAutosubmitForm};
  },

  /**
   * Static method used to pass customer info, or express checkout info to
   * the Apple Paysheet. Sets up our pay sheet line items.
   * @param {Object} paymentDetails
   * @returns {Object}
   */
  getPaymentDetails(paymentDetails = {}) {
    const i18n = window.VueI18n;
    let purchaser = '';
    if (paymentDetails?.deliveryAddress?.companyName) {
      purchaser = paymentDetails?.deliveryAddress?.companyName;
    } else if (paymentDetails?.deliveryAddress?.firstName) {
      purchaser = `${paymentDetails?.deliveryAddress?.firstName} ${paymentDetails?.deliveryAddress?.lastName}`;
    }

    return {
      total: {
        label: !purchaser ? 'Express Checkout' : purchaser,
        type: 'final',
        amount: {
          currency: paymentDetails.totalPriceWithTax.currencyIso,
          value: paymentDetails.totalPriceWithTax.value,
        },
      },
      displayItems: [
        {
          label: i18n.t('text.order.subtotal'),
          type: 'final',
          amount: {currency: paymentDetails.itemGrossPrice.currencyIso, value: paymentDetails.itemGrossPrice.value},
        },
        {
          label: i18n.t('basket.page.shipping'),
          amount: {
            currency: paymentDetails.shippingGrossPrice.currencyIso,
            value: paymentDetails.shippingGrossPrice.value,
          },
          type: 'final',
        },
        {
          label: i18n.t('text.order.estimatedTax'),
          amount: {currency: paymentDetails.totalTax.currencyIso, value: paymentDetails.totalTax.value},
          type: 'final',
        },
      ],
    };
  },

  /**
   * Sets up our apple pay request and pay sheet with defaults. Merges an options
   * object if we have ship to store options and checks ship to home modes. The required
   * config fields can show/hide features on the apple paysheet.
   * @param {Object} options
   * @param {Object} supportedNetworks
   * @param {Object} paymentDetails
   * @returns {Array}
   */
  getPaymentMethods(options, supportedNetworks) {
    let defaults = {
      supportedMethods: ApplePaySetupData.SUPPORT_METHODS,
      data: {
        version: ApplePaySetupData.VERSION,
        merchantIdentifier: this.getMerchantIdentifier(),
        merchantCapabilities: ApplePaySetupData.MERCHANT_CAPABILITIES,
        supportedNetworks: supportedNetworks,
        countryCode: ApplePaySetupData.COUNTRY_CODE,
        shippingContactEditingMode: ApplePayContactEditingModes.STORE_PICKUP,
        shippingType: ApplePayShippingTypes.SHIPPING,
        requiredBillingContactFields: ApplePaySetupData.REQUIRED_BILLING_CONTACT_FIELDS,
        requiredShippingContactFields: ApplePaySetupData.REQUIRED_SHIPPING_CONTACT_FIELDS,
      },
    };

    let data;
    if (!ApplePayProvider.isExpressCheckout) {
      // Standard Checkout DTC
      data = {
        ...defaults,
        data: {
          ...defaults.data,
          shippingContact: {},
          requiredBillingContactFields: [ApplePayRequiredContactTypes.POSTAL_ADDRESS],
          requiredShippingContactFields: [ApplePayRequiredContactTypes.PHONE],
        },
      };
    } else if (options.retailer) {
      // Express Store pickup
      const retailerForDelivery = options.retailer;

      let shippingContact = {
        addressLines: [retailerForDelivery.address?.line1],
        locality: retailerForDelivery.address?.town,
        administrativeArea: retailerForDelivery.address?.regionText
          ? retailerForDelivery.address?.regionText
          : retailerForDelivery.address.region?.name,
        postalCode: retailerForDelivery.address?.postalCode,
        familyName: retailerForDelivery?.description,
      };

      if (retailerForDelivery.address.line2) {
        shippingContact.addressLines.push(retailerForDelivery.address.line2);
      }

      data = {
        ...defaults,
        data: {
          ...defaults.data,
          shippingContact,
          shippingType: ApplePayShippingTypes.STORE_PICKUP,
        },
      };
    } else {
      // Express Checkout DTC
      data = {
        ...defaults,
        data: {
          ...defaults.data,
          shippingContactEditingMode: ApplePayContactEditingModes.ENABLED,
        },
      };
    }
    return [data];
  },

  getMerchantIdentifier() {
    return store.state.backend.customVariables.find((item) => item.qualifier === MERCHANT_IDENTIFIER).value;
  },

  /**
   * Handles deletion of dummy address and setting of full customers
   * address to Hybris after Apple Pay authorization.
   * @param {Object} addressDetails
   * @returns {AxiosPromise}
   */
  async submitCustomerCartAddress(addressDetails) {
    const {shippingContact, billingContact} = addressDetails;
    const deliveryModeId = store.state.checkout.selectedDeliveryMode;
    const dealerCode = store.state.checkout.selectedStore;

    let data;
    if (ApplePayProvider.paymentMethodOptions.retailer !== null) {
      const mergeAddress = {
        ...shippingContact,
        givenName: billingContact?.givenName,
        familyName: billingContact?.familyName,
      };
      data = await expressCartFinalAddress(mergeAddress);
    } else {
      data = await expressCartFinalAddress(shippingContact);
    }

    if (!store.state.backend.isUserLoggedIn) {
      await store.dispatch(cartActions.UPDATE_CART_EMAIL, shippingContact?.emailAddress);
    }

    const finalizedAddress = await store.dispatch(cartActions.UPDATE_CART_ADDRESS, data);
    await store.dispatch(cartActions.UPDATE_CART_DEALER_AND_MODE, {
      dealerCode,
      deliveryModeId,
    });
    return finalizedAddress;
  },

  /**
   * Handles converting authorized data for payment provider submission.
   * @param {Object} details
   * @returns payment object.
   */
  async convertToPaymentDetails(details) {
    const {shippingContact, billingContact} = details;
    const token = details.token;
    let reCaptchaToken;

    let transactionPaymentProvider;
    if (ApplePayProvider.isGr4vyTransaction) transactionPaymentProvider = PaymentProvider.GR4VY_APPLE;
    else if (ApplePayProvider.isCybersourceTransaction) transactionPaymentProvider = PaymentProvider.CYBERSOURCE_APPLE;

    if (ReCaptchaHelpers().isReCaptchaAvailable(window.tk_gen?.reCaptchaTokenToggle)) {
      reCaptchaToken = await ReCaptchaHelpers().getReCaptchaToken(window.tk_gen?.reCaptchaSiteKey, {
        action: 'ecommerce',
      });
    } else {
      reCaptchaToken = '';
    }

    return {
      billingAddress: {
        line1: billingContact.addressLines[0],
        line2: billingContact.addressLines[1],
        regionIso: billingContact.administrativeArea,
        countryIso: billingContact.countryCode,
        lastName: billingContact.familyName,
        firstName: billingContact.givenName,
        townCity: billingContact.locality,
        postcode: billingContact.postalCode,
        phone: shippingContact.phoneNumber,
        billingAddress: true,
        shippingAddress: false,
        defaultAddress: false,
      },
      cardTypeCode: token.paymentMethod?.network,
      nameOnCard: `${billingContact.givenName} ${billingContact.familyName}`,
      saveInAccount: false,
      newBillingAddress: false,
      paymentProviderCode: transactionPaymentProvider,
      paymentOption: transactionPaymentProvider,
      reCaptchaToken: reCaptchaToken,
      applePayData: {
        epochTimestamp: details.session?.epochTimestamp,
        expiresAt: details.session?.expiresAt,
        merchantSessionIdentifier: details.session?.merchantSessionIdentifier,
        nonce: details.session?.nonce,
        merchantIdentifier: details.session?.merchantIdentifier,
        operationalAnalyticsIdentifier: details.session?.operationalAnalyticsIdentifier,
        pspId: details.session?.pspId,
        initiative: 'web',
        validationUrl: store.state.checkout.validationUrl,
        token: JSON.stringify(token),
      },
    };
  },
};

export {ApplePayProvider};
