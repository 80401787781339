const CloudinaryConstants = {
  TRANSFORMATION_DELIMITER: ',',
  SPACE_DELIMITER: '%20',
  CDN_URL: '//media.trekbikes.com/image/upload/',
  DEFAULT_PARAMETERS: {
    f: 'auto',
    fl: 'progressive:semi',
    q: 'auto',
  },
};

const Cloudinary = {
  /**
   * Convert an object to an array of key_value strings for use in transforming cloudinary urls.
   * @param parameters
   * @param rules
   * @return {Array}
   */
  toTransformationList(parameters, rules = {}) {
    return Object.entries(parameters).reduce((transformationList, [key, value]) => {
      if (rules.skipEmpty && !value.toString().length) {
        return;
      }
      transformationList.push(`${key}_${value}`);
      return transformationList;
    }, []);
  },
  /**
   * Create a cloudinary asset url from an asset id and an object of transformation parameters.
   * @param {String} assetId
   * @param {Object} parameters
   * @returns {String}
   */
  createAssetUrl(assetId, parameters = CloudinaryConstants.DEFAULT_PARAMETERS) {
    if (!assetId) throw new Error('Asset id required');
    const transformationList = this.toTransformationList(parameters);
    return `${CloudinaryConstants.CDN_URL}${transformationList.join(
      CloudinaryConstants.TRANSFORMATION_DELIMITER
    )}/${assetId.replace(/ /g, CloudinaryConstants.SPACE_DELIMITER)}`;
  },
};

export {CloudinaryConstants, Cloudinary};
