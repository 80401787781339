import Vue from 'vue';
import occInstance from '@/api/instances/occ';

const rootUrl = '/products/recalls';

const recallInquiry = {
  namespaced: true,

  state: {
    hasRecall: false,
    hasSearchedForRecall: false,
  },

  mutations: {
    setHasSearchedForRecall(state, payload) {
      Vue.set(state, 'hasSearchedForRecall', payload);
    },
    setHasRecall(state, payload) {
      Vue.set(state, 'hasRecall', payload);
    },
  },

  actions: {
    // fetches recalls by product serial number
    getRecallBySerialNumber({commit}, serialNumber) {
      occInstance
        .get(`${rootUrl}/inquiry/${serialNumber}`)
        .then((response) => {
          if (response?.data) {
            commit('setHasSearchedForRecall', true);
            commit('setHasRecall', response.data.serialNumberHasRecall);
            return response.data;
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};

export default recallInquiry;
