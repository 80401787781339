import storefrontInstance from '@/api/instances/storefront';
import {cancelPreviousRequest} from '@/utils/axios/cancel-request';

const AllocatedItemsApi = {
  async getCart() {
    const response = await storefrontInstance.get('/checkout/multi/allocated-items/items');

    return response?.data?.data;
  },

  async updateAllocatedItems(entries = []) {
    const dto = buildUpdateAllocatedItemsDTO(entries);
    const response = await storefrontInstance.put('/checkout/multi/allocated-items/update', dto);

    return response?.data;
  },

  async getTotalsWithAllocated(entries = []) {
    const config = cancelPreviousRequest('totals');

    const dto = buildTotalsWithFreightDTO(entries);
    const response = await storefrontInstance.put('/cart/totals/', dto, config);

    return response?.data?.data;
  },

  async getTotalsWithFreight(entries = []) {
    const dto = buildTotalsWithFreightDTO(entries);
    const response = await storefrontInstance.put('/freight/cart/', dto);

    return response?.data.data;
  },

  async getHoldOptions() {
    const response = await storefrontInstance.get('/checkout/multi/cart-hold-options/');

    return response?.data?.data;
  },
};

function buildTotalsWithFreightDTO(entries = []) {
  const entriesData = entries.filter((entry) => entry.selected);
  return {
    entries: entriesData,
  };
}

function buildUpdateAllocatedItemsDTO(entries = []) {
  return entries.filter((entry) => entry.selected);
}

export {AllocatedItemsApi};
