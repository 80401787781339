import get from 'lodash/get';
import {Url} from '@/utils/url';

const fieldsMap = {
  text: {encode: true, valueField: 'searchQueryText'},
  sort: 'viewOptions.sortCode',
  viewMode: 'viewOptions.viewMode',
  pageSize: 'viewOptions.pageSize',
  currentPage: 'viewOptions.currentPage',
  facets: 'filtersQuery',
};

/**
 * Get object with data that excludes default values.
 * @param data
 * @param defaults
 */
const dataExcludingDefaults = (data, defaults) => {
  let result = {};
  for (let field in fieldsMap) {
    let options = fieldsMap[field];
    let value = '';

    if (typeof options !== 'object') {
      value = get(data, fieldsMap[field]);
      options = {
        valueField: fieldsMap[field],
        encode: false,
      };
    } else {
      value = get(data, fieldsMap[field].valueField);
    }

    if (
      options.valueField !== get(defaults, options.valueField) &&
      value !== undefined &&
      value.toString().length > 0
    ) {
      result[field] = options.encode ? encodeURIComponent(value) : value;
    }
  }

  return result;
};

/**
 * Update browser's URL address bar.
 * @param state
 * @param defaults
 */
const updateAddressBar = (state, defaults) => {
  const params = dataExcludingDefaults(state, defaults);
  if (!Object.keys(params)) {
    return;
  }

  const updatedUrl = Url.update(window.location.href, params, {
    removeKeys: ['facets', ...Object.keys(defaults.viewOptions)],
  });

  if (updatedUrl !== window.location.href) {
    window.history.pushState({}, window.document.title, updatedUrl);
  }
};

export {updateAddressBar};
