<script>
export default {
  name: 'StoreHoursScript',

  props: {
    cdnStaticFilesRoot: {
      type: String,
      default: '',
    },
    latitude: {
      type: String,
      default: '',
    },
    longitude: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    storeName: {
      type: String,
      default: '',
    },
    addressLine1: {
      type: String,
      default: '',
    },
    addressLine2: {
      type: String,
      default: '',
    },
    addressTown: {
      type: String,
      default: '',
    },
    addressPostalCode: {
      type: String,
      default: '',
    },
    addressName: {
      type: String,
      default: '',
    },
    addressUrl: {
      type: String,
      default: '',
    },
    sourceLatitude: {
      type: String,
      default: '',
    },
    sourceLongitude: {
      type: String,
      default: '',
    },
    boundSouthLatitude: {
      type: String,
      default: '',
    },
    boundWestLongitude: {
      type: String,
      default: '',
    },
    boundNorthLatitude: {
      type: String,
      default: '',
    },
    boundEastLongitude: {
      type: String,
      default: '',
    },
    results: {
      type: String,
      default: '',
    },
    singlePosLatitude: {
      type: String,
      default: '',
    },
    singlePosLongitude: {
      type: String,
      default: '',
    },
    singlePosName: {
      type: String,
      default: '',
    },
    productSelection: {
      type: String,
      default: '',
    },
    dotsFlag: {
      type: String,
      default: '',
    },
  },

  mounted() {
    window.markerLocation = `${this.cdnStaticFilesRoot}/_ui/desktop/theme-trek/images/markers/`;
    var map;
    var infowindow;
    window.map = map;
    window.markers = [];
    window.infowindow = infowindow;
    window.storelatitude = this.latitude;
    window.storelongitude = this.longitude;
    window.storeDescription = this.description;
    window.storename = this.storeName;
    window.storeaddressline1 = this.addressLine1;
    window.storeaddressline2 = this.addressLine2;
    window.storeaddresstown = this.addressTown;
    window.storeaddresspostalCode = `${this.addressPostalCode}`;
    window.storeaddresscountryname = `${this.addressName}`;
    window.storeaddressurl = this.addressUrl;
    window.storeSearchPageDatasourceLatitude = this.sourceLatitude;
    window.storeSearchPageDatasourceLongitude = this.sourceLongitude;
    window.storeSearchPageDataboundSouthLatitude = this.boundSouthLatitude;
    window.storeSearchPageDataboundWestLongitude = this.boundWestLongitude;
    window.storeSearchPageDataboundNorthLatitude = this.boundNorthLatitude;
    window.storeSearchPageDataboundEastLongitude = this.boundEastLongitude;
    window.storeSearchPageDataresults = this.results;
    window.singlePoslatitude = this.singlePosLatitude;
    window.singlePoslongitude = this.singlePosLongitude;
    window.singlePosname = this.singlePosName;
    window.storeProductSelection = this.productSelection;
    if (!this.productSelection) {
      window.storeProductSelection = 'GOOD';
    }
    window.dotsFlag = this.dotsFlag;
  },
};
</script>
