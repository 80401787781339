'use strict';

let headerEl = $('#js-sticky-header');
let asmEl = $('#_asm');
let asmCallout = $('#asm-size-callout');

/* If ASM is enabled on the page the top nav needs to behave differently as follows:

 1. If the ASM module is visible in the viewport then the top nav position needs to be relative
 2. If the ASM module is not visible in the viewport then the top nav position needs to be fixed (so it's sticky)
 3. If we're in mobile sizes then display an callout instead of the ASM module as it's not supported at those sizes
*/
function updateASMHeader() {
  // If ASM is enabled on the page the top nav needs to behave differently
  if ($('#_asm').length && $('#js-sticky-header').length) {
    headerEl.css('position', 'relative');

    $(window).on('resize scroll', () => {
      updateTopNavPosition();
    });
  } else {
    $('#asm-size-callout').remove();
  }
}

// Switches the top nav 'position' between relative and fixed depending on the ASM module visibility
function updateTopNavPosition() {
  var isInViewPort = false;

  var elementTop;
  var elementBottom;

  if (asmEl.css('display') !== 'none') {
    elementTop = $(asmEl).offset().top;
    elementBottom = elementTop + $(asmEl).outerHeight();
  } else if (asmCallout.css('display') !== 'none') {
    elementTop = $(asmCallout).offset().top;
    elementBottom = elementTop + $(asmCallout).outerHeight();
  }

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  isInViewPort = elementBottom > viewportTop && elementTop < viewportBottom;

  headerEl.css('position', isInViewPort ? 'relative' : 'fixed');
}

export {updateASMHeader};
