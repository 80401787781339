import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';
import {Url} from '@/utils/url';

class SearchApi {
  /**
   * Initialize a new instance of the SearchApi class.
   */
  constructor() {
    this.baseApiUrl = '/search/productResults/';
    this.lastLoadedUrl = '';
    this.data = {};
    this.listViewDefaultPageSize = '24';
  }

  set state(data) {
    this.data = data;
  }

  /**
   * Check whether the URL has been changed or not.
   * @return {boolean}
   */
  hasUrlChanged() {
    const url = this.getRequestUrl();
    return url !== this.lastLoadedUrl;
  }

  /**
   * Retrieve size of the page.
   * @return {string|*}
   */
  getPageSize() {
    if (this.data.viewOptions.viewMode === 'listview') {
      return this.listViewDefaultPageSize;
    }

    return this.data.viewOptions.pageSize;
  }

  /**
   * Get API Request URL.
   * @return {*|string}
   */
  getRequestUrl() {
    let q = `${encodeURIComponent(this.data.searchQueryText)}:${this.data.viewOptions.sortCode}`;
    if (this.data.filtersQuery.length > 0) {
      q = `${q}:${this.data.filtersQuery}`;
    }

    return Url.create(
      this.baseApiUrl,
      {
        pageSize: this.getPageSize(),
        sort: this.data.viewOptions.sortCode,
        page: this.data.viewOptions.currentPage,
        categoryCode: this.data.categoryId,
        viewMode: this.data.viewOptions.viewMode,
        q,
      },
      {
        skipEmpty: true,
      }
    );
  }

  /**
   * Load data from remote API.
   */
  fetchResults() {
    const url = this.getRequestUrl();
    return new Promise((resolve, reject) => {
      if (!this.hasUrlChanged()) {
        resolve([]);
        return;
      }

      this.lastLoadedUrl = url;

      storefrontInstance
        .get(url)
        .then((response) => {
          resolve(get(response, 'data.data'));
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export {SearchApi};
