const getTarget = (el) => {
  let target;
  if (typeof el === 'string') {
    target = document.querySelector(el);
  } else if (el instanceof HTMLElement) {
    target = el;
  }
  if (!target) {
    throw new Error('target not found');
  }

  return target;
};

const scrollBehavior = !window.matchMedia(`(prefers-reduced-motion: reduce)`).matches ? 'smooth' : 'instant';

const scrollToElement = (element, offset = 100) => {
  const target = getTarget(element);
  const scrollToValue = target.getBoundingClientRect().top + window.scrollY - offset;

  window.scrollTo({
    top: scrollToValue,
    behavior: scrollBehavior,
  });
};

const delayedScrollToElement = (element, offset = 100, delay = 300) => {
  setTimeout(() => {
    scrollToElement(element, offset);
  }, delay);
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: scrollBehavior,
  });
};

export {scrollToElement, delayedScrollToElement, scrollToTop};
