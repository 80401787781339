<script>
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import FormGroup from '@/components/FormGroup';
import TrekAvantyCheck from '@/components/scripts/TrekAvantyCheck';
import SemRush from '@/components/scripts/SemRush';
import CartFooter from '@/components/cart/footer';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {TrekButton, TrekLink, FormGroup, TrekAvantyCheck, SemRush, CartFooter, PdlIcon},
};
</script>
