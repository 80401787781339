import {compactBreakpoints} from '@/constants/view-breakpoints';

const globalModule = {
  namespaced: true,
  state: {
    isCompactView: false,
    headerHeight: 0,
  },

  mutations: {
    setIsCompactView(state, payload) {
      state.isCompactView = payload;
    },

    setHeaderHeight(state, payload) {
      state.headerHeight = payload;
    },
  },

  actions: {
    updateViewportState({commit}, payload) {
      commit('setIsCompactView', Object.keys(compactBreakpoints).includes(payload));
    },
  },
};

export default globalModule;
