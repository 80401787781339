export default {
  _default: function (n) {
    return n + ' πρέπει να είναι έγκυρη τιμή';
  },
  after: function (n, e) {
    return n + ' πρέπει να είναι μετά ' + e[0];
  },
  alpha: function (n) {
    return n + ' πρέπει να περιέχει μόνο αλφαβητικούς χαρακτήρες';
  },
  alpha_dash: function (n) {
    return n + ' μπορεί να περιέχει αλφαριθμητικούς χαρακτήρες, παύλες και κάτω παύλες';
  },
  alpha_num: function (n) {
    return n + ' πρέπει να περιέχει μόνο αλφαριθμητικούς χαρακτήρες';
  },
  alpha_spaces: function (n) {
    return n + ' μπορεί να περιέχει μόνο αλφαβητικούς χαρακτήρες και κενά';
  },
  before: function (n, e) {
    return n + ' πρέπει να είναι πρίν ' + e[0];
  },
  between: function (n, e) {
    return n + ' πρέπει να είναι μεταξύ ' + e[0] + ' καί ' + e[1];
  },
  confirmed: function (n, e) {
    return n + ' δεν ταιριάζει με ' + e[0];
  },
  credit_card: function (n) {
    return n + ' πρέπει να είναι έγκυρη πιστωτική κάρτα';
  },
  date_between: function (n, e) {
    return n + ' πρέπει να είναι μεταξύ ' + e[0] + ' καί ' + e[1];
  },
  date_format: function (n, e) {
    return n + ' πρέπει να είναι σε μορφή ' + e[0];
  },
  decimal: function (n, e) {
    void 0 === e && (e = []);
    var t = e[0];
    return (
      void 0 === t && (t = '*'),
      n + ' πρέπει να είναι αριθμός και να περιέχει' + (t && '*' !== t ? ' ' + t : '') + ' δεκαδικά ψηφία'
    );
  },
  digits: function (n, e) {
    return n + ' πρέπει να είναι αριθμός και να περιέχει ' + e[0] + ' ψηφία';
  },
  dimensions: function (n, e) {
    return n + ' πρέπει να είναι ' + e[0] + ' pixels επί ' + e[1] + ' pixels';
  },
  email: function (n) {
    return n + ' πρέπει να είναι έγκυρο email';
  },
  excluded: function (n) {
    return n + ' πρέπει να είναι έγκυρη τιμή';
  },
  ext: function (n) {
    return n + ' πρέπει να είναι έγκυρο αρχείο';
  },
  image: function (n) {
    return n + ' πρέπει να είναι εικόνα';
  },
  included: function (n) {
    return n + ' πρέπει να είναι έγκυρη τιμή';
  },
  integer: function (n) {
    return n + ' πρέπει να είναι ακέραιος αριθμός';
  },
  ip: function (n) {
    return n + ' πρέπει να είναι έγκυρη διεύθυνση IP';
  },
  length: function (n, e) {
    var t = e[0],
      r = e[1];
    return r
      ? n + ' πρέπει να είναι μεταξύ ' + t + ' και ' + r + ' χαρακτήρες'
      : n + ' πρέπει να είναι ' + t + ' χαρακτήρες';
  },
  max: function (n, e) {
    return n + ' δεν πρέπει να υπερβαίνει τους ' + e[0] + ' χαρακτήρες';
  },
  max_value: function (n, e) {
    return n + ' πρέπει να είναι ' + e[0] + ' ή λιγότερο';
  },
  mimes: function (n) {
    return n + ' πρέπει να είναι έγκυρο αρχείο ΜΙΜΕ';
  },
  min: function (n, e) {
    return n + ' πρέπει να είναι τουλάχιστον ' + e[0] + ' χαρακτήρες';
  },
  min_value: function (n, e) {
    return n + ' πρέπει να είναι ' + e[0] + ' ή περισσότερο';
  },
  numeric: function (n) {
    return n + ' πρέπει να περιέχει μόνο αριθμούς';
  },
  regex: function (n) {
    return n + ' δεν είναι έγκυρο';
  },
  required: function (n) {
    return n + ' δεν έχει συμπληρωθεί';
  },
  size: function (n, e) {
    return (
      n +
      ' πρέπει να μην υπερβαίνει τα ' +
      (function (n) {
        var e = 1024,
          t = 0 === (n = Number(n) * e) ? 0 : Math.floor(Math.log(n) / Math.log(e));
        return 1 * (n / Math.pow(e, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t];
      })(e[0])
    );
  },
  url: function (n) {
    return n + ' πρέπει να είναι έγκυρη διεύθυνση URL';
  },
};
