<template>
  <div class="autocomplete-dropdown__container">
    <div v-if="!b2b" class="autocomplete-dropdown__arrow" />
    <ul class="autocomplete-dropdown__list" tabindex="0">
      <li
        v-for="item in searchSuggestionData"
        :key="item.value"
        qaid="autocomplete-dropdown-list-item-suggestions"
        class="suggestions autocomplete-dropdown__list-item"
      >
        <a
          qaid="autocomplete-dropdown-list-item-suggestions-link"
          :href="item.url"
          @click="$emit('onSelected', item.value)"
        >
          {{ item.value }}
        </a>
      </li>
      <li
        v-if="b2b && searchCategoriesData.length > 0"
        class="autocomplete-dropdown__list-header"
        qaid="autocomplete-dropdown-categories-header"
      >
        {{ $t('text.categories') }}
      </li>
      <li
        v-for="item in searchCategoriesData"
        :key="item.value"
        qaid="autocomplete-dropdown-list-item-categories"
        class="categories autocomplete-dropdown__list-item"
      >
        <a
          qaid="autocomplete-dropdown-list-item-categories-link"
          :href="item.url"
          @click="$emit('onSelected', item.value)"
        >
          {{ item.value }}
        </a>
      </li>
      <li
        v-if="b2b && searchProductsData.length > 0"
        class="autocomplete-dropdown__list-header"
        qaid="autocomplete-dropdown-products-header"
      >
        {{ $t('text.products') }}
      </li>
      <li
        v-for="item in searchProductsData"
        :key="item.value"
        class="products autocomplete-dropdown__list-item"
        qaid="autocomplete-dropdown-list-item-products"
      >
        <a
          qaid="autocomplete-dropdown-list-item-products-link"
          :href="item.url"
          @click="$emit('onSelected', item.value)"
        >
          <div v-if="!b2b" class="autocomplete-dropdown__list-item-thumb">
            <img qaid="autocomplete-dropdown-list-item-products-img" alt="product-image" :src="item.image" />
          </div>
          <div class="autocomplete-dropdown__list-item-desc">
            <span qaid="autocomplete-dropdown-list-item-products-title" class="title">{{ item.value }}</span>
            <span qaid="autocomplete-dropdown-list-item-products-price" class="price">{{ item.price }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
  props: {
    result: {
      type: Object,
      required: true,
      default: () => {
        return {
          categories: [],
          products: [],
          suggestions: [],
        };
      },
    },
    displayImages: {
      type: String,
      default: 'true',
    },
  },
  computed: {
    searchSuggestionData() {
      return this.result.suggestions.map((item) => {
        return {
          value: item.term,
          url: `${this.encodedContextPath}/search?text=${item.term}`,
        };
      });
    },
    searchProductsData() {
      return this.result.products.map((item) => {
        return {
          value: item?.marketingModelYear ? `${item.name} (${item.marketingModelYear})` : item.name,
          price: item.price != null ? item.price.formattedValue : '',
          image: item.images.length > 0 && this.displayImages === 'true' ? item.images[0].url : null,
          url: this.encodedContextPath + item.url,
        };
      });
    },
    searchCategoriesData() {
      return this.result.categories.map((item) => {
        return {
          value: item.name,
          url: this.encodedContextPath + item.url,
        };
      });
    },
    ...mapState('backend', ['encodedContextPath', 'b2b']),
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-dropdown {
  &__arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);
    position: absolute;
    right: 13px;
    top: 11px;

    @apply w-0;
    @apply h-0;
    @apply hidden;

    @include breakpoint(large) {
      @apply block;
    }
  }

  &__container {
    z-index: 16000009;

    @apply pt-1;
    @apply right-0;
    @apply left-0;
    @apply top-12;
    @apply absolute;

    @include breakpoint(large) {
      width: 36rem;
      left: unset;

      @apply pt-2;
      @apply top-8;
    }
  }

  &__list {
    @apply shadow-md;

    &-header {
      @apply bg-gray-05;
      @apply p-1;
      @apply text-xs;
      @apply text-left;
      @apply font-bold;
      @apply text-gray-80;
      @apply leading-normal;
    }

    &-item {
      &:first-child {
        @apply border-t-0;
      }

      @apply border-t;
      @apply border-gray-30;
      @apply bg-white;
      @apply leading-normal;

      &.suggestions {
        > a,
        > a:hover {
          @apply p-1;
          @apply uppercase;
          @apply ml-1;

          @include breakpoint(large) {
            @apply ml-0;
          }
        }
      }

      &.categories {
        > a,
        > a:hover {
          @apply p-2;

          @include breakpoint(large) {
            @apply p-1;
          }
        }
      }

      &-thumb {
        @apply w-24;
        @apply p-1;
        @apply hidden;

        @include breakpoint(medium) {
          @apply block;
        }
      }

      &-desc {
        text-overflow: ellipsis;

        @apply flex;
        @apply m-1;
        @apply overflow-x-hidden;

        flex-basis: 100%;

        @apply p-1;

        @include breakpoint(large) {
          @apply p-0;
        }

        & .title {
          text-overflow: ellipsis;

          @apply whitespace-nowrap;
          @apply overflow-x-hidden;

          flex-grow: 1;
        }

        & .price {
          @apply font-bold;
          @apply text-right;
          @apply ml-2;
          @apply whitespace-nowrap;
        }
      }

      > a {
        @apply text-gray-100;
        @apply font-normal;
        @apply flex;
        @apply items-center;
      }

      > a:hover,
      > a:focus {
        @apply bg-gray-05;
        @apply text-gray-100;
      }
    }
  }
}
</style>
