<template>
  <div class="site-search-box">
    <form
      ref="searchForm"
      class="search-form-box"
      :class="{b2bMasthead__search: b2b}"
      :qaid="qaids.form"
      :name="names.form"
      method="GET"
      :action="`${encodedContextPath}/search/`"
      @submit="onSubmit"
      @reset="clear"
    >
      <input
        ref="searchInput"
        v-model="searchTerm"
        :qaid="qaids.input"
        class="js-site-search-input siteSearchInput primary-navigation__input text-small search-form-box__input multibrand-page-search"
        type="text"
        name="text"
        aria-label="site-search"
        spellcheck="false"
        autocomplete="off"
        maxlength="100"
        :placeholder="$t('search.placeholder')"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <button
        qaid="search-btn-submit"
        type="submit"
        class="search-form-box__btn search-form-box__btn-search"
        :class="{active: isSearchBoxFocused}"
      >
        <pdl-icon name="search" :label="$t('text.search')" />
      </button>
      <button
        v-show="isSearchCrossVisible"
        qaid="search-btn-close"
        type="reset"
        :class="{active: isSearchBoxFocused && viewBreakpointMoreThan('large')}"
        class="search-form-box__btn search-form-box__btn-close"
      >
        <pdl-icon name="close" :label="$t('popup.close')" />
      </button>
    </form>
    <search-autocomplete-dropdown
      v-show="isDropdownVisible"
      :display-images="displayImages"
      :result="queryResult"
      :class="{active: isDropdownVisible}"
      @onSelected="onSelected"
    />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import debounce from 'lodash/debounce';
import storefrontInstance from '@/api/instances/storefront';
import SearchAutocompleteDropdown from './SearchAutocompleteDropdown';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'SearchInput',
  components: {SearchAutocompleteDropdown, PdlIcon},
  props: {
    componentUid: {
      type: String,
      required: true,
    },
    minCharacter: {
      type: String,
      default: '2',
    },
    waitTime: {
      type: String,
      default: '100',
    },
    displayImages: {
      type: String,
      default: 'true',
    },
  },

  data() {
    return {
      searchTerm: '',
      categories: [],
      products: [],
      suggestions: [],
      isSearchBoxFocused: false,
      isDropdownVisible: false,
      debounce: null,
    };
  },

  computed: {
    autocompleteUrl() {
      return `search/autocomplete/${this.componentUid}`;
    },

    names() {
      return {
        form: `search_form_${this.componentUid}`,
      };
    },

    qaids() {
      return {
        form: `search-form-${this.componentUid}`,
        input: `input-${this.componentUid}`,
      };
    },

    queryResult() {
      return {
        categories: this.categories ?? [],
        products: this.products ?? [],
        suggestions: this.suggestions ?? [],
      };
    },
    hasSearchData() {
      return this.categories?.length > 0 || this.products?.length > 0 || this.suggestions?.length > 0;
    },
    isSearchCrossVisible() {
      return this.searchTerm.trim().length > 0 || this.viewBreakpointLessThan('large');
    },
    isSearchTermLonger() {
      return this.searchTerm.length > parseInt(this.minCharacter) - 1;
    },

    shouldDropdownOpen() {
      return this.hasSearchData && this.isSearchTermLonger;
    },

    ...mapState('backend', ['encodedContextPath', 'b2b']),
    ...mapGetters('viewSize', ['viewBreakpointLessThan', 'viewBreakpointMoreThan']),
  },

  mounted() {
    document.addEventListener('click', this.closeOnClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOnClickOutside);
  },

  methods: {
    /**
     * Doing the original work of jQuery Plugin, when selecting any suggestion from dropdown, placing the
     * suggestion text in input element and focusing it.
     */
    onSelected(value) {
      this.searchTerm = value;
      this.$refs.searchInput.focus();
    },
    onSubmit() {
      this.searchTerm = this.searchTerm.trim();
    },
    clear() {
      this.searchTerm = '';
      this.closeDropdown();
    },
    onBlur() {
      this.isSearchBoxFocused = false;
    },
    onFocus() {
      this.isSearchBoxFocused = true;
    },
    onInput() {
      if (this.isSearchTermLonger) {
        if (!this.debounce) {
          this.debounce = debounce(this.fireSearchRequest, parseInt(this.waitTime));
        }
        return this.debounce();
      } else {
        // Close the drop down if already opened by previous query.
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    },
    openDropdown() {
      this.isDropdownVisible = true;
    },

    async fireSearchRequest() {
      try {
        let response = await storefrontInstance.get(this.autocompleteUrl, {params: {text: this.searchTerm.trim()}});
        if (!response) return;
        this.categories = response.data?.categories;
        this.products = response.data?.products;
        this.suggestions = response.data?.suggestions;

        //If results comes late, for example while backspacing, when the last character is left, it'll still fire the query and after getting the result will open the drop-down.
        if (this.shouldDropdownOpen) {
          this.openDropdown();
        } else {
          this.closeDropdown();
        }
      } catch (error) {
        this.closeDropdown();
        console.error(error);
      }
    },
    closeOnClickOutside(event) {
      const el = this.$refs.searchForm;
      if (event.target === el || (el.contains(event.target) && this.shouldDropdownOpen)) {
        this.openDropdown();
      } else {
        this.closeDropdown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.site-search-box {
  @apply w-full;

  @include breakpoint(large) {
    position: relative;
  }

  .search-form-box {
    @apply relative;

    @include breakpoint(large) {
      @apply w-full;
    }

    &__input {
      @apply text-md;
      @apply p-1;
      @apply my-0;
      @apply ml-0;
      @apply mr-1;
      @apply text-gray-100;

      height: calc(2.25rem + 2px);
      font-style: italic;

      &::placeholder {
        @apply text-white;
        @apply opacity-100;
      }

      @include breakpoint(large) {
        @apply m-0;
      }
    }

    &__btn {
      &-close {
        @include breakpoint(large) {
          position: absolute;

          @apply right-2;

          top: 0.375rem;
        }

        &.active {
          @apply text-gray-60;
        }
      }

      &-search {
        position: absolute;

        @apply right-10;
        @apply top-2;
        @apply text-gray-90;

        @include breakpoint(large) {
          @apply text-white;
          @apply left-2;

          top: 0.4rem;
          right: auto;
        }

        &.active {
          @apply text-gray-100;
        }
      }
    }
  }
}
</style>
