<template>
  <ul qaid="nav-categories-layout" class="nav-categories-layout">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'NavCategoriesLayout',
};
</script>

<style lang="scss" scoped>
.nav-categories-layout {
  display: flex;
  margin-top: 1.25rem;

  @apply flex-col;
  @apply px-2;

  @include breakpoint(medium) {
    @apply px-3;
  }

  @include breakpoint(large) {
    @apply space-x-4;
    @apply mt-0;
    @apply flex-row;
    @apply items-center;
    @apply px-0;
  }

  @include breakpoint(xlarge) {
    @apply space-x-6;
  }
}
</style>
