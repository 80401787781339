import {TaxModes} from '@/constants/tax-modes.js';
import {computed} from 'vue';
import store from '@/store';

function formatPrice(priceObj, isFreeForZeroEnabled = false) {
  if (!priceObj) return;
  if (priceObj.value <= 0)
    return isFreeForZeroEnabled ? window.VueI18n.t('text.free').toUpperCase() : priceObj.formattedValue;
  return priceObj.formattedValue;
}

function useCartSummary(cartData = {}) {
  //helper function to handle ref or reactive cartData
  const getCartData = () => {
    return cartData?.value ?? cartData;
  };

  const hasAppliedPromotions = computed(() => {
    return getCartData()?.appliedOrderPromotions?.length || getCartData()?.appliedProductPromotions?.length;
  });

  const isVatEnabled = computed(() => {
    return getCartData()?.displayTaxB2CEnum === TaxModes.VAT;
  });

  const isCalculatedTaxEnabled = computed(() => {
    return getCartData()?.displayTaxB2CEnum === TaxModes.CALCULATED_TAX;
  });

  const isEstimatedTaxShowing = computed(() => {
    return Boolean(getCartData()?.showTaxEstimate);
  });

  const isAnyDeliveryModeSelected = computed(() => {
    return getCartData()?.entries?.some((entry) => entry.deliveryMode?.code);
  });
  const deliveryModeName = computed(() => {
    return window.VueI18n.t('basket.page.shipping');
  });

  const isDeliveryModeSet = computed(() => {
    return Boolean(deliveryModeName.value);
  });

  const subtotalPrice = computed(() => {
    return formatPrice(getCartData()?.itemGrossPrice);
  });

  const totalPrice = computed(() => {
    if (!getCartData()?.entries?.length) return formatPrice(getCartData()?.totalPriceWithoutCharges);
    return formatPrice(getCartData()?.totalPrice);
  });

  const totalPriceWithTax = computed(() => {
    return isVatEnabled.value ? formatPrice(getCartData()?.totalPrice) : formatPrice(getCartData()?.totalPriceWithTax);
  });

  const discountPrice = computed(() => {
    return formatPrice(getCartData()?.totalDiscounts);
  });

  const hasDiscounts = computed(() => {
    return Boolean(getCartData()?.totalDiscounts?.value > 0);
  });

  const cartTaxes = computed(() => {
    return formatPrice(getCartData()?.cartTaxes, true);
  });

  const totalTax = computed(() => {
    //Only show tax if we've started selecting delivery modes.
    return isAnyDeliveryModeSelected.value || store.getters['checkout/isRestrictedToRetailer']
      ? formatPrice(getCartData()?.totalTax)
      : '-';
  });

  const shippingGrossPrice = computed(() => {
    //Only show shipping price if we've started selecting delivery modes.
    return isAnyDeliveryModeSelected.value ? formatPrice(getCartData()?.shippingGrossPrice, true) : '-';
  });

  const netTotal = computed(() => {
    return formatPrice(getCartData()?.cartNetTotal, true);
  });

  const taxMessage = computed(() => {
    return window.VueI18n.t('checkout.multi.tax.calcCheckout');
  });

  const discountLabel = computed(() => {
    const voucher = getCartData()?.appliedVouchers?.[0];
    if (typeof voucher === 'string') return voucher;
    return voucher?.code;
  });

  const isAlabama = computed(() => {
    return getCartData()?.deliveryAddress?.region?.name === 'Alabama';
  });

  const trekUShippingCharge = computed(() => {
    return formatPrice(getCartData()?.trekUShippingCharge, true);
  });

  return {
    discountLabel,
    taxMessage,
    netTotal,
    cartTaxes,
    discountPrice,
    totalPrice,
    totalPriceWithTax,
    subtotalPrice,
    isVatEnabled,
    hasAppliedPromotions,
    hasDiscounts,
    isAlabama,
    isCalculatedTaxEnabled,
    deliveryModeName,
    isDeliveryModeSet,
    totalTax,
    shippingGrossPrice,
    isEstimatedTaxShowing,
    trekUShippingCharge,
  };
}

function useOrderSummary(order) {
  const orderTotal = computed(() => {
    return order.net ? formatPrice(order.totalPriceWithTax) : formatPrice(order.totalPrice);
  });

  const orderSubtotal = computed(() => {
    return order.appliedOrderPromotions?.length || order.appliedProductPromotions?.length
      ? formatPrice(order.netTotalAfterDiscount)
      : formatPrice(order.subTotal);
  });

  const totalTax = computed(() => {
    return formatPrice(order.totalTax);
  });

  return {
    orderTotal,
    orderSubtotal,
    totalTax,
  };
}

export {useCartSummary, useOrderSummary, formatPrice};
