<template>
  <!-- 
    This component functions as a re-usable utility for controls in the utility bar. 
    A control is an icon with a label that either opens a link or a popover when clicked 
  -->
  <div class="nav-utility-bar-control">
    <component
      :is="htmlElement"
      v-tippy="tippySettings"
      :href="href"
      class="nav-utility-bar-control__button"
      @show="isLink ? null : onShow"
    >
      <div class="nav-utility-bar-control__icon">
        <slot name="icon">
          <pdl-icon class="ml-25 self-center" :name="icon" :label="icon" />
          <pdl-badge v-if="hasBadge" qaid="nav-utility-bar-control-badge" class="absolute right-0 top-0" />
        </slot>
      </div>
      <div class="nav-utility-bar-control__content">
        <slot />
      </div>
    </component>
    <slot v-if="!isLink" name="popover" />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {PdlBadge} from '@pedal/pdl-badge';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavUtilityBarControl',
  components: {PdlBadge, PdlIcon},
  props: {
    /**
     * The icon to display. The icon slot overrides this if used.
     */
    icon: {
      type: String,
      default: undefined,
    },
    /**
     * The popover theme to apply
     */
    theme: {
      type: String,
      default: undefined,
    },
    /**
     * The href for the link. If used, the element will render as an anchor without v-tippy
     */
    href: {
      type: String,
      default: undefined,
    },
    /**
     * The ID of the html element to use as the popover's content
     */
    popoverHtml: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('global', ['isCompactView']),
    ...mapGetters('notifications', ['notifications']),
    isLink() {
      return this.href;
    },
    isNotificationControl() {
      return this.icon === 'notifications';
    },
    htmlElement() {
      return this.isLink ? 'a' : 'button';
    },
    hasBadge() {
      return this.isNotificationControl && this.notifications !== null
        ? this.notifications.filter((notification) => !notification.readStatus).length > 0
        : false;
    },
    tippySettings() {
      return this.isLink
        ? null
        : {
            html: `#${this.popoverHtml}`,
            interactive: true,
            reactive: true,
            trigger: 'click',
            theme: this.theme,
            placement: 'top-start',
            flipBehavior: ['top', 'bottom-end'],
          };
    },
  },
  methods: {
    onShow() {
      console.log('show');
    },
  },
};
</script>

<style scoped lang="scss">
.nav-utility-bar-control {
  @apply mt-2;

  @include breakpoint(large) {
    @apply mt-0;
    @apply ml-2;
  }

  &__button {
    @apply text-white;
    @apply leading-none;
    @apply hover:text-white;
    @apply focus:text-white;

    display: flex;
    align-items: center;
    outline: none;
  }

  &__icon {
    @apply mr-1;
    @apply relative;

    @include breakpoint(large) {
      @apply mr-0;
    }

    // Legacy style override from navigation.scss
    & .flag-icon-us-squared::after {
      margin-bottom: 0 !important;
    }
  }

  &__content {
    @apply text-lg;

    @include breakpoint(large) {
      display: none;
    }
  }
}
</style>
