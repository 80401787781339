export default {
  methods: {
    // Adds the locale path to relative links without it or returns the link as is (including null/undefined)
    composeLink(link) {
      const locale = ACC.config.encodedContextPath;
      return link && !link.startsWith('http') && !link.startsWith(locale) ? locale + link : link;
    },
    // Sets the target of a link
    setTarget(target) {
      return target === 'NEWWINDOW' ? '_blank' : '_self';
    },
  },
};
