import {delayedScrollToElement} from '@/utils/scroll-to';
/**
 * This runs only on page load when there is any accordionComponent present in the page.
 * If a hash is present in the url, we will anchor to the corresponding collapse item by component uid
 */
export default function () {
  const urlHash = window.location.hash;
  if (!urlHash) return;

  const decodedHash = decodeURIComponent(urlHash).split('#')[1];
  const hashElement = document.querySelector(`.accordion-entry-component [id='${decodedHash}']`);
  if (!hashElement) return;

  const headerHeight = document.querySelector('#trek-header').offsetHeight;
  const accordionWrappers = document.querySelectorAll('.accordion-entry-component');
  let wrapperCount = accordionWrappers.length;

  accordionWrappers.forEach(function (item) {
    const collapseItem = item.querySelector('.pdl-collapse-item__header');
    const hasAnchorId = item.querySelectorAll(`[id = '${decodedHash}']`).length > 0;
    const isCollapsedAnchorItem = hasAnchorId && !collapseItem?.classList?.contains('is-active');

    if (isCollapsedAnchorItem) collapseItem.click();
    wrapperCount--;

    if (wrapperCount === 0) {
      delayedScrollToElement(hashElement, headerHeight);
    }
  });
}
