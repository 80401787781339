import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';
import find from 'lodash/find';
import debounce from 'lodash/debounce';

const rootUrl = '/saved-lists/lists/';

/**
 * A helper to synchronize number of total entries in the given list by id.
 * @param targetLists Target array of lists.
 * @param listId ID of the list to modify.
 * @param totalEntries Number of entries.
 */
const syncTotalEntries = (targetLists, listId, totalEntries) => {
  if (targetLists && targetLists.length) {
    targetLists.forEach((list) => {
      if (list.id === listId) {
        list.totalEntries = totalEntries;
      }
    });
  }
};

const savedLists = {
  namespaced: true,
  state: {
    userLists: [],
    accountLists: [],
    entries: [],
    activeEntries: [],
    buyingZoneEntries: [],
    selectedList: {
      id: '',
      bikesEntries: [],
      partsEntries: [],
    },
    errorMessage: null,
    isBuyingZone: false,
    discontinuedMessage: null,
    clearQty: false,
    buyingZoneClearQty: false,
    isFetchedAllOnce: false,
  },

  mutations: {
    setIsFetchedAllOnce(state, payload) {
      Vue.set(state, 'isFetchedAllOnce', payload);
    },

    setUserLists(state, payload) {
      Vue.set(state, 'userLists', payload);
    },

    setAccountLists(state, payload) {
      Vue.set(state, 'accountLists', payload);
    },

    setSelectedList(state, payload) {
      let selectedList = null;
      if (payload.data) {
        if (payload.data.removedEntries && payload.data.removedEntries.length > 0) {
          Vue.set(state, 'discontinuedMessage', payload.meta.feedback.message.basePropertyValue);
        }
        payload.data.bikesEntries.forEach((element) => {
          Vue.set(element, 'checked', false);
        });
        payload.data.partsEntries.forEach((element) => {
          Vue.set(element, 'checked', false);
        });
        selectedList = payload.data;
      } else {
        selectedList = payload;
      }

      // Synchronize total entries.
      [state.userLists, state.accountLists].forEach((lists) => {
        syncTotalEntries(lists, selectedList.id, selectedList.totalEntries);
      });

      // Set state value.
      Vue.set(state, 'selectedList', selectedList);
    },

    updateUserLists(state, {id, totalEntries}) {
      const updatedList = state.userLists.find((list) => list.id === id);
      const newList = {...updatedList, totalEntries};
      const oldLists = state.userLists.filter((list) => list.id !== id);

      Vue.set(state, 'userLists', [...oldLists, newList]);
    },

    addUserList(state, {name, id, totalEntries}) {
      Vue.set(state, 'userLists', [...state.userLists, {name, id, totalEntries}]);
    },

    setIsBuyingZone(state, isBuyingZone) {
      Vue.set(state, 'isBuyingZone', isBuyingZone);
    },

    addEntry(state, {sku, qty}) {
      const newEntry = {sku, qty};
      if (state.isBuyingZone === true) {
        const oldEntries = state.buyingZoneEntries.filter((entry) => entry.sku !== sku);
        Vue.set(state, 'buyingZoneEntries', [...oldEntries, newEntry]);
      } else {
        const oldEntries = state.entries.filter((entry) => entry.sku !== sku);
        Vue.set(state, 'entries', [...oldEntries, newEntry]);
      }
    },

    setName(state, {name, id}) {
      const matchIndex = state.userLists.findIndex((list) => list.id === id);

      state.selectedList.name = name;
      state.userLists[matchIndex].name = name;
    },

    // Overrides all entries with the array passed in
    setEntries(state, entries) {
      entries.forEach((entry) => {
        if (entry.checked === null) {
          entry.checked = false;
        }
      });
      Vue.set(state, 'entries', entries);
    },

    setBuyingZoneEntries(state, entries) {
      Vue.set(state, 'buyingZoneEntries', entries);
    },

    clearEntries(state) {
      if (state.isBuyingZone) {
        Vue.set(state, 'activeEntries', []);
        Vue.set(state, 'buyingZoneEntries', []);
      } else {
        Vue.set(state, 'entries', []);
      }
    },

    updateEntries(state, sku) {
      let findElemIndex = state.entries.findIndex((item) => item.sku === sku);
      if (findElemIndex >= 0) {
        state.entries.splice(findElemIndex, 1);
      }
    },

    removeList(state, id) {
      const newLists = state.userLists.filter((list) => list.id !== id);

      Vue.set(state, 'userLists', newLists);
    },

    identifyEntriesList(state) {
      if (state.isBuyingZone === true) {
        const activeEntries = state.buyingZoneEntries.filter((entry) => entry.qty);
        Vue.set(state, 'activeEntries', activeEntries);
      } else {
        const activeEntries = state.entries.filter((entry) => entry.qty);
        Vue.set(state, 'activeEntries', activeEntries);
      }
    },

    setError(state, message) {
      Vue.set(state, 'errorMessage', message);
    },

    clearError(state) {
      Vue.set(state, 'errorMessage', null);
    },

    clearQty(state) {
      if (state.isBuyingZone) {
        Vue.set(state, 'buyingZoneClearQty', true);
        setTimeout(() => {
          Vue.set(state, 'buyingZoneClearQty', false);
        }, 100);
      } else {
        Vue.set(state, 'clearQty', true);
        setTimeout(() => {
          Vue.set(state, 'clearQty', false);
        }, 100);
      }
    },

    setStockWatch(state, {stockWatchProduct, stockWatchWarehouse, stockWatchActive, entriesName}) {
      const product = find(state.selectedList[entriesName], {sku: stockWatchProduct});

      if (product) {
        const warehouse = find(product.stockWatch, {id: stockWatchWarehouse});
        Vue.set(warehouse, 'stockWatchActive', stockWatchActive);
      }
    },

    setRowChecked(state, {entriesType, rowIndex, value}) {
      state.selectedList[entriesType][rowIndex].checked = value;
    },
    setOrderedQty(state, {entriesType, rowIndex, value}) {
      state.selectedList[entriesType][rowIndex].orderedQty = value;
    },
  },

  actions: {
    clearSelectedList(context) {
      context.commit('setSelectedList', {
        id: '',
        bikesEntries: [],
        partsEntries: [],
      });
    },

    fetchAllLists: debounce(function (context) {
      return storefrontInstance.get(rootUrl).then(({data: {data}}) => {
        context.commit('setUserLists', data.userLists);
        context.commit('setAccountLists', data.accountLists);
        context.commit('setIsFetchedAllOnce', true);
      });
    }, 1000),

    fetchList(context, {id, isToast}) {
      return storefrontInstance
        .get(`${rootUrl}${id}/`, {toast: isToast})
        .then(({data}) => {
          context.commit('setSelectedList', data);

          return data;
        })
        .catch((error) => {
          const {meta} = error.response.data;
          const {
            message: {basePropertyValue},
          } = meta.feedback;

          if (basePropertyValue) {
            context.commit('setError', basePropertyValue);
          }
        });
    },

    fetchListSort(context, {id, sortKey}) {
      return storefrontInstance
        .get(`${rootUrl}${id}?sortBy=${sortKey}`)
        .then(({data}) => {
          context.commit('setSelectedList', data);

          return data;
        })
        .catch((error) => console.log(error));
    },

    createList(context, {name, csrfToken, isToast}) {
      const CSRFToken = csrfToken || ACC.config.CSRFToken;
      const requestBody = {name, entries: context.state.activeEntries};
      const requestConfig = {headers: {CSRFToken}, toast: isToast};

      return storefrontInstance.post(rootUrl, requestBody, requestConfig).then(({data}) => {
        const {name, id, entries} = data.data;

        context.commit('clearEntries');
        context.commit('addUserList', {
          name,
          id,
          totalEntries: entries ? entries.length : 0,
        });

        return data;
      });
    },

    updateListName(context, {id, name}) {
      return storefrontInstance.patch(`${rootUrl}${id}/name/`, name).then(({data}) => {
        context.commit('setName', {name, id});
        return data;
      });
    },

    getTotalEntries() {
      return storefrontInstance.get(`${rootUrl}`).then(({data}) => data);
    },

    async updateListEntries(context, {id, entries, isToast}) {
      const {data} = await storefrontInstance.patch(`${rootUrl}${id}/`, {entries}, {toast: isToast});
      const entriesList = await context.dispatch('getTotalEntries', id);
      const currentEntryList = entriesList.data.userLists.find((entry) => entry.id === id);

      context.commit('clearEntries');
      context.commit('updateUserLists', {id, totalEntries: currentEntryList.totalEntries});
      return data;
    },

    updateSelectedEntries(context, {id, entries}) {
      return storefrontInstance.patch(`${rootUrl}${id}/entries/`, {entries}).then(({data}) => {
        if (data?.entries?.length > 0) {
          return context.dispatch('fetchList', {id});
        }
        return data;
      });
    },

    removeSelectedEntries(context, entries) {
      const id = context.state.selectedList.id;

      return storefrontInstance.delete(`${rootUrl}${id}/entries/`, {data: entries}).then(() => {
        return context.dispatch('fetchList', {id});
      });
    },

    deleteList(context, id) {
      return storefrontInstance.delete(`${rootUrl}${id}/`).then((data) => {
        context.commit('removeList', id);
        if (data) return data;
      });
    },

    updateQty(context, {sku, qty, checked, lineNote}) {
      if (context.state.selectedList.id) {
        const {id} = context.state.selectedList;
        const entries = [{sku, qty, checked, lineNote}];
        return context.dispatch('updateSelectedEntries', {id, entries});
      } else {
        context.commit('addEntry', {sku, qty});
        context.commit('identifyEntriesList');
      }
    },
  },
};

export default savedLists;
