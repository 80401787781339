<template>
  <div v-show="isCompactView" id="abbreviatedNavigationBar" qaid="nav-compact-container" class="nav-compact-container">
    <div class="title-bar row" data-responsive-toggle="none" data-hide-for="large">
      <div class="title-bar__icon title-bar__icon-menu">
        <button type="button" :aria-label="menuAriaLabel" :name="menuName" @click="toggleMobileMenu">
          <pdl-icon v-if="mobileMenuOpen" name="close" />
          <pdl-icon v-else name="menu" />
        </button>
      </div>
      <div class="title-bar__logo">
        <slot name="logo" />
      </div>
      <div class="title-bar__icon title-bar__icon-search">
        <slot name="search" />
      </div>
      <div class="title-bar__icon title-bar__icon-cart">
        <slot name="cart" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavCompactContainer',
  components: {PdlIcon},
  provide: {
    idSuffix: '-compact',
  },
  props: {
    menuAriaLabel: {
      type: String,
      default: null,
    },
    menuName: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('global', ['isCompactView']),
    ...mapState('navigation', ['mobileMenuOpen']),
  },
  methods: {
    ...mapMutations('navigation', ['setMobileMenuOpen']),
    toggleMobileMenu() {
      this.mobileMenuOpen ? this.setMobileMenuOpen(false) : this.setMobileMenuOpen(true);
    },
  },
};
</script>
