import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  props: {
    button: {
      type: Boolean,
      default: true,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    iconSuccess: {
      type: Boolean,
      default: false,
    },
    iconWarning: {
      type: Boolean,
      default: false,
    },
    iconAlert: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xlarge: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    formInline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      return {
        button: this.button,
        'button--primary': this.button && this.primary,
        'button--secondary': this.button && this.secondary,
        'button--tertiary': this.button && this.tertiary,
        'button--text': this.button && this.text,
        'button--success': this.button && this.success,
        'button--alert': this.button && this.alert,
        'button--inverted': this.button && this.inverted,
        'button--icon-right': this.button && this.iconRight,
        'button--icon-only': this.button && this.iconOnly,
        'button--icon-success': this.button && (this.text || this.tertiary) && this.iconSuccess,
        'button--icon-warning': this.button && (this.text || this.tertiary) && this.iconWarning,
        'button--icon-alert': this.button && (this.text || this.tertiary) && this.iconAlert,
        'button--xs': this.button && this.xsmall,
        'button--sm': this.button && this.small,
        'button--lg': this.button && this.large,
        'button--xl': this.button && this.xlarge,
        'button--block': this.button && this.block,
        'button--underline': this.button && this.text && this.underline,
        'button--inline': this.button && this.formInline,
      };
    },
  },
};
