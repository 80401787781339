const dialogsModule = {
  namespaced: true,
  state: {
    payerAuthDialogVisible: false,
  },
  mutations: {
    setPayerAuthDialog(state, payload) {
      state.payerAuthDialogVisible = payload;
    },
  },
};
export default dialogsModule;
