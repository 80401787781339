const MERCHANT_IDENTIFIER = 'merchantIdentifier';

const ApplePaySetupData = Object.freeze({
  COUNTRY_CODE: 'US',
  MERCHANT_CAPABILITIES: ['supports3DS', 'supportsCredit', 'supportsDebit'],
  REQUIRED_BILLING_CONTACT_FIELDS: ['postalAddress', 'phone', 'email'],
  REQUIRED_SHIPPING_CONTACT_FIELDS: ['postalAddress', 'phone', 'email'],
  SUPPORT_METHODS: 'https://apple.com/apple-pay',
  VERSION: 12,
});

const ApplePayShippingTypes = Object.freeze({
  SHIPPING: 'shipping',
  STORE_PICKUP: 'storePickup',
});

const ApplePayRequiredContactTypes = Object.freeze({
  PHONE: 'phone',
  POSTAL_ADDRESS: 'postalAddress',
  EMAIL: 'email',
});

const ApplePayContactEditingModes = Object.freeze({
  ENABLED: 'enabled',
  STORE_PICKUP: 'storePickup',
});

const ApplePayStatus = Object.freeze({
  SUCCESS: 'success',
  FAIL: 'fail',
});

export {
  ApplePaySetupData,
  ApplePayStatus,
  ApplePayShippingTypes,
  ApplePayContactEditingModes,
  ApplePayRequiredContactTypes,
  MERCHANT_IDENTIFIER,
};
