'use strict';

export default function () {
  var privacyPolicyAcceptCheckbox = document.getElementById('gdprPrivacyPolicyConsent');

  if (document.body.contains(document.getElementById('gdprPrivacyPolicyConsent'))) {
    document.getElementById('emailSignupButton').disabled = true;

    privacyPolicyAcceptCheckbox.addEventListener('change', (e) => {
      if (e.target.checked) {
        document.getElementById('emailSignupButton').disabled = false;
      } else {
        document.getElementById('emailSignupButton').disabled = true;
      }
    });
  }

  $('.emailSignupText').keyup(function (event) {
    if (event.keyCode === 13 && (!privacyPolicyAcceptCheckbox || privacyPolicyAcceptCheckbox.checked)) {
      $('.emailSignupButton').click();
    }
  });

  $('.emailSignupButton').on('click', function (event) {
    event.preventDefault();

    var userEmail = $(this).parent().find('.emailSignupText').val();
    var emailSignupUrl = $(this).data('email-signup-url');

    $.ajax({
      url: emailSignupUrl,
      data: {
        email: userEmail,
      },
      type: 'GET',
      dataType: 'json',
      error: function () {
        //Get it from the component...
        $('.email-signup__header').text($('.email-signup__error_prop_title').val());
        $('.email-signup__subtext').text($('.email-signup__error_prop_subtitle').val());
        $('.email-signup__wrap').addClass('error');
      },
      success: function (data) {
        if (data.STATUS === 'OK') {
          $('.email-signup__header').text(data.TITLE);
          $('.email-signup__subtext').text(data.SUBTITLE);
          $('.email-signup__message').text(data.EMAIL);
          $('.email-signup__wrap').removeClass('error');
          $('.email-signup__wrap').addClass('success');
          $('.email-signup__form-container').hide();
          $('.email-signup__consent_message').hide();
        } else if (data.STATUS === 'INVALID') {
          $('.email-signup__header').text(data.TITLE);
          $('.email-signup__subtext').text(data.SUBTITLE);
          $('.email-signup__wrap').addClass('error');
        } else if (data.STATUS === 'ERROR') {
          $('.email-signup__header').text($('.email-signup__error_prop_title').val());
          $('.email-signup__subtext').text($('.email-signup__error_prop_subtitle').val());
          $('.email-signup__wrap').addClass('error');
        }
      },
    });
  });

  $('.emailSignupText').on('focus', function () {
    $(this).parent().addClass('has-focus');
    $('.email-signup__wrap').removeClass('error');
  });
  $('.emailSignupText').on('focusout', function () {
    if ($(this).val().length === 0) {
      $(this).parent().removeClass('has-focus');
    }
  });
}
