'use strict';

export default function (element) {
  // requirements are to provide your button and reveal target
  var buttons = element.find('.list-heading');

  // clear event listeners (this fixes an issue with the event firing twice, which breaks in safari)
  buttons.off('click');

  buttons.on('click', function () {
    var mediaQuery = '(max-width: 639px)';
    // default
    if (window.matchMedia(mediaQuery).matches) {
      handleStates($(this));
    }
  });

  function handleStates(button) {
    button.parent().toggleClass('active');
  }

  buttons.attr('tabindex', '0');

  buttons.keyup(function (event) {
    if (event.keyCode === 13) {
      handleStates($(this));
    }
  });
}
