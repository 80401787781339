import store from '@/store';
import CryptoUtils from '@/utils/oauth/crypto-utils';
import {Cookie} from '@/utils/cookie';
import tokensInstance from '@/api/instances/tokens';

const formatTokens = (tokens) => {
  return {accessToken: tokens.aT, refreshToken: tokens.rT, sessionId: tokens.sId, iv: tokens.iv};
};

const parseDecryptedCookie = ({decryptedText, iv}) => {
  return {...JSON.parse(decryptedText), iv: Array.from(iv)};
};

const decryptAuthTokenFromCookie = async (keySeed) => {
  const encryptedTokens = Cookie.get('userToken');
  if (!encryptedTokens) return;

  const decryptedCookie = await new CryptoUtils(keySeed).decrypt(encryptedTokens);
  return parseDecryptedCookie(decryptedCookie);
};

const encryptTokens = async (decryptedTokens, keySeed) => {
  const tokens = JSON.stringify({
    aT: decryptedTokens.accessToken,
    rT: decryptedTokens.refreshToken,
    sId: decryptedTokens.sessionId,
  });
  const encryptedTokens = await new CryptoUtils(keySeed).encrypt(tokens, decryptedTokens.iv);
  return encryptedTokens;
};

const saveAuthTokens = (tokens) => {
  const formattedTokens = formatTokens(tokens);
  if (!formattedTokens?.accessToken || !formattedTokens?.refreshToken) return;
  store.dispatch('user/saveTokens', formattedTokens);
};

const AuthTokens = {
  getAccessToken: function () {
    return store?.state?.user?.accessToken;
  },
  getSessionId: function () {
    return store?.state?.user?.sessionId;
  },
  getIv: function () {
    return store?.state?.user?.iv;
  },
  getRefreshToken: function () {
    return store?.state?.user?.refreshToken;
  },
  getEncryptedTokens: async function (email) {
    let accessToken = this.getAccessToken();
    let refreshToken = this.getRefreshToken();
    let iv = this.getIv();
    let sessionId = this.getSessionId();
    if (!accessToken || !refreshToken || !email) return;
    // refresh token is encrypted at rest
    const {decryptedText: decryptedRefreshToken} = await new CryptoUtils(email).decrypt(refreshToken);
    const tokens = {
      accessToken: accessToken,
      refreshToken: decryptedRefreshToken,
      iv: iv,
      sessionId: sessionId,
    };

    return encryptTokens(tokens, email);
  },
  refreshAuthTokens: async function (email) {
    const tokens = await this.getEncryptedTokens(email);

    if (!tokens) throw new Error('No tokens present. Cannot refresh.');

    const payload = {
      token: tokens,
      userId: email,
    };

    try {
      const response = await tokensInstance.post(`/token/renew`, payload);
      const updatedTokens = response.data.token;

      if (!updatedTokens) {
        throw new Error('Failed to refresh tokens.');
      }

      const updateToken = await this.updateToken(email, updatedTokens);
      return updateToken.aT;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  },

  updateToken: async function (email, token) {
    const decryptedToken = await new CryptoUtils(email).decrypt(token);
    const parsedTokens = parseDecryptedCookie(decryptedToken);
    saveAuthTokens(parsedTokens);
    return parsedTokens;
  },

  parseAuthTokens: async function (email) {
    if (this.getAccessToken()) return;
    const tokens = await decryptAuthTokenFromCookie(email);
    if (!tokens) return;
    saveAuthTokens(tokens);
  },
};

export default AuthTokens;
