<template>
  <a
    :id="linkId"
    :qaid="`nav-categories-link-${linkId}`"
    :href="composeLink(href)"
    :class="isActive ? 'nav-categories-link--active' : null"
    class="nav-categories-link font-heading font-bold lg:font-body"
    :target="setTarget(target)"
    @click="hasOverlay ? processLinkClick(id) : null"
    v-html="linkText"
  />
</template>

<script>
import {mapActions, mapState} from 'vuex';
import links from '../mixins/links';

export default {
  name: 'NavCategoriesLink',
  mixins: [links],
  inject: ['idSuffix'],
  props: {
    /**
     * All of the overlay data associated with this category link in JSON format.
     */
    jsonData: {
      type: Array,
      default: null,
    },
    /**
     * The uid of the link. This is used to set the "activeLink" in VueX.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * The text of the link
     */
    linkText: {
      type: String,
      default: null,
    },
    /**
     * The href for the link with no overlay
     */
    href: {
      type: String,
      default: null,
    },

    target: {
      type: String,
      default: null,
    },
    isAutoExpandMobile: {
      type: Boolean,
      default: false,
    },
    /**
     * When true, links without products in this category's overlay will be hidden
     */
    hideLinksWithoutProducts: {
      type: Boolean,
      default: false,
    },
    /**
     * How many links should be in each column for auto-wrapping singular lists
     */
    minLinksPerCol: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    ...mapState('global', ['isCompactView']),
    ...mapState('navigation', ['activeLink']),
    isActive() {
      return this.id === this.activeLink;
    },
    hasOverlay() {
      return Boolean(this.jsonData);
    },
    linkId() {
      return this.idSuffix ? `${this.id}${this.idSuffix}` : this.id;
    },
  },
  mounted() {
    if (this.hasOverlay) {
      this.optimizeOverlayData({
        link: this.id,
        linkText: this.linkText,
        jsonData: this.jsonData,
        hideLinksWithoutProducts: this.hideLinksWithoutProducts,
        isAutoExpandMobile: this.isAutoExpandMobile,
        minLinksPerCol: this.minLinksPerCol,
      });
    }
  },
  methods: {
    ...mapActions('navigation', ['optimizeOverlayData', 'setDefaultActiveTab', 'updateActiveLink']),
    processLinkClick(id) {
      this.updateActiveLink(id);
      if (!this.isCompactView || this.isAutoExpandMobile) {
        this.setDefaultActiveTab();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-categories-link {
  display: block;
  margin: 0.75rem 0;
  color: var(--white) !important; // Needed until #primary-navigation & it's associated styles can be removed in phase 2

  @apply text-xl;
  @apply leading-none;

  &::after {
    content: '';
    display: block;
    border-bottom: 2px solid transparent;
    transition: margin 0.25s ease-in-out, border 0.25s ease-in-out;
  }

  @include breakpoint(large) {
    margin: 0;

    @apply text-xs;

    &:hover::after,
    &--active::after {
      margin-top: 0.25rem;
      border-color: var(--nav-highlight-color);
    }
  }

  @include breakpoint(xlarge) {
    @apply text-sm;
  }
}
</style>
