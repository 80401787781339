/**
 * The list of view breakpoints. The list should be always in ascending order.
 * @type {{small: number, owllarge: number, large: number, xlarge: number, medium: number}}
 */

const compactBreakpoints = Object.freeze({
  small: 320,
  medium: 640,
});

const ViewBreakpoints = Object.freeze({
  ...compactBreakpoints,
  large: 1024,
  xlarge: 1200,
  xxlarge: 1440,
});

const ViewSize = Object.freeze({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'xlarge',
  XX_LARGE: 'xxlarge',
});

export {compactBreakpoints, ViewBreakpoints, ViewSize};
