import storefrontInstance from '@/api/instances/storefront';

const AllocatedBackorderAPI = {
  async fetchSearchHeader() {
    const response = await storefrontInstance.get(`/backorders-allocated-items/search/header/`);

    return response?.data?.data;
  },
  async searchEntries(payload) {
    const response = await storefrontInstance.post('/backorders-allocated-items/search/', payload);

    return response?.data?.data;
  },
  async fetchProductTypeETA(productType) {
    const response = await storefrontInstance.get(`/notification-settings/eta-watch?producttype=${productType}`);

    return response?.data;
  },
};

export default AllocatedBackorderAPI;
