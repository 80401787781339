var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"nav-lists-layout",class:[
    'nav-lists-layout',
    {
      'nav-lists-layout--grid': _vm.isGridLayout,
      [`nav-lists-layout--grid--${_vm.availableColumns}-col`]: _vm.isGridLayout,
    },
  ]},_vm._l((_vm.lists),function(list){return _c('div',{key:list.columnHeader},[_c('nav-list',{ref:"nav-lists-layout__list",refInFor:true,attrs:{"column-header":list.columnHeader,"column-header-url":list.url,"uid":list.uid,"links":_vm.filterLinks(list.links),"col-span":_vm.singularListColSpan,"cols-available":_vm.singularListColsAvailable}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }