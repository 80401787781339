<template>
  <section class="video-component">
    <video v-if="isMp4" :id="videoId" class="bg-video" playsinline autoplay muted loop subtitles="test">
      <source :src="videoSrc" type="video/mp4" />
      <track :label="description" kind="descriptions" srclang="en" />
    </video>

    <div v-if="languageISO !== 'zh_CN' && videoSrc" data-bg-video :data-video="videoId" class="bg-video"></div>

    <div v-if="languageISO === 'zh_CN'">
      <image-component
        :id="fallbackImageData.id"
        :css-class="`${fallbackImageData.alignment} wallpaper fit-cover h-full`"
        :asset-id="fallbackImageData.assetId"
        img-format="pjpg"
        :is-data-object-fit="true"
        :alt-text="fallbackImageData.altText"
        transformation="fill"
        sizes="auto"
        :is-responsive="true"
      />
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import ImageComponent from '@/components/ImageComponent.vue';

export default {
  name: 'VideoComponent',
  components: {ImageComponent},
  props: {
    /**
     * Description for background videos not hosted on youtube.
     * Track element to address accessibility.
     */
    description: {
      type: String,
      default: '',
    },
    /**
     * Object to set a fallback image attrs
     * @params {id, assetId, alignment, altText}
     */
    fallbackImageData: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Sets video ID for external js.
     */
    videoId: {
      type: String,
      default: '',
      required: true,
    },
    /**
     * Url of the video source.
     */
    videoSrc: {
      type: String,
      default: '',
      required: true,
    },
  },

  computed: {
    ...mapState('backend', ['languageISO']),

    isMp4() {
      return this.videoSrc.lastIndexOf('.mp4') !== -1;
    },
  },
};
</script>
