import Vue from 'vue';
import get from 'lodash/get';
import set from 'lodash/set';
import flatten from 'lodash/flatten';
import clone from 'lodash/clone';
import {SearchApi} from '@/components/containers/plp/utils/search-api';
import {ProductApi} from '@/api/product';
import {updateAddressBar} from '@/components/containers/plp/utils/update-address-bar';
import {LocalStorage} from '@/utils/storage/local';
import {toId} from '@/utils/qaid-helper';
import ProductGroups from '@/components/containers/bulk-product/utils/product-groups';

const searchApi = new SearchApi();
const currentUserId = toId(ACC?.customVariables?.currentUserEmail) || 'guest';
const persistentKeys = ['viewMode'];
const defaultViewOptions = {
  pageSize: '72',
  sortCode: 'relevance',
  viewMode: get(LocalStorage.read(currentUserId), 'viewMode', 'grid'),
  currentPage: 0,
};

// A mockup response in case the price call doesn't come up with any response for any sku variant(Unlikely to happen on prod)
const mockupResponseObject = {
  retailerPrice: {
    advancedOrdering: null,
    apple: null,
    bulkPrices: null,
    bulkSalePrices: null,
    closeout: null,
    excludeTaxes: null,
    price: null,
    savedAmount: null,
    wasPrice: null,
  },
  consumerPrice: {advertised: null, price: null, wasPrice: null, saleAdvertised: null},
  appleLabels: {
    retailLabelDescription: '',
    retailLabelPrice: '',
    retailLabelProductId: '',
    retailLabelStatus: false,
  },
};

const saveHistory = (state) => {
  updateAddressBar(state, {viewOptions: defaultViewOptions});
};

const encodeValues = (list) => {
  if (!list) {
    return list;
  }

  list.forEach((item) => {
    if (item.facetOptionsValueDataList) {
      item.facetOptionsValueDataList.forEach((option) => {
        option.value = encodeURIComponent(option.value);
      });
    }
  });
  return list;
};

/**
 * Update product by given code, parameter name and its value.
 * @param state
 * @param payload
 */
const updateProduct = (state, payload) => {
  if (!payload.code || !payload.param || !payload.value) {
    return;
  }

  const index = state.products.findIndex((item) => item.code === payload.code);
  if (index > -1) {
    Vue.set(state.products[index], payload.param, payload.value);
  }
};

/**
 * Load data from remote API.
 * @param commit
 * @param state
 */
const fetchResults = (commit, state) =>
  new Promise((resolve, reject) => {
    searchApi.state = state;
    if (!state || !searchApi.hasUrlChanged()) {
      resolve();
      return;
    }

    commit('setLoadingState', true);
    searchApi
      .fetchResults()
      .then((data) => {
        if (data && Object.keys(data).length > 0) {
          const options = Object.assign({}, data.productListPageOptions, {
            searchSpellingSuggestion: data.searchSpellingSuggestion,
            sortOptions: data.sortOptions,
          });

          commit('setFilters', encodeValues(data.allAvailableFacets));
          commit('setSelectedFilters', encodeValues(data.userSelectedOptionsDataList));
          commit('setOptions', options);
          commit('setProducts', data.productDataList);
          commit('clearCollapseState');

          if (data.redirectURL && data.redirectURL.length > 0) {
            window.location.href = data.redirectURL;
          }
        }

        commit('setLoadingState', false);
        saveHistory(state);
        resolve();
      })
      .catch((reason) => {
        commit('setLoadingState', false);
        reject(reason);
      });
  });

const plpModule = {
  namespaced: true,

  state: {
    categoryId: '',
    searchQueryText: '',
    products: [],
    filters: {},
    filtersQuery: '',
    selectedFilters: {},
    options: {},
    viewOptions: clone(defaultViewOptions),
    loading: false,
    isColorSwatchAllowed: true,
    hasAccessToPlpListView: true,
    collapseState: {},
    isBundleDialogVisible: false,
    isExcludingTaxesShown: false,
  },

  getters: {
    isAllProductsExpanded: (state) => {
      if (!state.products || !state.products.length) {
        return false;
      }

      let expandedItems = 0;
      Object.keys(state.collapseState).forEach((item) => {
        if (state.collapseState[item]) {
          expandedItems += 1;
        }
      });

      return expandedItems === state.products.length;
    },
  },

  mutations: {
    clearCollapseState(state) {
      Vue.set(state, 'collapseState', {});
    },

    updateCollapseState(state, payload) {
      if (!payload || !payload.code) {
        return;
      }

      Vue.set(state.collapseState, payload.code, payload.state);
    },

    setLoadingState(state, isLoading) {
      Vue.set(state, 'loading', isLoading);
    },

    setIsColorSwatchAllowedState(state, payload) {
      Vue.set(state, 'isColorSwatchAllowed', payload);
    },

    setHasAccessToPlpListView(state, payload) {
      Vue.set(state, 'hasAccessToPlpListView', payload);
    },

    setProducts(state, payload) {
      Vue.set(state, 'products', payload);
    },

    setFilters(state, payload) {
      Vue.set(state, 'filters', payload);
    },

    setSelectedFilters(state, payload) {
      Vue.set(state, 'selectedFilters', payload);
    },

    setOptions(state, payload) {
      Vue.set(state, 'options', payload);
    },

    resetViewOptions(state) {
      Vue.set(state, 'viewOptions', clone(defaultViewOptions));
    },

    setCategoryId(state, categoryId) {
      Vue.set(state, 'categoryId', categoryId);
    },

    setFiltersQuery(state, query) {
      Vue.set(state, 'filtersQuery', query);
    },

    setSearchQueryText(state, searchQueryText) {
      Vue.set(state, 'searchQueryText', searchQueryText);
    },

    updateProductStatus(state, payload) {
      updateProduct(state, {
        code: payload.code,
        param: 'isReady',
        value: payload.isReady,
      });
    },

    updateViewOption(state, payload) {
      state.viewOptions = Object.assign(state.viewOptions, payload);
    },

    setBundleDialogVisibility(state, payload) {
      Vue.set(state, 'isBundleDialogVisible', payload);
    },

    setIsExcludingTaxesShown(state, payload) {
      Vue.set(state, 'isExcludingTaxesShown', payload);
    },
  },

  actions: {
    updateProductStatus({commit}, payload) {
      commit('updateProductStatus', payload);
    },

    clearCollapseState({commit}) {
      commit('clearCollapseState');
    },

    loadProductData({state}, {chunks, options}) {
      return new Promise((resolve, reject) => {
        if (!chunks || !chunks.length) {
          resolve();
        } else {
          let allBaseProducts = [];
          let allvariantCodes = [];
          Promise.all(chunks.map((ids) => ProductApi.batchLoadProducts(ids)))
            .then((resultsArray) => {
              if (resultsArray && resultsArray.length) {
                allBaseProducts = flatten(resultsArray);

                allBaseProducts.forEach((product) => {
                  product.variants.forEach((variant) => allvariantCodes.push(variant.code));
                });

                return ProductApi.fetchVariantsPrices(allvariantCodes);
              }
            })
            .then((pricingResults) => {
              const bundleTemplateIdList = [];
              const skusWithBundle = [];
              let plpProductsWithBundle = [];

              allBaseProducts.forEach((product) => {
                product.variants.forEach((variant, index) => {
                  let priceObject = pricingResults.find((item) => item.code === variant.code);
                  if (priceObject) {
                    set(product, `variants[${index}].retailerPrice`, priceObject.retailerPrice);
                    set(product, `variants[${index}].consumerPrice`, priceObject.consumerPrice);
                    set(product, `variants[${index}].appleLabels`, priceObject.appleLabels);
                  } else {
                    set(product, `variants[${index}].retailerPrice`, mockupResponseObject.retailerPrice);
                    set(product, `variants[${index}].consumerPrice`, mockupResponseObject.consumerPrice);
                    set(product, `variants[${index}].appleLabels`, mockupResponseObject.appleLabels);
                  }

                  // On each product clopase(on b2b plp) get all the products which has bundle product associated with them
                  if (variant.bundleTemplateIdList) {
                    skusWithBundle.push(variant.code);
                    bundleTemplateIdList.push(variant.bundleTemplateIdList[0]);
                    plpProductsWithBundle.push(
                      state.products.find((productWithBundle) => productWithBundle.code === product.code)
                    );
                  }
                });

                const productOptions = {
                  code: product.code,
                  param: 'productGroups',
                  value: new ProductGroups(product, options).getProductGroups(),
                };

                updateProduct(state, productOptions);
              });

              //Storing bundle template ids and sku in vuex(product-bundle.js)
              window.store.dispatch('productBundle/addBundleTemplates', {
                id: bundleTemplateIdList,
                sku: skusWithBundle,
              });

              // store PLP bundle products in vuex
              window.store.commit('productBundle/addPlpBundleProducts', plpProductsWithBundle);
              resolve();
            })
            .catch((reason) => {
              reject(reason);
            });
        }
      });
    },

    updateCollapseState({commit}, payload) {
      commit('updateCollapseState', payload);
    },

    setApiBaseUrl(context, baseApiUrl) {
      searchApi.baseApiUrl = baseApiUrl;
    },

    fetchResults({commit, state}) {
      return fetchResults(commit, state);
    },

    setCategoryId({commit}, categoryId) {
      commit('setCategoryId', categoryId);
    },

    setSearchQueryText({commit}, searchQueryText) {
      commit('setSearchQueryText', searchQueryText);
    },

    textSearch({commit, state}, searchQueryText) {
      commit('setSearchQueryText', searchQueryText);
      commit('updateViewOption', {currentPage: 0});
      fetchResults(commit, state);
    },

    setFiltersQuery({commit}, query) {
      commit('setFiltersQuery', query);
    },

    updateViewOption({commit, state}, payload) {
      let reload = false;
      let persistentOptions = {};

      Object.keys(payload).forEach((param) => {
        if (persistentKeys.indexOf(param) > -1) {
          persistentOptions[param] = payload[param];
        }

        if (state.viewOptions[param] !== payload[param]) {
          reload = true;
        }
      });

      if (Object.keys(persistentOptions)) {
        LocalStorage.save(currentUserId, persistentOptions);
      }

      if (reload) {
        commit('updateViewOption', payload);
        fetchResults(commit, state);
      }
    },

    resetViewOptions({commit}) {
      commit('resetViewOptions');
    },

    updateFilter({commit, state}, payload) {
      const cleanUpItem = (filter) => filter.replace(/(^::|::$|^:|:$)/g, '').replace(/(::)/g, ':');
      const updateItem = (query, item) => {
        let newFilterQuery;
        let filter = `${item.facetCode}:${item.value || get(item, 'facetOptionsValueDataList[0].value')}`;

        if (!item.selected) {
          newFilterQuery = `${query}${query.length > 0 ? ':' : ''}${filter}`;
        } else {
          newFilterQuery = cleanUpItem(query.replace(filter, ''));
          if (newFilterQuery.endsWith(':')) {
            newFilterQuery = newFilterQuery.substring(0, newFilterQuery.length - 1);
          }
        }

        return newFilterQuery;
      };

      let newFilterQuery = '';
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          newFilterQuery = updateItem(!newFilterQuery.length ? state.filtersQuery : newFilterQuery, item);
        });
      } else {
        newFilterQuery = updateItem(state.filtersQuery, payload);
      }

      commit('setFiltersQuery', newFilterQuery);
      commit('updateViewOption', {currentPage: 0});
      fetchResults(commit, state);
    },
  },
};

export default plpModule;
