import storefrontInstance from '@/api/instances/storefront';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';

const advancedOrderingModule = {
  namespaced: true,

  state: {
    advancedOrderingMode: window.ACC?.advancedOrderingMode || false,
    nonAOItems: [],
    advancedOrderingError: false,
    displayAddErrorModal: false,
    // Provides a way to watch for when the async call to toggle AO finishes
    aoToggleCompletedWatcher: false,
  },

  getters: {
    advancedOrderingMode(state) {
      return state.advancedOrderingMode;
    },

    aoToggleCompletedWatcher(state) {
      return state.aoToggleCompletedWatcher;
    },
  },

  mutations: {
    setAdvancedOrderingMode(state, value) {
      state.advancedOrderingMode = value;
    },

    setNonAOItems(state, value) {
      state.nonAOItems = value;
    },

    setAdvancedOrderingError(state, value) {
      state.advancedOrderingError = value;
    },

    setDisplayAddErrorModal(state, value) {
      state.displayAddErrorModal = value;
    },
  },

  actions: {
    toggleAdvancedOrderingMode({commit, state}) {
      let value = !state.advancedOrderingMode;
      commit('setAdvancedOrderingMode', value);

      storefrontInstance
        .post('/my-account/set-advanced-toggle', {advancedOrderToggle: value})
        .then(({data}) => {
          if (data && data.meta && data.meta.feedback) {
            if (checkMetaFeedbackType(data, MetaFeedbackType.SUCCESS)) {
              commit('setNonAOItems', data.data);
              commit('setAdvancedOrderingMode', !value);
            }
          } else {
            commit('setNonAOItems', []);
            commit('setAdvancedOrderingMode', !value);
            commit('setAdvancedOrderingError', true);
          }
        })
        .catch((error) => {
          commit('setNonAOItems', []);
          commit('setAdvancedOrderingMode', !value);
          commit('setAdvancedOrderingError', true);

          console.log(error);
        })
        .finally(() => {
          this.dispatch('cart/fetchCart');
          this.dispatch('miniCart/fetchMiniCart');
          state.aoToggleCompletedWatcher = !state.aoToggleCompletedWatcher;
        });
    },
  },
};

export default advancedOrderingModule;
