import RcpChallengesApi from '@/rcpChallenges/utils/rcp-challenges-api';

const rcpChallenges = {
  namespaced: true,
  state: {
    activeChallenge: {},
    activeChallengeType: '',
    isChallengeLoading: false,
    isChallengeDeleteDialogOpen: false,
    hasUnsavedChanges: false,
  },
  mutations: {
    setActiveChallenge(state, payload) {
      state.activeChallenge = payload;
    },
    setActiveChallengeType(state, payload) {
      state.activeChallengeType = payload;
    },
    setIsChallengeLoading(state, payload) {
      state.isChallengeLoading = payload;
    },
    setDeleteDialogStatus(state, payload) {
      state.isChallengeDeleteDialogOpen = payload;
    },
    setHasUnsavedChanges(state, payload) {
      state.hasUnsavedChanges = payload;
    },
  },
  actions: {
    async fetchChallenge({commit}, requestId) {
      try {
        commit('setIsChallengeLoading', true);
        let response = await RcpChallengesApi.fetchChallenge(requestId);
        if (response.type) commit('setActiveChallengeType', response.type);
        //restoring locationPoint obj for audience selection
        if (!response.searchScope.locationPoint) {
          response.searchScope.locationPoint = {lat: 0, lng: 0, elevation: 0};
        }
        commit('setActiveChallenge', response);
        commit('setIsChallengeLoading', false);
      } catch (error) {
        commit('setIsChallengeLoading', false);
        throw error.cause.data;
      }
    },
    clearActiveChallenge({commit}) {
      commit('setActiveChallenge', {});
      commit('setActiveChallengeType', '');
    },
  },
};

export default rcpChallenges;
