<template>
  <!-- **
  * TODO: In Phase 2 when Foundation is removed, change the v-show on this parent div to v-if
  * We only want either NavLargeContainer OR NavOverlayCompactLayout to appear in the DOM at a time 
  ** -->
  <transition name="fade" @before-enter="setupOverlay" @after-leave="unsetOverlay">
    <div v-show="isShown" qaid="nav-overlay-compact" class="nav-overlay-compact" :style="overlayHeight">
      <nav class="nav-overlay-compact__nav">
        <nav-overlay-compact-screen is-visible>
          <slot name="nav" />
          <slot name="utility-bar-compact" />
        </nav-overlay-compact-screen>

        <nav-overlay-compact-screen
          :is-visible="!categoryHasSingularTab && Boolean(activeLink)"
          :breadcrumb="activeLinkText"
          @back="goBack"
        >
          <nav-tabs-layout qaid="nav-tabs-compact-layout" />
        </nav-overlay-compact-screen>

        <nav-overlay-compact-screen
          :is-visible="activeLink && activeTab"
          :breadcrumb="activeTabText ? activeTabText : activeLinkText"
          @back="goBack"
        >
          <nav-lists-layout qaid="nav-lists-compact-layout" />
        </nav-overlay-compact-screen>
      </nav>
    </div>
  </transition>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import NavOverlayCompactScreen from '@/navigation/components/NavOverlayCompactScreen.vue';
import NavTabsLayout from '@/navigation/layouts/NavTabsLayout.vue';
import NavListsLayout from '@/navigation/layouts/NavListsLayout.vue';
import throttle from 'lodash/throttle';

export default {
  name: 'NavOverlayCompactLayout',
  components: {
    NavOverlayCompactScreen,
    NavTabsLayout,
    NavListsLayout,
  },
  provide: {
    idSuffix: '-compact',
  },
  computed: {
    ...mapState('global', ['isCompactView']),
    ...mapState('navigation', ['activeLink', 'activeTab', 'currentOverlay', 'mobileMenuOpen', 'overlayHeight']),
    activeLinkText() {
      return this.currentOverlay?.parentLinkText;
    },
    activeTabText() {
      return this.currentOverlay?.tabs.find((tab) => tab.uid === this.activeTab)?.text;
    },
    categoryHasSingularTab() {
      return this.currentOverlay?.tabs?.length === 1;
    },
    isShown() {
      return this.isCompactView && this.mobileMenuOpen;
    },
  },
  mounted() {
    window.addEventListener('resize', this.throttledResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.throttledResize);
    if (this.isShown) {
      this.unsetOverlay();
    }
  },
  methods: {
    ...mapActions('navigation', ['unsetNav', 'calculateOverlayHeight']),
    ...mapMutations('navigation', ['setActiveLink', 'setActiveTab']),
    disableBodyScrolling() {
      document.body.style.overflow = 'hidden';
    },
    enableBodyScrolling() {
      document.body.style.overflow = 'auto';
    },
    goBack() {
      if (this.isShown) {
        if (this.activeTab) {
          this.setActiveTab(null);
          // If there is only 1 tab, goBack means go all the way back to the choose-a-link screen
          if (this.categoryHasSingularTab) {
            this.setActiveLink(null);
          }
        } else {
          this.setActiveLink(null);
        }
      }
    },
    setupOverlay() {
      this.disableBodyScrolling();
      this.calculateOverlayHeight();
    },
    unsetOverlay() {
      this.enableBodyScrolling();
      this.unsetNav();
    },
    throttledResize: throttle(function () {
      this.calculateOverlayHeight();
    }, 250),
  },
};
</script>

<style lang="scss" scoped>
.nav-overlay-compact {
  @apply bg-gray-100;

  z-index: 16000009;
  position: absolute;
  width: 100%;
  overflow: auto;

  /* Ovelay Transition ----------------------- */
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.5s, transform 0.5s;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  /* Nav ------------------------------------- */
  &__nav {
    position: relative;
    height: 100%;
  }
}
</style>
