import occInstance from '@/api/instances/occ';

const rootUrl = '/users';
const UserOccApi = {
  async fetchUserProfile() {
    const url = `${rootUrl}/current`;
    const {data: userProfile} = await occInstance.get(url);
    return userProfile;
  },
  async patchUserProfile(params) {
    const url = `${rootUrl}/current`;
    const {data: updatedUserProfile} = await occInstance.patch(url, params);
    return updatedUserProfile;
  },
  async fetchGenderList(params) {
    const url = `enums/customergender`;
    const {data: userGenderList} = await occInstance.get(url, {params});
    return userGenderList;
  },
};

export default UserOccApi;
