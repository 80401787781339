export const asyncQueryAction = (asyncFunc, REQUEST, SUCCESS, FAILURE) => {
  const run = async ({commit}) => {
    commit(REQUEST);
    try {
      const result = await asyncFunc();
      commit(SUCCESS, result);
    } catch (error) {
      commit(FAILURE, error);
    }
  };
  return {run};
};
