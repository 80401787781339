export default {
  after: function (e, t) {
    return e + '-feltet må være etter ' + t[0];
  },
  alpha: function (e) {
    return e + '-feltet kan bare inneholde bokstaver';
  },
  alpha_dash: function (e) {
    return e + '-feltet kan bare inneholde alfanumeriske tegn, samt bindestrek og understrek';
  },
  alpha_num: function (e) {
    return e + ' kan bare inneholde alfanumeriske tegn';
  },
  alpha_spaces: function (e) {
    return e + '-feltet kan bare inneholde alfanumeriske tegn og mellomrom';
  },
  before: function (e, t) {
    return e + '-feltet må være før ' + t[0];
  },
  between: function (e, t) {
    return e + '-feltet må være imellom ' + t[0] + ' og ' + t[1];
  },
  confirmed: function (e) {
    return e + '-feltet kan ikke bekreftes';
  },
  credit_card: function (e) {
    return e + '-feltet er ugyldig';
  },
  date_between: function (e, t) {
    return e + '-feltet må være imellom ' + t[0] + ' og ' + t[1];
  },
  date_format: function (e, t) {
    return e + '-feltet må være i følgende format: ' + t[0];
  },
  decimal: function (e, t) {
    void 0 === t && (t = []);
    var n = t[0];
    return (
      void 0 === n && (n = '*'),
      e + '-feltet må være numerisk samt kan inneholde' + (n && '*' !== n ? ' ' + n : '') + ' desimaler'
    );
  },
  digits: function (e, t) {
    return e + '-feltet må være numerisk og inneholde nøyaktig ' + t[0] + ' siffer';
  },
  dimensions: function (e, t) {
    return e + '-feltet må være ' + t[0] + ' ganger ' + t[1] + ' piksler';
  },
  email: function (e) {
    return e + '-feltet må være en gyldig e-postadresse';
  },
  excluded: function (e) {
    return e + '-feltet må være en gyldig verdi';
  },
  ext: function (e) {
    return e + '-feltet må være en gyldig fil';
  },
  image: function (e) {
    return e + '-feltet må være et bilde';
  },
  included: function (e) {
    return e + '-feltet må være en gyldig verdi';
  },
  ip: function (e) {
    return e + '-feltet må være en gyldig IP-adresse';
  },
  max: function (e, t) {
    return e + '-feltet kan ikke være lengre enn ' + t[0] + ' tegn';
  },
  max_value: function (e, t) {
    return e + '-feltet må være ' + t[0] + ' eller mindre';
  },
  mimes: function (e) {
    return e + '-feltet må ha en gyldig filtype';
  },
  min: function (e, t) {
    return e + '-feltet må være minst ' + t[0] + ' tegn';
  },
  min_value: function (e, t) {
    return e + '-feltet må være ' + t[0] + ' eller mer';
  },
  numeric: function (e) {
    return e + '-feltet kan bare inneholde nummer';
  },
  regex: function (e) {
    return e + '-feltet sin formatering er ugyldig';
  },
  required: function (e) {
    return e + '-feltet er obligatorisk';
  },
  size: function (e, t) {
    return (
      e +
      '-feltet må være mindre enn ' +
      (function (e) {
        var t = 1024,
          n = 0 === (e = Number(e) * t) ? 0 : Math.floor(Math.log(e) / Math.log(t));
        return 1 * (e / Math.pow(t, n)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][n];
      })(t[0])
    );
  },
  url: function (e) {
    return e + '-feltet er ikke en gyldig URL';
  },
};
