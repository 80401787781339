const storeLocatorModule = {
  namespaced: true,
  state: {
    activeFilters: null,
    pagination: {},
    textSearchQuery: '',
    mapCenterCoords: {},
    mapZoom: null,
    searchBounds: null,
  },

  getters: {
    activeFilters(state) {
      return state.activeFilters;
    },
    pagination(state) {
      return state.pagination;
    },
    textSearchQuery(state) {
      return state.textSearchQuery;
    },
    mapCenterCoords(state) {
      return state.mapCenterCoords;
    },
    mapZoom(state) {
      return state.mapZoom;
    },
    searchBounds(state) {
      return state.searchBounds;
    },
  },

  mutations: {
    setActiveFilters(state, payload) {
      state.activeFilters = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setTextSearchQuery(state, payload) {
      state.textSearchQuery = payload;
    },
    setMapCenterCoords(state, payload) {
      state.mapCenterCoords = payload;
    },
    setMapZoom(state, payload) {
      state.mapZoom = payload;
    },
    setSearchBounds(state, payload) {
      state.searchBounds = payload;
    },
  },

  actions: {
    resetCache(context) {
      context.commit('setActiveFilters', null);
      context.commit('setPagination', {});
      context.commit('setTextSearchQuery', '');
      context.commit('setMapCenterCoords', {});
      context.commit('setMapZoom', null);
      context.commit('setSearchBounds', null);
    },
  },
};

export default storeLocatorModule;
