export default {
  after: function (n, e) {
    return n + ' უნდა იყოს ' + e[0] + '(ი)ს შემდეგ';
  },
  alpha: function (n) {
    return n + ' უნდა შეიცავდეს მხოლოდ ასოებს';
  },
  alpha_dash: function (n) {
    return n + ' უნდა შესაძლებელია შეიცავდეს ციფრებს, ასოებს და პუნქტუაციის ნიშნებს';
  },
  alpha_num: function (n) {
    return n + ' უნდა შეიცავდეს მხოლოდ ციფრებს';
  },
  alpha_spaces: function (n) {
    return n + ' უნდა შეიცავდეს მხოლოდ ასოებსა და ცარიელ სივრცეებს';
  },
  before: function (n, e) {
    return n + ' უნდა იყოს ' + e[0] + 'მდე';
  },
  between: function (n, e) {
    return n + ' უნდა იყოს ' + e[0] + ' და ' + e[1] + 'ს შორის';
  },
  confirmed: function (n, e) {
    return n + ' არ ემთხვევა ' + e[0] + '(ი)ს';
  },
  date_between: function (n, e) {
    return n + ' უნდა უნდა იყოს ' + e[0] + ' და ' + e[1] + '-ს შორის';
  },
  date_format: function (n, e) {
    return n + ' უნდა იყოს ' + e[0] + ' ფორმატში';
  },
  decimal: function (n, e) {
    void 0 === e && (e = []);
    var t = e[0];
    return (
      void 0 === t && (t = '*'), n + ' უნდა შეიცავდეს ციფრებსა და' + (t && '*' !== t ? ' ' + t : '') + ' მთელ რიცხვებს'
    );
  },
  digits: function (n, e) {
    return n + ' უნდა შეიცავდეს ციფრებს და უნდა იყოს ზუსტად ' + e[0] + '-ნიშნა';
  },
  dimensions: function (n, e) {
    return n + ' უნდა იყოს ' + e[0] + 'x' + e[1] + ' ზომის (pixel)';
  },
  email: function (n) {
    return n + '-ს უნდა ჰქონდეს ელ-ფოსტის სწორი ფორმატი';
  },
  excluded: function (n) {
    return n + ' უნდა იყოს სწორი მნიშვნელობა';
  },
  ext: function (n) {
    return n + ' უნდა იყოს ფაილი';
  },
  image: function (n) {
    return n + ' უნდა იყოს სურათი';
  },
  included: function (n) {
    return n + ' უნდა იყოს სწორი მნიშვნელობა';
  },
  ip: function (n) {
    return n + ' უნდა იყოს სწორი ip მისამართი';
  },
  max: function (n, e) {
    return n + ' არ უნდა იყოს ' + e[0] + ' სიმბოლოზე მეტი';
  },
  max_value: function (n, e) {
    return n + ' უნდა შეიცავდეს ' + e[0] + ' სიმბოლოს ან ნაკლებს';
  },
  mimes: function (n) {
    return n + 'ს უნდა ჰქონდეს სწორი ფაილის ფორმატი';
  },
  min: function (n, e) {
    return n + ' უნდა შეიცავდეს მინიმუმ ' + e[0] + ' სიმბოლოს';
  },
  min_value: function (n, e) {
    return n + ' უნდა შეიცავდეს ' + e[0] + ' ან მეტ სიმბოლოს';
  },
  numeric: function (n) {
    return n + ' უნდა შეიცავდეს ციფრებს';
  },
  regex: function (n) {
    return n + '-(ი)ს ფორმატი არასწორია';
  },
  required: function (n) {
    return n + ' აუცილებელია';
  },
  size: function (n, e) {
    return (
      n +
      ' უნდა იყოს ' +
      (function (n) {
        var e = 1024,
          t = 0 === (n = Number(n) * e) ? 0 : Math.floor(Math.log(n) / Math.log(e));
        return 1 * (n / Math.pow(e, t)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][t];
      })(e[0]) +
      '-ზე ნაკლები'
    );
  },
  url: function (n) {
    return n + '-(ი)ს არ აქვს სწორი მისამართის ფორმატი';
  },
};
