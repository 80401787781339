import storefrontInstance from '@/api/instances/storefront';

const bontragerCustom = {
  namespaced: true,
  state: {
    dataIsLoading: false,
    designData: {},
    savedDesigns: [],
    orderedDesigns: [],
    recipeId: null,
  },
  mutations: {
    setLoadingState(state, dataIsLoading) {
      state.dataIsLoading = dataIsLoading;
    },
    setDesignData(state, designData) {
      state.designData = designData;
    },
    setSavedDesigns(state, savedDesigns) {
      state.savedDesigns = savedDesigns;
    },
    setOrderedDesigns(state, orderedDesigns) {
      state.orderedDesigns = orderedDesigns;
    },
    setRecipeId(state, recipeId) {
      state.recipeId = recipeId;
    },
  },
  actions: {
    async fetchDesignData({commit}) {
      commit('setLoadingState', true); //turns on loading indicator until data returns
      try {
        await storefrontInstance.get(`/custom-product/`).then((response) => {
          if (response.status === 200) {
            let designData = response.data.data;
            let savedItems = designData.filter((data) => data.isOrdered === false);
            let orderedItems = designData.filter((data) => data.isOrdered === true);

            commit('setDesignData', designData);
            commit('setSavedDesigns', savedItems);
            commit('setOrderedDesigns', orderedItems);
            commit('setLoadingState', false);
          }
        });
      } catch (error) {
        console.error('fetchDesignData error:', error);
        return error;
      }
    },

    async deleteSavedDesign({state, dispatch}) {
      await storefrontInstance
        .delete(`/custom-product/${state.recipeId}`)
        .then(() => {
          dispatch('fetchDesignData');
        })
        .catch((error) => {
          console.error('deleteSavedDesign error:', error);
          return error;
        });
    },
  },
};

export default bontragerCustom;
