const ReCaptchaHelpers = () => {
  const getReCaptcha = () => window.grecaptcha;
  const asyncReCaptchaReady = () => new Promise((resolve) => getReCaptcha().ready(resolve));

  const getReCaptchaToken = async (reCaptchaSiteKey, config = {}) => {
    if (!getReCaptcha()) throw new Error('gReCaptcha library unavailable');
    await asyncReCaptchaReady();
    const token = await getReCaptcha()?.execute(reCaptchaSiteKey, config);
    return token;
  };

  const isReCaptchaAvailable = (isReCaptchaToggleEnabled) => isReCaptchaToggleEnabled && !!getReCaptcha();

  return {isReCaptchaAvailable, getReCaptchaToken};
};

export {ReCaptchaHelpers};
