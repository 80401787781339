import Vue from 'vue';
import findIndex from 'lodash/findIndex';

const compareModule = {
  namespaced: true,
  state: {
    racks: {},
    compareRackDialogVisible: false,
  },

  mutations: {
    addItem(state, {unitId, item}) {
      state.racks[unitId].push(item);
    },

    addRack(state, unitId) {
      Vue.set(state.racks, unitId, []);
    },

    removeItem(state, {unitId, itemIndex}) {
      state.racks[unitId].splice(itemIndex, 1);
    },

    emptyRackItems(state, unitId) {
      state.racks[unitId] = [];
    },

    setCompareRackDialog(state, payload) {
      state.compareRackDialogVisible = payload;
    },
  },

  actions: {
    addToRack({commit, rootState, state}, item) {
      const unitId = rootState.backend.unitId;
      if (state.racks[unitId]) {
        commit('addItem', {unitId: unitId, item});
      } else {
        commit('addRack', unitId);
        commit('addItem', {unitId: unitId, item});
      }

      toast({
        type: 'success',
        message: 'Item added',
      });
    },

    createRack({commit, rootState, state}) {
      const unitId = rootState.backend.unitId;
      if (!state.racks[unitId]) {
        commit('addRack', unitId);
      }
    },

    removeFromRack({commit, rootState, state}, item) {
      const unitId = rootState.backend.unitId;
      const itemIndex = findIndex(state.racks[unitId], {productCode: item.productCode});
      if (itemIndex > -1) {
        commit('removeItem', {unitId, itemIndex});
        toast({
          type: 'success',
          message: 'Item removed',
        });
      }
    },

    emptyRack({commit, rootState}) {
      const unitId = rootState.backend.unitId;
      commit('emptyRackItems', unitId);
      toast({
        type: 'success',
        message: 'Compare rack emptied',
      });
    },
  },

  getters: {
    currentRack(state, getters, rootState) {
      return state.racks[rootState.backend.unitId];
    },
  },
};

export default compareModule;
