const PdlToastType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const PDL_TOAST_METHOD = 'toast';

export {PdlToastType, PDL_TOAST_METHOD};
