<script>
import headTag from '@/utils/head-tag';

export default {
  name: 'TrekAvantyCheck',

  mounted() {
    const avmScriptId = 'avm-script';
    const avmScript = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.avmws.com/1015585/';
    headTag.appendAsyncScript(avmScriptId, avmScript);
  },

  render: () => null,
};
</script>
