<template>
  <div v-if="restorationErrorMessage" class="alert negative row expand">
    {{ $t(`basket.restoration.${restorationErrorMessage}`) }}
  </div>
</template>

<script>
import {handleCartRestorations} from '@/utils/cart/cart-modification-config';
import {useIsTranslationLoaded} from '@/composables/is-translation-loaded.js';
import {computed} from 'vue';

export default {
  props: {
    restorationErrorMessage: {
      type: String,
      default: '',
    },
    restorationData: {
      type: Object,
      default: () => ({}),
    },
    showModifications: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const restorationModifications = computed(() => {
      return props.restorationData?.modifications ?? [];
    });

    useIsTranslationLoaded(() => {
      if (!props.showModifications || !restorationModifications.value.length) return;
      handleCartRestorations(restorationModifications.value);
    });
  },
};
</script>
