import storefrontInstance from '@/api/instances/storefront';
import {DistrictRegions} from '@/utils/cart/delivery-address-util';
const baseUrl = 'https://api.mapbox.com';
const geocodingPath = 'geocoding/v5/mapbox.places';

export const MapboxGeocodeTypes = Object.freeze({
  POSTCODE: 'postcode',
  ADDRESS: 'address',
  COUNTRY: 'country',
  REGION: 'region',
  DISTRICT: 'district',
  PLACE: 'place',
  LOCALITY: 'locality',
  POI: 'poi',
});

const DEFAULT_TYPE_CONFIG = [
  MapboxGeocodeTypes.POSTCODE,
  MapboxGeocodeTypes.ADDRESS,
  MapboxGeocodeTypes.COUNTRY,
  MapboxGeocodeTypes.DISTRICT,
  MapboxGeocodeTypes.REGION,
  MapboxGeocodeTypes.PLACE,
  MapboxGeocodeTypes.LOCALITY,
].join(',');

async function getLocationObject(address, accessToken) {
  const geocodeData = await storefrontInstance.get(
    `${baseUrl}/${geocodingPath}/${address}.json?access_token=${accessToken}`
  );
  return geocodeData?.data?.features[0];
}

async function computeGeocodedLocation(address, accessToken) {
  const encodedAddress = encodeURIComponent(address);
  const geocodeData = await storefrontInstance.get(
    `${baseUrl}/${geocodingPath}/${encodedAddress}.json?access_token=${accessToken}`
  );
  return geocodeData?.data?.features[0]?.center;
}

async function getSearchSuggestionList({search, country, accessToken, types = DEFAULT_TYPE_CONFIG} = {}) {
  // available types: postcode,address,country,region,district,place,locality,poi
  // https://docs.mapbox.com/api/search/geocoding/#data-types

  const searchSuggestionResponse = await storefrontInstance.get(
    `${baseUrl}/${geocodingPath}/${search}.json?&types=${types}&access_token=${accessToken}${
      country ? `&country=${country}` : ''
    }`
  );

  const searchSuggestionData = searchSuggestionResponse.data.features.map((suggestion) => ({
    value: suggestion.place_name,
    id: suggestion.id,
    qaid: 'input-autocomplete-list-item',
    data: {
      center: suggestion.center,
    },
  }));

  return searchSuggestionData;
}

async function getRegion({search, country, accessToken} = {}) {
  const response = await storefrontInstance.get(
    `${baseUrl}/${geocodingPath}/${search}.json?access_token=${accessToken}${country ? `&country=${country}` : ''}`
  );
  const feature = response.data.features?.[0];
  if (DistrictRegions.includes(country?.toUpperCase())) {
    const district = feature?.context?.find((context) => context.id.includes(MapboxGeocodeTypes.DISTRICT));
    return {
      name: district?.text,
    };
  }
  const region = feature?.context?.find((context) => context.id.includes(MapboxGeocodeTypes.REGION));
  return {
    isocode: region?.short_code,
  };
}
async function getFeaturesFromPostalCode({postalCode, country, accessToken}) {
  const url = new URL(`${baseUrl}/${geocodingPath}/${postalCode}.json`);
  const searchParams = new URLSearchParams({
    types: MapboxGeocodeTypes.POSTCODE,
    country,
    access_token: accessToken,
  }).toString();

  const {data: results} = await storefrontInstance.get(`${url}?${searchParams}`);

  return results.features;
}
async function getFeaturesFromCoordinates({longitude, latitude, accessToken, country}) {
  const url = new URL(`${baseUrl}/${geocodingPath}/${longitude},${latitude}.json`);
  const searchParams = new URLSearchParams({
    types: MapboxGeocodeTypes.POSTCODE,
    country,
    access_token: accessToken,
  }).toString();

  const {data: results} = await storefrontInstance.get(`${url}?${searchParams}`);

  return results.features;
}
function getValidLocationFromFeatures(featureCollection, country) {
  const feature = featureCollection.find(({text, place_type}) => {
    return place_type.includes(MapboxGeocodeTypes.POSTCODE) && text;
  });
  const postcode = feature?.text;
  const longitude = feature?.center[0];
  const latitude = feature?.center[1];

  let regionCode = feature?.context?.find(({id}) => id.includes(MapboxGeocodeTypes.REGION))?.short_code;
  if (!regionCode) {
    regionCode = feature?.context?.find(({id}) => id.includes(MapboxGeocodeTypes.PLACE))?.short_code;
  }

  if (DistrictRegions.includes(country?.toUpperCase())) {
    regionCode = feature?.context?.find(({id}) => id.includes(MapboxGeocodeTypes.DISTRICT))?.text;
  }

  return {
    postcode,
    regionCode,
    latitude,
    longitude,
  };
}
async function getValidLocationFromPostcode({postalCode, country, accessToken}) {
  const featureCollection = await getFeaturesFromPostalCode({postalCode, country, accessToken});
  return getValidLocationFromFeatures(featureCollection, country);
}
async function getValidLocationFromCoordinates({longitude, latitude, accessToken, country}) {
  const featureCollection = await getFeaturesFromCoordinates({longitude, latitude, country, accessToken});
  return getValidLocationFromFeatures(featureCollection, country);
}

async function getAddressFromCoordinates({longitude, latitude, accessToken}) {
  if (!longitude || !latitude) {
    throw new Error('Need longitude and latitude to reverse geocode and get address.');
  }
  const addressFromReverseGeocode = await storefrontInstance.get(
    `${baseUrl}/${geocodingPath}/${longitude},${latitude}.json?access_token=${accessToken}`
  );
  return addressFromReverseGeocode?.data;
}

export {
  computeGeocodedLocation,
  getSearchSuggestionList,
  getRegion,
  getLocationObject,
  getAddressFromCoordinates,
  getValidLocationFromPostcode,
  getValidLocationFromCoordinates,
};
