<template>
  <transition name="fade" @before-enter="setupOverlay" @after-leave="unsetOverlay">
    <div v-show="isShown" qaid="nav-overlay-large-layout" class="nav-overlay-large-layout" :style="overlayHeight">
      <div
        :class="[
          'nav-overlay-large-layout__grid',
          {
            'has-card': hasCard,
          },
        ]"
      >
        <nav-tabs-layout qaid="nav-tabs-large-layout" />
        <nav-lists-layout qaid="nav-lists-large-layout" :has-card="hasCard" />
        <nav-card v-if="hasCard" />
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import NavTabsLayout from '@/navigation/layouts/NavTabsLayout.vue';
import NavListsLayout from '@/navigation/layouts/NavListsLayout.vue';
import NavCard from '@/navigation/components/NavCard.vue';
import throttle from 'lodash/throttle';

export default {
  name: 'NavOverlayLargeLayout',
  components: {
    NavTabsLayout,
    NavListsLayout,
    NavCard,
  },
  provide: {
    idSuffix: '-large',
  },
  computed: {
    ...mapState('global', ['isCompactView']),
    ...mapState('navigation', ['activeLink', 'activeTab', 'currentOverlay', 'overlayHeight']),
    isShown() {
      return !this.isCompactView && Boolean(this.activeLink);
    },
    hasCard() {
      return Boolean(this.currentOverlay?.tabs.find((tab) => tab.uid === this.activeTab)?.card);
    },
  },
  mounted() {
    window.addEventListener('resize', this.throttledResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.throttledResize);
    if (this.isShown) {
      this.unsetOverlay();
    }
  },
  methods: {
    ...mapActions('navigation', ['unsetNav', 'calculateOverlayHeight']),
    closeOnEsc(event) {
      if (this.isShown && event.key === 'Escape') {
        this.unsetOverlay();
      }
    },
    disableBodyScrolling() {
      document.body.style.overflow = 'hidden';
      window.addEventListener('keydown', this.closeOnEsc);
    },
    enableBodyScrolling() {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', this.closeOnEsc);
    },
    setupOverlay() {
      this.disableBodyScrolling();
      this.calculateOverlayHeight();
    },
    unsetOverlay() {
      this.enableBodyScrolling();
      this.unsetNav();
    },
    throttledResize: throttle(function () {
      this.calculateOverlayHeight();
    }, 250),
  },
};
</script>

<style scoped lang="scss">
.nav-overlay-large-layout {
  @apply bg-gray-100;
  @apply overflow-auto;

  z-index: 16000015;
  position: absolute;
  width: 100%;

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.15s;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  &__grid {
    @apply max-w-3xl;
    @apply mx-auto;
    @apply px-3;
    @apply py-6;

    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 4fr;

    &.has-card {
      grid-template-columns: 1fr 3fr 1fr;
    }
  }
}
</style>
