/**
 * All accepted item availability values per https://schema.org/ItemAvailability
 */
export const ItemAvailability = Object.freeze({
  BACK_ORDER: 'BackOrder',
  DISCONTINUED: 'Discontinued',
  IN_STOCK: 'InStock',
  IN_STORE_ONLY: 'InStoreOnly',
  LIMITED_AVAILABILITY: 'LimitedAvailability',
  ONLINE_ONLY: 'OnlineOnly',
  OUT_OF_STOCK: 'OutOfStock',
  PRE_ORDER: 'PreOrder',
  PRE_SALE: 'PreSale',
  SOLD_OUT: 'SoldOut',
});
