// Vue Plugins
import common from '@/mixins/common';
import trackGlobalEvents from '@/mixins/tracking/track-global-events';
import store from '@/store';
import {I18n, loadLanguageAsync} from '@/utils/i18n-setup-utils';
import vue from 'vue';
import tippy from 'vue-tippy';

// Vue Components
import compareRackContainer from '@/components/compare/CompareRackContainer';
import compareBar from '@/components/containers/plp/CompareBar';
import LiveChat from '@/components/LiveChat';
import SemRush from '@/components/scripts/SemRush';
import BackendProps from '@/components/BackendProps';
import TrekMarquee from '@/components/TrekMarquee';
import ImageComponent from '@/components/ImageComponent';
import RefLink from '@/components/ref-link/RefLink';
import TrekButton from '@/components/TrekButton';
import TrekFooter from '@/components/TrekFooter';
import TrekLink from '@/components/TrekLink';
import {PdlLink} from '@pedal/pdl-link';
import {PdlToast} from '@pedal/pdl-toast';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlBadge} from '@pedal/pdl-badge';
import {PdlSwatch} from '@pedal/pdl-swatch';
import {PdlTabs, PdlTabPane, PdlTabNav, PdlTabBar} from '@pedal/pdl-tabs';
import TrekAvantyCheck from '@/components/scripts/TrekAvantyCheck';
import StoreDetailsScript from '@/components/scripts/StoreDetailsScript';
import StoreHoursScript from '@/components/scripts/StoreHoursScript';
import RecaptchaTokenScript from '@/components/scripts/RecaptchaTokenScript';
import SearchInput from '@/components/header-search/SearchInput';
import CartRestoration from '@/components/cart/header/CartRestoration';

// Vue Nav Components
import NavCategoriesLink from '@/navigation/components/NavCategoriesLink';
import NavCompactContainer from '@/navigation/containers/NavCompactContainer';
import NavLargeContainer from '@/navigation/containers/NavLargeContainer';
import NavUtilityBarB2cContainer from '@/navigation/containers/NavUtilityBarB2cContainer';
import NavUtilityBarControl from '@/navigation/components/NavUtilityBarControl';
import NavCategoriesLayout from '@/navigation/layouts/NavCategoriesLayout';
import NavOverlayCompactLayout from '@/navigation/layouts/NavOverlayCompactLayout';
import NavApp from '@/navigation/NavApp';

// Vue components used site-wide
import {nonCriticalCssLoader} from '@/entryPoints/lib/non-critical-css-loader';
import emailSignup from '@/modules/EmailSignup';
import explodingAccordion from '@/modules/explodingAccordion';
import geoPrompt from '@/modules/geoPrompt';
import loadingIndicator from '@/modules/loadingIndicator';
import menuSelector from '@/modules/MenuSelector';
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';

// Utils
import toggler from '@/modules/Toggler';
import {updateASMHeader} from '@/utils/asmAdjustments';
import '@/utils/axios/index';

// Directives
import DebugHighlight from '@/directives/debug-highlight.directive';

//Misc
import editorialHighlightComponent from '@/modules/editorialHighlightComponent';
import accordionComponent from '@/modules/accordionComponent.js';

//vuex store
import {mapActions, mapState} from 'vuex';
import AuthHelpers from '@/utils/auth-helpers.js';

class ShellBase {
  /**
   * Initialize a new instance of the ShellBase class.
   * @param pageComponents
   */
  constructor(pageComponents = {}, vueRouter = {}) {
    this.vue = vue;
    this.store = store;
    this.pageComponents = pageComponents;
    this.router = vueRouter;

    vue.config.devtools = !window.ACC.config.isProduction;
    vue.config.silent = window.ACC.config.isProduction;
    vue.config.ignoredElements = ['apple-pay-button'];

    // Directives
    vue.directive('debug-highlight', DebugHighlight);
  }

  /**
   * Register a list of components in a global context.
   * @param components
   */
  registerVueComponents(components) {
    if (!components || !Object.keys(components).length) {
      return;
    }

    Object.keys(components).forEach((name) => {
      const component = components[name];
      this.vue.component(name, component.default || component);
    });
  }

  /**
   * Initialize Vue
   */
  async initializeVue() {
    const userEmail = store.state?.user?.userEmail;
    const customer = store.state?.user?.customer;
    const authHelpers = AuthHelpers({userEmail, customer}, store.dispatch, store.commit);

    await authHelpers.handleLogin();

    this.loadPlugins();
    const vm = new vue({
      router: this.router,
      store,
      i18n: I18n,
      el: '#app',
      components: {
        ...this.pageComponents,
        compareBar,
        compareRackContainer,
        BackendProps,
        TrekButton,
        TrekLink,
        TrekFooter,
        RefLink,
        NavApp,
        NavLargeContainer,
        NavCategoriesLayout,
        NavCategoriesLink,
        NavCompactContainer,
        NavOverlayCompactLayout,
        NavUtilityBarB2cContainer,
        NavUtilityBarControl,
        PdlLink,
        PdlIcon,
        PdlBadge,
        PdlSwatch,
        PdlTabs,
        PdlTabPane,
        PdlTabNav,
        PdlTabBar,
        LiveChat,
        SemRush,
        TrekAvantyCheck,
        StoreDetailsScript,
        StoreHoursScript,
        RecaptchaTokenScript,
        SearchInput,
        ImageComponent,
        PdlSectionHeader,
        PdlHeading,
        PdlSubheading,
        TrekMarquee,
        CartRestoration,
      },
      mixins: [trackGlobalEvents],

      data() {
        return {
          isLoaded: false,
          unsubscribe: undefined,
          mobileMenuOpened: false,
        };
      },

      beforeMount() {
        loadLanguageAsync(window.ACC.languageISO).then(() => {
          this.isLoaded = true;
        });
      },
      created() {
        window.addEventListener('resize', this.handleResize);
        this.getCurrentBreakpoint();
      },

      beforeDestroy() {
        this.unsubscribe();
      },

      destroyed() {
        window.removeEventListener('resize', this.handleResize);
      },
      computed: {
        ...mapState('global', ['headerHeight']),
      },
      watch: {
        headerHeight() {
          document.documentElement.style.scrollPaddingTop = this.headerHeight + 'px';
        },
      },
      methods: {
        ...mapActions('viewSize', ['handleResize', 'getCurrentBreakpoint']),
        toast(options) {
          return this.$notify(options);
        },

        openMobileMenu() {
          this.mobileMenuOpened = !this.mobileMenuOpened;
        },
      },
    });

    window.store = store;
    window.toast = vm.toast;
    window.vm = vm;

    authHelpers.initSelectedRetailer();
    authHelpers.initSelectedLocation();
  }

  loadPlugins() {
    // Load common plugins here
    vue.mixin(common);
    vue.prototype.$notify = PdlToast;
    this.loadTippy();
  }

  preLoadInits() {
    nonCriticalCssLoader();
  }

  globalDocumentReadyInits() {
    // adding a global loading indicator
    window.loadingIndicator = loadingIndicator();

    // Enables top nav interactions
    menuSelector($(document));

    // ASM Adjustments
    updateASMHeader();

    // Collapsing blocks handler
    const togglers = $('[data-toggler]');
    togglers.each((i, t) => {
      toggler($(t));
    });

    // Geo Prompt
    if ($('.geo-prompt-modal').length) {
      geoPrompt();
    }

    // Footer Email Sign Up Script
    emailSignup();
  }

  globalOnLoadInits() {
    $(window).trigger('resize');

    // Footer expanding accordions in mobile
    const explodingAccordions = $(document).find('[data-accordion-explode]');
    explodingAccordions.each((i, t) => {
      explodingAccordion($(t));
    });

    // Footer hide shop links in kiosk mode
    if ($('#isKioskMode').length) {
      $('#footerRetailerLink').parent().hide();
      $('#retailersFooterLinkElectra').parent().hide();
    }

    //Check if editorialHighLightComponet is present in page
    if ($('.js-editorial-highlight-component').length) {
      editorialHighlightComponent();
    }

    //Check if accordionComponent is present on page
    if ($('.accordion-component').length) {
      accordionComponent();
    }
  }

  loadTippy() {
    this.vue.use(tippy, {
      animateFill: false,
      arrow: true,
      theme: 'light',
      flipDuration: 0,
      flip: true,
      flipBehavior: ['top', 'right', 'bottom'],
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: true,
          },
        },
      },
    });
  }
}

export {ShellBase};
