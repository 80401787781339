import {PaymentProvider} from '@/constants/payment-provider.js';
import StoredPaymentHandler from '@/utils/checkout/payment-handlers/stored-payment-handler.js';

const CheckoutPaymentHandler = {
  init: (selectedPaymentProvider) => {
    switch (selectedPaymentProvider.value) {
      case PaymentProvider.STORED_PAYMENT:
        StoredPaymentHandler.handlePayment();
        break;
      default:
        throw new Error(`Unknown payment provider ${selectedPaymentProvider.value}`);
    }
  },
};

export default CheckoutPaymentHandler;
