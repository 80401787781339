import {UnitTypes} from '@/constants/unit-types';
import distance from '@turf/distance';
import {convertMetersToDistanceUnit, convertMetersToElevationUnit} from '@/utils/unit-converter';

function calculateMaxGrade(elevationChartArray) {
  return Math.max(...elevationChartArray?.map((point) => point?.segmentMaxGrade));
}

function calculateMaxAlititude(elevationChartArray) {
  return Math.max(...elevationChartArray?.map((point) => point?.segmentMaxAltitude));
}

function calculateTotalElevationGain(elevationChartArray) {
  return elevationChartArray?.reduce(function (acc, obj) {
    return acc + obj?.segmentElevationGain;
  }, 0);
}

function calculateMinAltitude(elevationChartArray) {
  return Math.min(...elevationChartArray.flatMap((data) => data.elevationPoints.map((point) => point?.elevation)));
}

function createElevationDataObject(elevationChartData) {
  if (!elevationChartData.length) return null;
  const object = {
    maxGrade: calculateMaxGrade(elevationChartData),
    maxAltitude: calculateMaxAlititude(elevationChartData),
    totalElevationGain: calculateTotalElevationGain(elevationChartData),
    minAltitude: calculateMinAltitude(elevationChartData),
    elevationChartData,
  };
  return object;
}

//to convert chartData to chartArray for highcharts
function convertChartDataToPoints(elevationChartData, userPreferredUnit) {
  let totalDistance = 0;
  return elevationChartData?.flatMap((segment) => {
    return segment?.elevationPoints?.map((point) => {
      totalDistance += point?.distance;
      const convertedTotalDistance = convertMetersToDistanceUnit(totalDistance, userPreferredUnit);

      const elevationInPreferredUnits = convertMetersToElevationUnit(point?.elevation, userPreferredUnit);

      return {
        geometry: [point?.lng, point?.lat],
        elevation: [convertedTotalDistance, elevationInPreferredUnits],
      };
    });
  });
}

function calculateElevationChartRange(yAxisMax, yAxisMin, userPreferredUnit) {
  let yAxisTickLabel = 0;

  const elevationRange = yAxisMax - yAxisMin;

  const firstQuadrantLimit = UnitTypes.IMPERIAL.milesString === userPreferredUnit ? 164 : 50;
  const secondQuadrantLimit = UnitTypes.IMPERIAL.milesString === userPreferredUnit ? 656 : 200;
  const thirdQuadrantLimit = UnitTypes.IMPERIAL.milesString === userPreferredUnit ? 1640 : 500;

  switch (true) {
    case elevationRange < firstQuadrantLimit:
      yAxisTickLabel = Math.ceil(yAxisMin) + elevationRange * 4;
      break;
    case elevationRange < secondQuadrantLimit:
      yAxisTickLabel = Math.ceil(yAxisMin) + elevationRange * 2;
      break;
    case elevationRange < thirdQuadrantLimit:
      yAxisTickLabel = Math.ceil(yAxisMin) + elevationRange * (4 / 3);
      break;
    default:
      yAxisTickLabel = Math.ceil(yAxisMin) + elevationRange;
  }

  return yAxisTickLabel;
}

function getClosestPointInSeries(point, series) {
  let closestPoint = [];
  series?.forEach((chartSeries) => {
    closestPoint = chartSeries?.data.reduce(
      (prev, current) => {
        const currentFeature = current.geometry;
        const distanceValue = distance(point, currentFeature);
        return distanceValue < prev.distance ? {point: current, distance: distanceValue} : prev;
      },
      {point: null, distance: Infinity}
    );
  });
  return closestPoint;
}

export {
  createElevationDataObject,
  convertChartDataToPoints,
  calculateElevationChartRange,
  getClosestPointInSeries,
  calculateMinAltitude,
};
