const Cookie = {
  /**
   * Set a cookie.
   * @param name
   * @param value
   * @param expiresInMinutes
   * @param path
   */
  set(name, value, expiresInMinutes = 0, path = '/') {
    if (!name || value === null) {
      return;
    }

    let expiration = '';
    if (expiresInMinutes > 0) {
      const date = new Date();
      date.setTime(date.getTime() + expiresInMinutes * 60000);
      expiration = '; expires=' + date.toGMTString();
    }

    let cookie = `${name}=${value}${expiration}; SameSite=Lax`;
    if (path) {
      cookie = `${cookie}; path=${path}`;
    }

    document.cookie = cookie;
  },

  /**
   * Remove cookie by its name.
   * @param name
   * @param path
   */
  remove(name, path = '/') {
    let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    if (path) {
      cookie = `${cookie}; path=${path};`;
    }

    document.cookie = cookie;
  },

  /**
   * Get document cookie by name.
   * @param name
   * @returns {*}
   */
  get(name) {
    let value = '; ' + document.cookie;
    let parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  },
};

export {Cookie};
