export default {
  _default: function (e) {
    return e + ' baliogabea da';
  },
  after: function (e, a) {
    var n = a[0];
    return e + '(e)k honen ondoren egon behar du ' + (a[1] ? 'edo honen berdina izan ' : '') + n;
  },
  alpha: function (e) {
    return e + ' eremuak soilik karaktere alfabetikoak eduki ditzake';
  },
  alpha_dash: function (e) {
    return e + ' eremuak karaktere alfanumerikoak, marrak eta azpimarrak eduki ditzake';
  },
  alpha_num: function (e) {
    return e + ' eremuak soilik karaktere alfanumerikoak eduki ditzake';
  },
  alpha_spaces: function (e) {
    return e + ' eremuak soilik karaktere alfanumerikoak eta zuriuneak eduki ditzake';
  },
  before: function (e, a) {
    var n = a[0];
    return e + '(e)k honen aurretik egon behar du ' + (a[1] ? 'edo honen berdina izan ' : '') + n;
  },
  between: function (e, a) {
    return e + ' eremuak ' + a[0] + ' eta ' + a[1] + ' artean egon behar du';
  },
  confirmed: function (e) {
    return e + ' berrespenak ez datoz bat';
  },
  credit_card: function (e) {
    return e + ' eremua baliogabea da';
  },
  date_between: function (e, a) {
    return e + '(e)k ' + a[0] + ' eta ' + a[1] + ' artean egon behar du';
  },
  date_format: function (e, a) {
    return e + '(e)k ' + a[0] + ' formatuan egon behar du';
  },
  decimal: function (e, a) {
    void 0 === a && (a = []);
    var n = a[0];
    return (
      void 0 === n && (n = '*'),
      e + ' eremuak zenbakizkoa izan behar du eta ' + (n && '*' !== n ? n : '') + ' dezimal izan ditzake'
    );
  },
  digits: function (e, a) {
    return e + ' eremuak zenbakizkoa izan behar du eta zehazki ' + a[0] + ' digitu izan behar ditu';
  },
  dimensions: function (e, a) {
    return e + ' eremuak ' + a[0] + ' pixel bider ' + a[1] + ' pixel izan behar du';
  },
  email: function (e) {
    return e + ' eremuak baliozko helbide elektroniko bat izan behar du';
  },
  excluded: function (e) {
    return e + ' eremuak baliozko balio bat izan behar du';
  },
  ext: function (e) {
    return e + ' eremuak baliozko fitxategi bat izan behar du';
  },
  image: function (e) {
    return e + ' eremuak irudi bat izan behar du';
  },
  included: function (e) {
    return e + ' eremuak baliozko balio bat izan behar du';
  },
  integer: function (e) {
    return e + ' eremuak zenbaki oso bat izan behar du';
  },
  ip: function (e) {
    return e + ' eremuak baliozko IP helbide bat izan behar du';
  },
  length: function (e, a) {
    var n = a[0],
      r = a[1];
    return r
      ? e + '(r)en luzerak ' + n + ' eta ' + r + ' artean egon behar du'
      : e + '(r)en luzerak ' + n + ' izan behar du';
  },
  max: function (e, a) {
    return e + ' eremuak ezin ditu ' + a[0] + ' karaktere baino gehiago izan';
  },
  max_value: function (e, a) {
    return e + ' eremuak ' + a[0] + ' edo gutxiago izan behar du';
  },
  mimes: function (e) {
    return e + ' eremuak baliozko fitxategi-mota bat izan behar du';
  },
  min: function (e, a) {
    return e + ' eremuak gutxienez ' + a[0] + ' karaktere izan behar ditu';
  },
  min_value: function (e, a) {
    return e + ' eremuak ' + a[0] + ' edo gehiago izan behar du';
  },
  numeric: function (e) {
    return e + ' eremuak zenbakizko karaktereak soilik izan ditzake';
  },
  regex: function (e) {
    return e + ' eremuaren formatua baliogabea da';
  },
  required: function (e) {
    return e + ' eremua derrigorrezkoa da';
  },
  size: function (e, a) {
    return (
      e +
      '(e)n tamainak ' +
      (function (e) {
        var a = 1024,
          n = 0 === (e = Number(e) * a) ? 0 : Math.floor(Math.log(e) / Math.log(a));
        return 1 * (e / Math.pow(a, n)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][n];
      })(a[0]) +
      ' baino txikiagoa izan behar du'
    );
  },
  url: function (e) {
    return e + ' eremua ez da baliozko URL bat';
  },
};
