import isEmpty from 'lodash/isEmpty';
import {computed, watch} from 'vue';

export function useIsTranslationLoaded(callback) {
  const isTranslationLoaded = computed(() => {
    return !isEmpty(window.VueI18n.messages);
  });

  watch(
    isTranslationLoaded,
    (value) => {
      if (value) callback();
    },
    {immediate: true}
  );
}
