export const injectForm = (formText) => {
  if (!formText) throw new Error("No form text - can't create form");
  const formDoc = new DOMParser().parseFromString(formText, 'text/html');
  const form = formDoc.forms?.[0];
  document.body.append(form);
  return form;
};

export const getBillingAddressData = () => {
  const billingAddressData = [
    'firstName',
    'lastName',
    'line1',
    'line2',
    'townCity',
    'regionIso',
    'postcode',
    'phone',
  ].reduce((obj, curr) => {
    obj[curr] = document.querySelector(`#${curr}`)?.value;
    return obj;
  }, {});

  return billingAddressData;
};
