import storefrontInstance from '@/api/instances/storefront';
import Vue from 'vue';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';

const claimLine = {
  id: '1', //static id required
  productType: 'Bikes',
  qty: null,
  serialNumber: '',
  status: '',
  size: '',
  sku: '',
  modelYear: '',
  modelName: '',
  modelYearList: [],
  warrantyOptionalAttachments: [],
};

const rootUrl = '/feedback';
const productFeedback = {
  namespaced: true,

  state: {
    isLoading: true,
    feedbackData: {},
    validators: [],
    showFeedbackDetails: false,
    showErrorCallout: false,
    isSerialNumberPristine: true,
  },
  mutations: {
    setFeedbackData(state, payload) {
      Vue.set(state, 'feedbackData', payload);
    },
    updateFeedbackProperty(state, {propName, value}) {
      state.feedbackData.claimLines[0][propName] = value;
    },
    updateFlag(state, {flagName, value}) {
      state[flagName] = value;
    },
    updateUserData(state, {propName, value}) {
      state.feedbackData[propName] = value;
    },
  },
  actions: {
    fetchFeedbackData({commit}) {
      commit('updateFlag', {flagName: 'isLoading', value: true});
      return storefrontInstance.get(`${rootUrl}/`).then((response) => {
        if (response?.data?.data?.claimLines.length == 0) {
          response.data.data.claimLines.push(claimLine);
          commit('setFeedbackData', response.data.data);
          commit('updateFlag', {flagName: 'isLoading', value: false});
        }
      });
    },
    saveProcessedFeedback({commit, state}, {validate}) {
      // Destructure state to only the properties of it we need
      commit('updateFlag', {flagName: 'isLoading', value: true});
      const {feedbackData} = state;
      const payload = {...feedbackData};
      payload.claimLines[0].warrantyOptionalAttachments = feedbackData.claimLines[0].warrantyOptionalAttachments.filter(
        (el) => el
      );

      const params = {
        validate: validate || false,
        manual: false,
      };
      return storefrontInstance
        .post(`${rootUrl}/`, payload, {params})
        .then((submitResponse) => {
          if (checkMetaFeedbackType(submitResponse.data, MetaFeedbackType.SUCCESS)) {
            commit('setFeedbackData', submitResponse.data.data);

            return submitResponse;
          }
        })
        .finally(() => {
          commit('updateFlag', {flagName: 'isLoading', value: false});
        });
    },
  },
};

export default productFeedback;
