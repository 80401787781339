<template>
  <div class="mini-cart-item text-left mb-3">
    <h4 class="mb-1 font-bold">
      <a :id="`cart-popup-name-${productCode}`" :href="url" v-text="name" />
    </h4>
    <pdl-media-object>
      <div slot="media">
        <image-component
          :id="`cart-popup-img-${productCode}`"
          :css-class="'mini-cart-item__image'"
          :alt-text="altText"
          :title="altText"
          aspect-ratio="4x3"
          :asset-id="productImage"
          transformation="pad"
          img-format="pjpg"
          :is-responsive="true"
        />
      </div>

      <div slot="content">
        <div v-for="category in variantMatrix" :key="category.parentVariantCategory.name" class="text-sm">
          <span class="font-bold" v-text="category.parentVariantCategory.name" />:
          <span v-text="category.variantValueCategory.name" />
        </div>
        <div>
          <span class="text-sm font-bold" v-text="$t('popup.cart.quantity')" />:
          <span v-text="quantity" />
        </div>
        <div class="text-sm mt-25">
          <div v-if="isLineLevelPromotionPresent" class="text-sm font-bold text-red">
            <span v-text="formattedAdjustedUnitPrice" />&nbsp;
            <span class="text-gray-100 line-through" v-text="beforeSalePrice" />
          </div>
          <div
            v-else
            :class="[
              'order-tile-checkout__definition',
              'font-extrabold',
              'text-sm',
              {'text-red': Boolean(formattedCalculableAdvertised)},
            ]"
          >
            <span v-text="basePrice" />&nbsp;
            <span v-if="formattedCalculableAdvertised" class="beforeSalePrice" v-text="formattedCalculableAdvertised" />
          </div>
        </div>
      </div>
    </pdl-media-object>
    <div v-if="prioritizedDate.length" class="mt-2">
      <pdl-callout>
        <template #content>
          <span qaid="b2c-mini-cart-availability" class="font-medium">
            {{ $t('text.estimatedAvailability') }} {{ prioritizedDate }}
          </span>
        </template>
      </pdl-callout>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlMediaObject} from '@pedal/pdl-media-object';
import ImageComponent from '@/components/ImageComponent.vue';
import NumberFormatter from '@/utils/NumberFormatter';

const numberFormatter = new NumberFormatter({priceFormatter: window.ACC.customVariables.priceFormatter});

export default {
  components: {PdlCallout, PdlMediaObject, ImageComponent},
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'azureCdnImagesUrl']),
    prioritizedDate() {
      if (!this?.item?.futureStock) return '';

      const {startDate, endDate} = this.item.futureStock;
      return `${startDate} - ${endDate}`;
    },
    formattedAdjustedUnitPrice() {
      if (!this?.item?.adjustedUnitPrice) return;
      return numberFormatter.formatPrice(this?.item?.adjustedUnitPrice);
    },
    formattedCalculableAdvertised() {
      if (!this?.item?.product?.price?.calculableAdvertised) return;
      return numberFormatter.formatPrice(this?.item?.product?.price?.calculableAdvertised);
    },
    basePrice() {
      if (!this?.item?.basePrice?.value) return;
      return numberFormatter.formatPrice(this?.item?.basePrice?.value);
    },
    beforeSalePrice() {
      const basePrice = this?.item?.basePrice;
      return basePrice?.value > 0 ? this.basePrice : 'N/A';
    },
    isLineLevelPromotionPresent() {
      return this?.item?.isLineLevelPromotionPresent;
    },
    formattedPrice() {
      if (!this?.item?.product?.price?.value) return;
      return numberFormatter.formatPrice(this?.item?.product?.price?.value);
    },
    variantMatrix() {
      return this?.item?.product?.variantMatrix ?? [];
    },
    quantity() {
      return this?.item?.quantity;
    },
    name() {
      return this?.item?.product?.name;
    },
    productCode() {
      return this?.item?.product?.code;
    },
    altText() {
      const altText = this?.item?.product?.images?.[0]?.altText ?? '';
      return altText.length > 0 ? altText : this.name;
    },
    productImage() {
      const missingProductImage = `${this.azureCdnImagesUrl}/missing-product-300x300.jpg`;
      const url = this?.item?.product?.images?.[0]?.url ?? '';
      return url.length > 0 ? url : missingProductImage;
    },
    url() {
      const {item, encodedContextPath} = this;
      return encodedContextPath + item?.product?.url;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .mini-cart-item__image {
  max-width: 9rem;
}
</style>
