const DeliveryAddressTemplate = {
  firstName: 'Sample',
  lastName: 'Consumer',
  line1: '123 Placeholder',
  line2: 'Apt 1',
  town: 'Somewhereville',

  postalCode: '87123',
  country: {
    isocode: 'US',
  },
};
//Ecommerce locales that require regions
const DefaultRegions = {
  JP: 'JP-01',
};
const DistrictRegions = ['GB'];

const getRegion = function (location, countryIso) {
  if (DistrictRegions.includes(countryIso)) {
    return {
      name: location?.regionCode,
    };
  }
  if (DefaultRegions[countryIso] && !location?.regionCode) {
    return {
      isocode: DefaultRegions[countryIso],
    };
  }
  return {
    isocode: location?.regionCode,
  };
};
const populateAddress = async function (countryCode, location) {
  let countryIso = countryCode?.toUpperCase();
  let address = {
    ...DeliveryAddressTemplate,
    postalCode: location?.postcode,
    country: {
      isocode: countryIso,
    },
  };
  let region = getRegion(location, countryIso);
  if (region?.isocode || region?.name) {
    address.region = region;
  }

  return address;
};
export {populateAddress, DistrictRegions};
