import {currencyToString} from './util';
import get from 'lodash/get';

export function formatAppleData(productArray = [], options = {}) {
  const uidProperty = options.uidProperty || 'sku';

  return productArray
    .filter((product) => product?.prices?.appleLabels || product?.appleLabels)
    .map((entry) => {
      const priceData = entry.prices || entry;
      return {
        // Consumer price object already formatted as entry.consumerPrice in BulkProductGridTabs
        advertisedPrice: priceData.consumerPrice ?? getConsumerPrice(entry.price || {}),
        id: entry[uidProperty],
        msrp: priceData.consumerPrice ? get(priceData, 'consumerPrice.advertised.low.formatted') : entry.price?.msrp,
        retailLabelDescription: priceData.appleLabels.retailLabelDescription || '',
        retailLabelPrice: currencyToString(priceData.appleLabels.retailLabelPrice || ''),
        retailLabelProductId: priceData.appleLabels.retailLabelProductId || '',
        retailLabelStatus: priceData.appleLabels.retailLabelStatus || false,
        sku: entry[uidProperty],
      };
    });
}

function getConsumerPrice(price) {
  return {
    advertised: {
      low: {
        formatted: price.msrp,
      },
    },
    saleAdvertised: {
      low: {
        formatted: price.saleAdvertised,
      },
    },
  };
}
