'use strict';

/**
 * This runs only on page load when there is any editorialHighLightComponent present in the page.
 * Upon running, this set three variables, and set a CSS variable '--header-height' if a Hash is present in url.
 */
export default function () {
  const urlHash = window.location.hash;
  const headerHeight = document.querySelector('#trek-header').offsetHeight;
  const hashElement = urlHash && document.querySelector(urlHash);

  if (hashElement) {
    hashElement.style.setProperty('--header-height', headerHeight + 'px');
    hashElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
