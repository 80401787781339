import {helpers} from '@vuelidate/validators';
// This regular expression defines a password is at least 8 characters long, no spaces, and must contain one each of the following:
// one digit (0-9), one lowercase letter (a-z), one uppercase letter (A-Z), and one special character (~!@$%^&*()_+`-=[]{}\|<>?,./"'#:;).
let passwordRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\W])(?=\\S+$).{8,}$');
const password = helpers.regex(passwordRegex);

const heightRemainder = (value) => {
  return !/\D/.test(value) || /\s/.test(value);
};

const bodyParams = (value) => {
  if (
    /\D/.test(value.trim()) ||
    ((/\s/.test(value) || parseInt(value) === 0) && (parseInt(value) === 0 || /\D/.test(value.trim())))
  ) {
    return false;
  }
  return true;
};

const greaterThanZero = (value) => parseInt(value) > 0;

const numberOtherThanZero = (value) => {
  return typeof value === 'number' && value !== 0;
};

export {password, heightRemainder, bodyParams, greaterThanZero, numberOtherThanZero};
