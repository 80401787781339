/**
 * Helper to export vuex actions, mutations.
 * Returns an object of string function calls.
 * @param {Object} typesObj
 * @param {String} moduleReference
 * @returns {Object} - 'checkout/setIsCheckoutDialogVisible'
 */
export const mutationAndActionHelper = (typesObj = {}, moduleReference = '') => {
  return Object.entries(typesObj).reduce((obj, [key, value]) => {
    obj[key] = `${moduleReference}/${value}`;
    return obj;
  }, {});
};
