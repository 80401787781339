<template>
  <component :is="tag" :class="{...buttonClasses, ...classes}" v-bind="$attrs" v-on="$listeners">
    <pdl-icon v-if="icon" :name="icon" aria-hidden="true" />
    <span v-if="!iconOnly">
      <slot />
    </span>
  </component>
</template>

<script>
import buttonMixin from '@/mixins/button';

export default {
  name: 'TrekLink',
  mixins: [buttonMixin],
  inheritAttrs: false,
  props: {
    button: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'button--disabled': this.button && this.disabled,
        'underline hover:no-underline': !this.button && this.underline,
      };
    },
    tag() {
      return this.disabled ? 'span' : 'a';
    },
  },
};
</script>
