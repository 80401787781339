import storefrontInstance from '@/api/instances/storefront';
import ProductGroups from '@/components/containers/bulk-product/utils/product-groups';

const preSeason = {
  namespaced: true,
  state: {
    shipments: [],
    currentShipment: {},
    validators: [],
    shipmentsGrid: {},
    orderFormType: null,
    orderFormTitle: null,
    updatedShipments: {},
    orderReview: {},
    dataIsLoading: false,
    updateShipmentDetails: null,
    showSuccessAlert: {successOnOverview: false, successOnPreSeasonOrders: false},
    bulkOrderFormId: '',
    orderFormLeftNavigation: [],
    shipmentListMaxLength: 6,
    orderSummaryOverview: {},
    modelSummary: {},
    invalidSkuList: {},
    showErrorCallout: false,
  },
  getters: {
    shipments: (state) => state.shipments,
    validators: (state) => state.validators,
    orderFormType: (state) => state.orderFormType,
    shipmentsGrid: (state) => state.shipmentsGrid,
    orderFormTitle: (state) => state.orderFormTitle,
    updatedShipments: (state) => state.updatedShipments,
    orderReview: (state) => state.orderReview,
    dataIsLoading: (state) => state.dataIsLoading,
    updateShipmentDetails: (state) => state.updateShipmentDetails,
    successOnOverview: (state) => state.showSuccessAlert.successOnOverview,
    successOnPreSeasonOrders: (state) => state.showSuccessAlert.successOnPreSeasonOrders,
    orderFormLeftNavigation: (state) => state.orderFormLeftNavigation,
    bulkOrderFormId: (state) => state.bulkOrderFormId,
    shipmentListMaxLength: (state) => state.shipmentListMaxLength,
    orderSummaryOverview: (state) => state.orderSummaryOverview,
    modelSummary: (state) => state.modelSummary,
    invalidSkuList: (state) => state.invalidSkuList,
    showErrorCallout: (state) => state.showErrorCallout,
  },
  mutations: {
    fetchShipmentsGrid(state, payload) {
      state.shipmentsGrid = payload;
    },
    setShipments(state, payload) {
      state.shipments = payload;
    },
    deleteShipment(state, shipmentNumber) {
      state.shipments.splice(shipmentNumber, 1);
    },
    addShipment(state, payload) {
      state.shipments.push(payload);
    },
    updateValidators(state, validator) {
      state.validators.push(validator);
    },
    setOrderFormType(state, orderFormType) {
      state.orderFormType = orderFormType;
    },
    setOrderFormTitle(state, orderFormTitle) {
      state.orderFormTitle = orderFormTitle;
    },
    updateShipment(state, variant) {
      state.updatedShipments[variant.updatedShipment.gridIndex][variant.variantIndex].updatedShipments =
        variant.updatedShipment.updatedShipments;
    },
    addUpdateShipment(state, updatedShipment) {
      state.updatedShipments[updatedShipment.gridIndex].push(updatedShipment);
    },
    fetchReview(state, payload) {
      state.orderReview = payload;
    },
    setLoadingState(state, dataIsLoading) {
      state.dataIsLoading = dataIsLoading;
    },
    updateShipmentDetails(state, updateShipmentDetails) {
      state.updateShipmentDetails = updateShipmentDetails;
    },
    showSuccessOnOverview(state, successOnOverview) {
      state.showSuccessAlert.successOnOverview = successOnOverview;
    },
    showSuccessOnPreSeasonOrders(state, successOnPreSeasonOrders) {
      state.showSuccessAlert.successOnPreSeasonOrders = successOnPreSeasonOrders;
    },
    fetchLeftNavigationList(state, navigationList) {
      state.orderFormLeftNavigation = navigationList;
    },
    setBulkOrderFormId(state, bulkOrderFormId) {
      state.bulkOrderFormId = bulkOrderFormId;
    },
    setShipmentListMaxLength(state, initialShipmentLength) {
      state.shipmentListMaxLength = initialShipmentLength;
    },
    setSummaryOverview(state, payload) {
      state.orderSummaryOverview = payload;
    },
    setModelSummary(state, payload) {
      state.modelSummary = payload;
    },
    fetchConfirmation(state, payload) {
      state.orderReview = payload;
    },
    fetchInvalidSkulist(state, payload) {
      state.invalidSkuList = payload;
    },
    updateShipmentItem(state, payload) {
      const updatedItemIndex = state.shipments.findIndex((item) => item.id === payload.id);
      state.shipments[updatedItemIndex] = payload;
    },
    updateFlag(state, {flagName, value}) {
      state[flagName] = value;
    },
  },
  actions: {
    async fetchShipments({commit}, orderFormType) {
      const shipmentData = await storefrontInstance
        .get(`pre-season-orders-api/shipments/${orderFormType}/`)
        .then(({data}) => data.data);
      const shipmentList = shipmentData.orderShipments.map((shipment) => ({
        ...shipment,
        immediateOrder: !!shipment.immediateOrder,
        customerNotes: shipment.customerNotes || '',
        id: window.crypto.getRandomValues(new Uint16Array(1))[0],
      }));

      const isShipmentFormEditable = shipmentData.isShipmentFormEditable || false;
      commit('setShipments', shipmentList);
      commit('updateShipmentDetails', isShipmentFormEditable);
      commit('setShipmentListMaxLength', shipmentList.length);
    },
    async fetchShipmentsGrid({commit}, formId) {
      const records = await storefrontInstance
        .get(`pre-season-orders-api/${formId}`)
        .then(({data}) => data?.data?.results || []);
      const productGroups = records.map((product) => new ProductGroups(product).getProductGroups()).flat();

      productGroups.forEach((group) => {
        group.products.forEach((product) => {
          product.shipments = {};
          for (let category of product.categorization) {
            product[category.name] = category.value;
          }
        });
      });

      if (records.length) commit('setOrderFormTitle', records[0].bulkOrderFormTitle);

      commit('fetchShipmentsGrid', productGroups);
    },
    saveShipment({commit, state}) {
      const shipmentData = {
        preseasonOrderType: state.orderFormType,
        orderShipments: state.shipments, // This includes all order data
      };
      return storefrontInstance
        .put(`pre-season-orders-api/save-shipments`, shipmentData)
        .then((submitResponse) => {
          commit('setShipments', submitResponse.data.data.orderShipments);
          return submitResponse;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveQuantity({state}, gridIndex) {
      return await storefrontInstance
        .put(`pre-season-orders-api/saveShipmentEntries`, state.updatedShipments[gridIndex])
        .then((response) => {
          return response;
        });
    },
    async updateValidators({commit}, validator) {
      await commit('updateValidators', validator);
    },
    updateShipment({commit, state}, variantData) {
      let updatedShipment = {
        sku: variantData?.code,
        orderFormID: variantData?.formId,
        warehouse: variantData?.availableWarehouse,
        updatedShipments: variantData?.shipments,
        gridIndex: variantData?.gridIndex,
      };
      //grid exist in update map
      if (state.updatedShipments[variantData.gridIndex]) {
        // variant exist in grid map
        let variantIndex = state.updatedShipments[variantData.gridIndex].findIndex(
          (variant) => variant && variant.sku == variantData.code
        );
        if (variantIndex >= 0) {
          commit('updateShipment', {variantIndex, updatedShipment});
        } else {
          commit('addUpdateShipment', updatedShipment);
        }
      } else {
        state.updatedShipments[variantData.gridIndex] = [];
        commit('addUpdateShipment', updatedShipment);
      }
    },
    async validateReviewOrder({dispatch, commit}, orderFormType) {
      commit('setLoadingState', true);
      try {
        const response = await storefrontInstance.get(`pre-season-orders-api/validate/${orderFormType}`);
        if (response.status === 200) {
          commit('fetchInvalidSkulist', response.data);
          dispatch('reviewOrder', orderFormType);
          dispatch('fetchSummaryOverview', orderFormType);
          commit('setLoadingState', false);
        }
      } catch (error) {
        console.error('/validateReviewOrder error:', error);
        return error;
      }
    },
    async reviewOrder({commit}, orderFormType) {
      commit('setLoadingState', true);

      try {
        const response = await storefrontInstance.get(`pre-season-orders-api/review/${orderFormType}`);

        if (response.status === 200) {
          let reviewData = response.data;
          commit('fetchReview', reviewData);
          commit('setLoadingState', false);
        }
      } catch (error) {
        console.error('/review error:', error);
        return error;
      }
    },
    async fetchLeftNavigation({commit}, orderFormType) {
      const navigationData = await storefrontInstance.get(`pre-season-orders-api/leftNavigation/${orderFormType}`);
      let leftNavList = navigationData.data.data;
      commit('fetchLeftNavigationList', leftNavList);
    },
    async fetchSummaryOverview({commit}, orderFormType) {
      commit('setLoadingState', true);

      try {
        const response = await storefrontInstance.get(`pre-season-orders-api/preSeasonOrderSummary/${orderFormType}`);
        if (response.status === 200) {
          let summaryOverviewData = response.data.data;
          commit('setSummaryOverview', summaryOverviewData);
          commit('setLoadingState', false);
        }
      } catch (error) {
        console.error('/summary error:', error);
        return error;
      }
    },
    async fetchModelSummary({commit}, modelSummaryQueryString) {
      try {
        const response = await storefrontInstance.get(
          `pre-season-orders-api/preSeasonOrderSummary/${modelSummaryQueryString}`
        );
        if (response.status === 200) {
          let modelSummaryData = response.data.data;
          commit('setModelSummary', modelSummaryData);
        }
      } catch (error) {
        console.error('/summary error:', error);
        return error;
      }
    },
    async orderConfirmation({commit}, orderTemplateID) {
      commit('setLoadingState', true);
      try {
        const response = await storefrontInstance.get(`pre-season-orders-api/orderConfirmation/${orderTemplateID}`);
        if (response.status === 200) {
          let apiData = response.data;
          commit('fetchConfirmation', apiData);
          commit('setLoadingState', false);
        }
      } catch (error) {
        console.error('/orderConfirmation error:', error);
        return error;
      }
    },
  },
};

export default preSeason;
