<template>
  <transition name="slide" @after-leave="$emit('back')">
    <div v-show="isShown" class="nav-overlay-compact-screen">
      <button v-if="breadcrumb" class="nav-overlay-compact-screen__breadcrumb" @click="handleBack">
        <pdl-icon class="nav-overlay-compact-screen__breadcrumb-icon" size="32" name="chevron_left" />
        <div class="nav-overlay-compact-screen__breadcrumb-text" v-html="breadcrumb" />
      </button>
      <slot />
    </div>
  </transition>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavOverlayCompactScreen',
  components: {PdlIcon},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    breadcrumb: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isShown: this.isVisible,
    };
  },
  computed: {
    ...mapState('navigation', ['currentOverlay']),
  },
  watch: {
    isVisible(val) {
      this.isShown = val;
    },
  },
  methods: {
    ...mapMutations('navigation', ['setActiveLink', 'setActiveTab']),
    handleBack() {
      this.isShown = false;
      if (this.currentOverlay.isAutoExpandMobile) {
        this.setActiveLink(null);
        this.setActiveTab(null);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-overlay-compact-screen {
  @apply bg-gray-100;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &:first-child {
    z-index: 0; // needed for proper layering
  }

  /* Screen Transition ------------------- */
  &.slide-enter-active,
  &.slide-leave-active {
    transition: transform 0.5s cubic-bezier(0, 1, 0.3, 1);
  }

  &.slide-enter,
  &.slide-leave-to {
    transform: translate3d(100%, 0, 0);
  }

  /* Breadcrumb ------------------------------ */
  &__breadcrumb {
    @apply p-2;
    @apply pt-3;

    display: flex;
    align-items: center;
    cursor: pointer;

    &-icon {
      color: var(--nav-highlight-color);
    }

    &-text {
      @apply ml-2;
      @apply text-xl;
      @apply text-left;
      @apply text-white;
      @apply font-heading;
      @apply leading-normal;
    }
  }
}
</style>
