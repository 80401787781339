<template>
  <div class="checkout-footer">
    <section class="row row--narrow footer-navigation">
      <ul class="checkout-footer__navigation-holder">
        <slot name="navigation-nodes" />
      </ul>
    </section>
    <section class="row row--narrow footer-information">
      <span class="footer-information__copyright link-small">{{ notice }}</span>
      <nav class="footer-information__items">
        <slot name="footer-items" />
      </nav>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    notice: {
      type: String,
      required: true,
    },
    cdnStaticFilesRoot: {
      type: String,
      default: '',
    },
  },
};
</script>
