import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';

const powerReviewsModule = {
  namespaced: true,

  state: {
    productDetails: {},
    isWindowPwrLoaded: false,
    isLoadingScript: false,
    prBaseProperties: {},
  },
  getters: {
    productDetails: (state) => state.productDetails,
  },

  mutations: {
    setProductDetails(state, payload) {
      Vue.set(state, 'productDetails', payload);
    },

    setIsLoadingScript(state, payload) {
      Vue.set(state, 'isLoadingScript', payload);
    },

    setIsWindowPwrLoaded(state, payload) {
      Vue.set(state, 'isWindowPwrLoaded', payload);
    },
    setBaseProps(state, payload) {
      payload.forEach((baseProperty) => {
        Vue.set(state.prBaseProperties, baseProperty.name, baseProperty.label);
      });
    },
  },
  actions: {
    async fetchProductReviews({state}, payload) {
      try {
        const response = await storefrontInstance.get(
          `https://readservices-b2c.powerreviews.com/m/4404/l/en_US/product/${payload}/reviews?apikey=${state.productDetails.pr_api_key}`
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};

export default powerReviewsModule;
