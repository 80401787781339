import {ItemAvailability} from './markup-item-availability';

export const StockStatus = Object.freeze({
  IN_STOCK: 'IN_STOCK',
  IN_STORES_ONLY: 'IN_STORES_ONLY',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  COMING_SOON: 'COMING_SOON',
  IN_WAREHOUSE: 'IN_WAREHOUSE',
  CONTACT_RETAILER: 'CONTACT_RETAILER',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  BTO_IN_STOCK: 'BTO_IN_STOCK',
  BTO_OUT_OF_STOCK: 'BTO_OUT_OF_STOCK',
  BUILD_TO_ORDER: 'BUILD_TO_ORDER',
});

const stockStatusConfigTarget = {
  //In stock
  [StockStatus.IN_STOCK]: {
    retailerStockClass: 'status-box--in_stock',
    markupItemAvailability: ItemAvailability.IN_STOCK,
    stockStatusMessage: {
      calloutType: 'success',
      cssClass: 'text-green',
      label: 'inStock',
      description: 'description.IN_STOCK',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_WAREHOUSE',
      retailerLabel: 'retailer.bike.inStock',
    },
  },
  //In store only
  [StockStatus.IN_STORES_ONLY]: {
    retailerStockClass: 'status-box--contact_retailer',
    markupItemAvailability: ItemAvailability.IN_STORE_ONLY,
    stockStatusMessage: {
      calloutType: 'warning',
      cssClass: 'text-red',
      label: 'inStoreOnly',
      // adding description since it's the fallback display value, EnumGlobalStockStatus3 will soon be deprecated
      description: 'description.ONLINE_ONLY',
      retailerLabel: 'retailer.bike.contactRetailer',
    },
  },
  //Out of Stock
  [StockStatus.OUT_OF_STOCK]: {
    retailerStockClass: 'status-box--contact_retailer',
    markupItemAvailability: ItemAvailability.OUT_OF_STOCK,
    stockStatusMessage: {
      calloutType: 'error',
      cssClass: 'text-red',
      label: 'outOfStock',
      description: 'description.OUT_OF_STOCK',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_STORES_ONLY',
      retailerLabel: 'retailer.bike.contactRetailer',
    },
  },
  //Pre Sale
  [StockStatus.COMING_SOON]: {
    retailerStockClass: 'status-box--contact_retailer',
    markupItemAvailability: ItemAvailability.PRE_SALE,
    stockStatusMessage: {
      calloutType: 'error',
      cssClass: 'text-red',
      label: 'comingSoon',
      description: 'description.COMING_SOON',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_STORES_ONLY',
      retailerLabel: 'retailer.bike.contactRetailer',
    },
  },
  // In Stock - Warehouse
  [StockStatus.IN_WAREHOUSE]: {
    retailerStockClass: 'status-box--in_warehouse',
    markupItemAvailability: ItemAvailability.IN_STOCK,
    stockStatusMessage: {
      calloutType: 'default',
      description: 'description.inStock',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_STORES_ONLY',
      retailerLabel: 'retailer.bike.inWarehouse',
    },
  },
  // Contact retailer
  [StockStatus.CONTACT_RETAILER]: {
    retailerStockClass: 'status-box--contact_retailer',
    markupItemAvailability: ItemAvailability.BACK_ORDER,
    stockStatusMessage: {
      calloutType: 'default',
      description: 'description.COMING_SOON',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_STORES_ONLY',
      retailerLabel: 'retailer.bike.contactRetailer',
    },
  },
  //No Variant matched
  [StockStatus.NOT_AVAILABLE]: {
    retailerStockClass: 'status-box--contact_retailer',
    markupItemAvailability: ItemAvailability.NOT_AVAILABLE,
    stockStatusMessage: {
      calloutType: 'default',
      cssClass: 'text-gray-100',
      label: 'notAvailable',
      description: 'description.NOT_AVAILABLE',
      nonECommDescription: 'description.NOT_AVAILABLE',
      nonECommNonLocallyDescription: 'description.NOT_AVAILABLE',
      retailerLabel: 'retailer.bike.contactRetailer',
    },
  },
  [StockStatus.BTO_IN_STOCK]: {
    retailerStockClass: 'status-box--in_stock',
    markupItemAvailability: ItemAvailability.IN_STOCK,
    stockStatusMessage: {
      calloutType: 'success',
      cssClass: 'text-green',
      label: 'inStock',
      description: 'description.BTO_IN_STOCK',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_WAREHOUSE',
      retailerLabel: 'retailer.bike.inStock',
    },
  },
  [StockStatus.BUILD_TO_ORDER]: {
    retailerStockClass: 'status-box--in_stock',
    markupItemAvailability: ItemAvailability.IN_STOCK,
    stockStatusMessage: {
      calloutType: 'success',
      cssClass: 'text-green',
      label: 'inStock',
      description: 'description.BUILD_TO_ORDER',
      nonECommDescription: 'description.IN_STORES_ONLY',
      nonECommNonLocallyDescription: 'description.IN_WAREHOUSE',
      retailerLabel: 'retailer.bike.inStock',
    },
  },
  [StockStatus.BTO_OUT_OF_STOCK]: {
    retailerStockClass: 'status-box--contact_retailer',
    markupItemAvailability: ItemAvailability.OUT_OF_STOCK,
    stockStatusMessage: {
      calloutType: 'error',
      cssClass: 'text-red',
      label: 'outOfStock',
      description: 'description.BTO_OUT_OF_STOCK',
      nonECommDescription: 'description.OUT_OF_STOCK',
      nonECommNonLocallyDescription: 'description.OUT_OF_STOCK',
      retailerLabel: 'retailer.bike.contactRetailer',
    },
  },
};

//Setup default values
const stockStatusProxyHandler = {
  get(target, prop) {
    return target[prop] ? target[prop] : stockStatusConfig[StockStatus.OUT_OF_STOCK];
  },
};

//export proxy
export const stockStatusConfig = new Proxy(stockStatusConfigTarget, stockStatusProxyHandler);
